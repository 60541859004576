import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  useColorModeValue,
  Spinner,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import OrganizationMemberRoleDropDown from "../Miscellaneous/Dropdowns/OrganizationMemberRoleDropDown";
import { useNavigate } from "react-router-dom";
import { InviteMembersModal } from "./InviteMembers/InviteMembersModal";

const AllMembers = ({ viewingOrganization }) => {
  const {
    selectedOrganization,
    removeMembersFromOrganization,
    getOrganizationMembersData,
    user,
  } = UserState();
  const { screenWidth } = LayoutState();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isInviteMemberModalOpen,
    onOpen: openInviteMemberModal,
    onClose: closeInviteMemberModal,
  } = useDisclosure();

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [clickedMember, setClickedMember] = useState(null);
  const [loadingMembersData, setLoadingMembersData] = useState(true);
  const [allMembers, setAllMembers] = useState([]);

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("purple.50", "purple.900");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBgColor = useColorModeValue("gray.50", "gray.700");

  const fetchAllOrganizationMembersData = async () => {
    try {
      const allMembersData = await getOrganizationMembersData(false);
      setAllMembers(allMembersData);
    } catch (error) {
      console.error(`Couldn't fetch all orgnaization members data`, error);
    } finally {
      setLoadingMembersData(false);
    }
  };

  const isSelected = (member) =>
    selectedMembers.some((selectedMember) => selectedMember.id === member.id);

  const selectUnselectAll = () => {
    setSelectedMembers(allSelected ? [] : [...allMembers]);
  };

  const removeMembers = async () => {
    const memberIds = selectedMembers.map((member) => member.firebaseUserId);
    const isMePresent = selectedMembers.some(
      (member) => member.firebaseUserId === user?.firebaseUserId
    );

    try {
      await removeMembersFromOrganization(memberIds);

      if (isMePresent) {
        toast({
          title: "Left Organization",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
        navigate("/dashboard");
      } else {
        toast({
          title: "Members removed successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error removing members",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setSelectedMembers([]);
    }
  };

  useEffect(() => {
    setAllSelected(
      selectedMembers?.length === allMembers?.length && allMembers?.length > 0
    );
  }, [selectedMembers, allMembers]);

  useEffect(() => {
    // fetch all members data.
    if (viewingOrganization?.id) {
      fetchAllOrganizationMembersData();
    }
  }, [viewingOrganization]);

  return (
    <Box
      bg={bgColor}
      borderRadius="lg"
      p={1}
      height={"100%"}
      width={"100%"}
      // overflow={"hidden"}
    >
      {isInviteMemberModalOpen && (
        <InviteMembersModal
          isOpen={isInviteMemberModalOpen}
          onClose={closeInviteMemberModal}
          organizationMembers={allMembers}
        />
      )}
      <Flex justifyContent="right" alignItems="center" mb={2} pt={2}>
        {!selectedOrganization?.isPersonal &&
          (selectedMembers?.length > 0 ? (
            <Button
              leftIcon={<DeleteIcon />}
              bg="red.500"
              color="white"
              size="sm"
              onClick={removeMembers}
              _hover={{ bg: "red.600" }}
            >
              Remove Members
            </Button>
          ) : (
            <Button
              leftIcon={<AddIcon />}
              bg="#a67db7"
              color="white"
              size="sm"
              onClick={openInviteMemberModal}
              _hover={{ bg: "#956ba6" }}
            >
              Invite Members
            </Button>
          ))}
      </Flex>
      {loadingMembersData ? (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          minH={"300px"}
          alignItems={"center"}
        >
          <Spinner size="xl" />
        </Box>
      ) : allMembers && allMembers?.length > 0 ? (
        <TableContainer>
          <Table variant="simple">
            <Thead bg={headerBgColor}>
              <Tr>
                <Th width="40px">
                  <Checkbox
                    isChecked={allSelected}
                    onChange={selectUnselectAll}
                    colorScheme="purple"
                  />
                </Th>
                <Th>Member</Th>
                <Th>Role</Th>
                <Th>Email</Th>
              </Tr>
            </Thead>
            <Tbody height={`calc(100% - 200px)`} overflow={"scroll"}>
              {allMembers.map((member) => (
                <Tr
                  key={member.id}
                  onClick={() => setClickedMember(member)}
                  bg={isSelected(member) ? selectedBgColor : "transparent"}
                  _hover={{ bg: hoverBgColor, cursor: "pointer" }}
                  transition="background-color 0.2s"
                >
                  <Td>
                    <Checkbox
                      isChecked={isSelected(member)}
                      colorScheme="purple"
                      onChange={(e) => {
                        e.stopPropagation();
                        setSelectedMembers(
                          isSelected(member)
                            ? selectedMembers.filter((m) => m.id !== member.id)
                            : [...selectedMembers, member]
                        );
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Td>
                  <Tooltip label="View Profile" aria-label="View Profile">
                    <Td
                      display={"flex"}
                      alignItems={"center"}
                      gap={4}
                      onClick={() => {
                        navigate(`/profile/${member?.firebaseUserId}`);
                      }}
                      _hover={{ color: "blue" }}
                    >
                      <Avatar
                        name={`${member?.firstName} ${member?.lastName}`}
                        src={member?.imageUrl || ""}
                        size={"sm"}
                      />
                      {`${member?.firstName?.toUpperCase()} ${member?.lastName?.toUpperCase()}`}
                    </Td>
                  </Tooltip>
                  <Td _hover={{ cursor: "pointer", scale: 1.3 }}>
                    <OrganizationMemberRoleDropDown
                      member={member}
                      setMembers={setAllMembers}
                      forUpdation={true}
                      clickedMember={clickedMember}
                    />
                  </Td>
                  <Td>{member?.email || ""}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text textAlign="center" color={textColor} fontSize="lg" mt={8}>
          No Members in the Organization
        </Text>
      )}
    </Box>
  );
};

export default AllMembers;
