import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";

const ViewMilestoneModal = ({
  isOpen,
  onClose,
  milestone,
  setMilestone,
  setMilestones,
}) => {
  const { isPhone } = LayoutState();
  const { modifyMilestone } = UserState();
  const [fields, setFields] = useState({
    title: milestone.title,
    description: milestone.description,
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [id]: value,
    }));
  };

  const updateMilestone = async () => {
    if (
      fields.title === milestone.title &&
      fields.description === milestone.description
    ) {
      return;
    }

    try {
      setLoading(true);
      await modifyMilestone(milestone.id, fields);
      toast({
        title: "Milestone updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error occurred",
        description: "Couldn't update milestone",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setMilestones((currentMilestones) =>
      currentMilestones.map((currentMilestone) =>
        currentMilestone.id === milestone.id
          ? { ...currentMilestone, ...fields }
          : currentMilestone
      )
    );

    updateMilestone();
    onClose();
  };

  useEffect(() => {
    setFields({
      title: milestone.title,
      description: milestone.description,
    });
  }, [milestone]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        maxH="90vh"
        boxShadow="xl"
        borderRadius="lg"
        overflow="hidden"
        bg="gray.100"
      >
        <ModalHeader p={4} pb={2}>
          <Input
            id="title"
            value={fields.title}
            onChange={handleInputChange}
            fontSize={isPhone ? "2xl" : "3xl"}
            fontWeight="500"
            border="none"
            bg="transparent"
            color="#4A2A5A"
            _focus={{ boxShadow: "none" }}
            placeholder="Milestone Title"
            _placeholder={{ color: "gray.400" }}
            autoFocus={false}
          />
        </ModalHeader>
        <ModalCloseButton cursor="pointer" zIndex={20}/>
        <ModalBody pt={2} display="flex" flexDirection="column" px={4}>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel color="#4A2A5A">Description</FormLabel>
              <Textarea
                id="description"
                value={fields.description}
                onChange={handleInputChange}
                minH="150px"
                bg="white"
                borderColor="#a67db7"
                _hover={{ borderColor: "#4A2A5A" }}
                _focus={{ borderColor: "#4A2A5A", boxShadow: "0 0 0 1px #4A2A5A" }}
                placeholder="Milestone description"
              />
            </FormControl>
            <Text fontSize="sm" color="gray.600">
              Created: {new Date(milestone.createdAt).toLocaleDateString()}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#a67db7"
            color="white"
            _hover={{ bg: "#4A2A5A" }}
            onClick={handleClose}
            isLoading={loading}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewMilestoneModal;
