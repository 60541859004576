import axios from "axios";
import { useEffect, useState } from "react";
import { NOTIFICATION_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";
import { UserState } from "../Context/UserProvider";

const useNotifications = () => {
  const { accessToken, user } = AuthState();
  const { selectedOrganization } = UserState();
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [filter, setFilter] = useState("unread");

  // VALID_TYPES = ["task_assignment", "mention", "deadline", "system", "bandwidth_alert", "skill_endorsement"]
  const notificationStructures = {
    task_assignment: {},
    mention: {},
    deadline: {},
    system: {},
    bandwidth_alert: {},
    skill_endorsement: {},
  };

  const sendNotification = async (
    recipientFirebaseId,
    type,
    title,
    message
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id, // Use dynamic org ID if needed
        },
      };
      await axios.post(
        `${baseUrl}/notifications`,
        { recipientFirebaseId, type, title, message },
        config
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const fetchNotifications = async (pageNo = page) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };

      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}/notifications?page=${pageNo}&unread_only=false`,
        config
      );
      const newNotifications = response?.data?.notifications || [];
      setNotifications((prev) => [...prev, ...newNotifications]);
      setHasMore(response.data.has_next);
      setPage(() => pageNo + 1);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadLatestNotifications = () => {
    setNotifications([]);
    fetchNotifications(1);
  };

  const handleLoadMore = () => {
    fetchNotifications();
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      await axios.put(`${baseUrl}/notifications/mark-all-read`, {}, config);

      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, read: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization?.id,
        },
      };
      await axios.put(
        `${baseUrl}/notifications/${notificationId}/read`,
        {},
        config
      );

      setNotifications((prev) =>
        prev.map((notification) =>
          notification._id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );

      setUnreadCount((prev) => Math.max(prev - 1, 0));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": "67018b5b8e2f22b21025855a",
        },
      };
      const response = await axios.get(
        `${baseUrl}/notifications/unread-count`,
        config
      );
      setUnreadCount(response.data.unread_count);
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  };

  useEffect(() => {
    if (!accessToken || !user?.firebaseUserId) {
      return;
    }

    fetchNotifications();
    fetchUnreadCount();
  }, [user]);

  useEffect(() => {
    if (filter === "all") {
      setFilteredNotifications(notifications);
    } else if (filter === "read") {
      setFilteredNotifications(notifications.filter((n) => n.read));
    } else if (filter === "unread") {
      setFilteredNotifications(notifications.filter((n) => !n.read));
    }
  }, [notifications, filter]);

  return {
    notifications: filteredNotifications,
    isLoading,
    hasMore,
    unreadCount,
    handleLoadMore,
    sendNotification,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    setFilter,
    loadLatestNotifications,
  };
};

export default useNotifications;
