import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";

const CreateSprintModal = ({ isOpen, onClose, projectId }) => {
  const { isPhone } = LayoutState();
  const { createNewSprint } = UserState();
  const [sprintName, setSprintName] = useState("");
  const [sprintGoal, setSprintGoal] = useState("");
  const [sprintDuration, setSprintDuration] = useState(7);

  const createSprint = async () => {
    await createNewSprint(projectId, sprintName, sprintGoal, sprintDuration);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? "95%" : "500px"}
        margin="10px"
        borderRadius="xl"
        boxShadow="xl"
      >
        <ModalHeader
          bg="gray.50"
          borderTopRadius="xl"
          borderBottom="1px"
          borderColor="gray.200"
          py={4}
        >
          <Text fontSize="xl" fontWeight="bold">
            Create New Sprint
          </Text>
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody py={6}>
          <VStack spacing={5}>
            <FormControl>
              <FormLabel fontWeight="medium">Name</FormLabel>
              <Input
                value={sprintName}
                onChange={(e) => setSprintName(e.target.value)}
                placeholder="Enter sprint name"
                borderRadius="md"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">Goal</FormLabel>
              <Input
                value={sprintGoal}
                onChange={(e) => setSprintGoal(e.target.value)}
                placeholder="Enter sprint goal"
                borderRadius="md"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">Duration {"(in days)"}</FormLabel>
              <Input
                value={sprintDuration}
                type="number"
                onChange={(e) => setSprintDuration(e.target.value)}
                placeholder="Default: 7 (days)"
                borderRadius="md"
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.200" py={4}>
          <Button
            colorScheme="blue"
            onClick={createSprint}
            borderRadius="md"
            px={8}
          >
            Create Sprint
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSprintModal;
