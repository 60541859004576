"use client";

import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SideBarContent from "./SideBarContent";
import { LayoutState } from "../../../Context/LayoutProvider";

const PermanentSideBar = () => {
  const { sidebarWidth } = LayoutState();

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width={`${sidebarWidth}px`}
      height={"100vh"}
      overflow="hidden"
      display={"flex"}
      flexDirection="column"
      className="permanent-side-bar"
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      transition="all 0.3s ease-in-out"
      id="permanent-side-bar-container"
    >
      <Box
        flex={1}
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
      >
        <SideBarContent />
      </Box>
    </Box>
  );
};

export default PermanentSideBar;
