import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { LayoutState } from "../../Context/LayoutProvider";
import logo from "../../assets/lumiproject-logo.png";
import luminame from "../../assets/lumiproject-name.png";

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
`;

export default function Component({
  isNameVisible,
  containerRef,
  myRef,
  sectionRefs,
}) {
  const [isScrolled, setIsScrolled] = useState(false);
  const { isPhone } = LayoutState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const scrollToTop = () => {
    containerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToSection = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(containerRef?.current?.scrollTop > 0);
    };

    const closePopover = () => {
      if (isOpen) onClose();
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    container.addEventListener("click", closePopover);

    return () => {
      container.removeEventListener("scroll", handleScroll);
      container.removeEventListener("click", closePopover);
    };
  }, [containerRef, isOpen, onClose]);

  return (
    <Flex
      ref={myRef}
      as="header"
      id="header"
      bg={isScrolled ? "rgba(255, 255, 255, 0.8)" : "transparent"}
      color="black"
      padding={4}
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top={0}
      zIndex={2}
      backdropFilter="blur(10px)"
      transition="all 0.3s ease-in-out"
      boxShadow={isScrolled ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"}
    >
      <Flex align="center" gap={4}>
        <Box
          onClick={scrollToTop}
          _hover={{ cursor: "pointer" }}
          display="flex"
          alignItems="center"
          transition="transform 0.3s ease-in-out"
          _active={{ transform: "scale(0.95)" }}
        >
          <Image src={logo} alt="lumiproject logo" boxSize="50px" />
          {!isNameVisible && (
            // <Heading
            //   as="h1"
            //   size="lg"
            //   bgGradient="linear(to-r, #0C9ECB, #96D0A2, #4D7CBE, #c1e1bf, #4853a4)"
            //   bgClip="text"
            //   fontWeight="extrabold"
            //   letterSpacing="tight"
            // >
            //   lumiproject
            // </Heading>
            <Image
              src={luminame}
              alt="lumiproject"
              height={"50px"}
              width={"100%"}
            />
          )}
        </Box>
        {!isPhone && (
          <Flex gap={6} alignItems="center" color="black">
            {["Features", "Pricing", "Contact"].map((item) => (
              <Text
                key={item}
                fontWeight="medium"
                _hover={{
                  color: "#0C9ECB",
                  cursor: "pointer",
                  transform: "translateY(-2px)",
                }}
                transition="all 0.2s"
                onClick={() =>
                  scrollToSection(sectionRefs[`${item.toLowerCase()}Ref`])
                }
              >
                {item}
              </Text>
            ))}
          </Flex>
        )}
      </Flex>
      {isPhone ? (
        <Flex alignItems="center" gap={2}>
          <Button
            size="sm"
            onClick={() =>
              navigate("/auth", { state: { component: "signup" } })
            }
            bgGradient="linear(to-r, #0C9ECB, #96D0A2)"
            color="white"
            _hover={{ bgGradient: "linear(to-r, #0C9ECB, #4853a4)" }}
            borderRadius="full"
            fontWeight="500"
            px={4}
          >
            Get Started
          </Button>
          <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <PopoverTrigger>
              <IconButton
                icon={<HamburgerIcon />}
                variant="ghost"
                _hover={{ bg: "transparent", color: "#0C9ECB" }}
                _active={{ bg: "transparent" }}
              />
            </PopoverTrigger>
            <PopoverContent width="200px" borderRadius="md" boxShadow="lg">
              <PopoverArrow />
              <PopoverBody display="flex" flexDirection="column" gap={3} py={4}>
                {["Features", "Pricing", "Contact"].map((item) => (
                  <Text
                    key={item}
                    fontWeight="medium"
                    _hover={{ color: "#0C9ECB", cursor: "pointer" }}
                    onClick={() => {
                      scrollToSection(sectionRefs[`${item.toLowerCase()}Ref`]);
                      onClose();
                    }}
                  >
                    {item}
                  </Text>
                ))}
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() =>
                    navigate("/auth", { state: { component: "signin" } })
                  }
                >
                  Login
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ) : (
        <Flex gap={4} alignItems="center">
          <Button
            onClick={() =>
              navigate("/auth", { state: { component: "signup" } })
            }
            bgGradient="linear(to-r, #0C9ECB, #96D0A2)"
            color="white"
            _hover={{ bgGradient: "linear(to-r, #0C9ECB, #4853a4)" }}
            borderRadius="full"
            fontWeight="500"
            px={6}
            py={2}
            animation={`${pulseAnimation} 2s infinite`}
          >
            Get Started
          </Button>
          <Button
            onClick={() =>
              navigate("/auth", { state: { component: "signin" } })
            }
            variant="ghost"
            color="black"
            _hover={{ bg: "transparent", color: "#0C9ECB" }}
            rightIcon={<ChevronDownIcon />}
          >
            Login
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
