import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Box,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CircleX } from "lucide-react";

const ParentTaskSelector = ({
  projectId,
  suggestions,
  setSuggestions,
  fetchSuggestions,
  parentTaskTitle,
  setParentTaskTitle,
  setParentId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const inputRef = useRef(null);
  const popoverBodyRef = useRef(null);

  useEffect(() => {
    if (projectId) {
      fetchSuggestions(projectId);
    }
  }, [projectId, fetchSuggestions]);

  const handleInputChange = (e) => {
    if (!projectId) {
      console.warn("Please select a project before adding a parent task.");
      return;
    }

    const value = e.target.value.trim();
    setParentTaskTitle(value);
    if (value) {
      onOpen();
    } else {
      onClose();
    }
    setFocusedIndex(-1);
  };

  const handleSelectSuggestion = (suggestion) => {
    setParentTaskTitle(suggestion.title);
    setParentId(suggestion.id);
    onClose();
    inputRef.current?.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === "Enter" && focusedIndex !== -1) {
      e.preventDefault();
      handleSelectSuggestion(suggestions[focusedIndex]);
    } else if (e.key === "Escape") {
      onClose();
      inputRef.current?.focus();
    }

    if (popoverBodyRef.current && focusedIndex !== -1) {
      const focusedElement = popoverBodyRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  return (
    <FormControl>
      <FormLabel>Parent Task</FormLabel>
      <Popover
        isOpen={isOpen && suggestions.length > 0}
        onClose={onClose}
        placement="bottom-start"
        autoFocus={false}
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              ref={inputRef}
              value={parentTaskTitle}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={
                projectId
                  ? "Type to search for parent task"
                  : "Select a project first"
              }
              bg="gray.100"
              autoComplete="off"
              disabled={!projectId}
              border={!projectId && "1px solid gray"}
            />
            {parentTaskTitle && (
              <InputRightElement>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setParentTaskTitle("");
                    setParentId("");
                    inputRef.current?.focus();
                  }}
                  aria-label="Clear parent task"
                  color="gray.500"
                  _hover={{ color: "red.500" }}
                >
                  <CircleX size={16} />
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent
          width="100%"
          borderRadius="sm"
          boxShadow="lg"
          overflow="hidden"
        >
          <PopoverBody p={0} maxH="250px" overflowY="auto" ref={popoverBodyRef}>
            {suggestions.map((suggestion, index) => (
              <Box
                key={suggestion.id}
                bg={focusedIndex === index ? "blue.100" : "gray.100"}
                _hover={{ bg: "blue.100", cursor: "pointer" }}
                onClick={() => handleSelectSuggestion(suggestion)}
                p={2}
                m={1}
                borderRadius="md"
              >
                <Text fontSize="sm">{suggestion.title}</Text>
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  );
};

export default ParentTaskSelector;
