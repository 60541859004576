import React, { useEffect, useRef, useState } from 'react';
import { Check } from "lucide-react";
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";

const ProjectDropDown = ({
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  setProjectId,
  project,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const { projects } = UserState();
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [query, setQuery] = useState("");
  const [focusIndex, setFocusIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const itemsRef = useRef({});
  const [projectName, setProjectName] = useState("");

  const toast = useToast();

  const handleInputBlur = (e) => {
    // Add small delay to allow click events to process
    setTimeout(() => {
      if (e.relatedTarget === popoverRef.current) {
        return;
      }
      onClose();
    }, 100);
  };

  const handleProjectClick = (e, obj) => {
    e?.stopPropagation();
    setProjectId(obj.id);
    setQuery(obj.projectName);
    setProjectName(obj.projectName);
    onClose();
  };

  const scrollIntoView = (index) => {
    const element = itemsRef.current[index];
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (!filteredProjects?.length) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        const nextIndex = (focusIndex + 1) % filteredProjects.length;
        setFocusIndex(nextIndex);
        setHoverIndex(nextIndex);
        scrollIntoView(nextIndex);
        break;
      case "ArrowUp":
        e.preventDefault();
        const prevIndex =
          (focusIndex - 1 + filteredProjects.length) % filteredProjects.length;
        setFocusIndex(prevIndex);
        setHoverIndex(prevIndex);
        scrollIntoView(prevIndex);
        break;
      case "Enter":
        e.preventDefault();
        if (focusIndex >= 0) {
          handleProjectClick(e, filteredProjects[focusIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        onClose();
        break;
    }
  };

  useEffect(() => {
    if (!projects) return;
    
    if (projectName && query !== projectName) {
      setProjectId("");
      setProjectName("");
    }
    
    if (query === "") {
      setFilteredProjects(projects);
    } else {
      const projs = projects.filter((proj) =>
        proj.projectName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProjects(projs || null);
    }
    setFocusIndex(-1);
    setHoverIndex(-1);
  }, [query]);

  useEffect(() => {
    if (project) {
      setProjectId(project.id);
      setProjectName(project.projectName);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFocusIndex(-1);
      setHoverIndex(-1);
    }
  }, [isOpen]);

  return (
    <Popover onOpen={onOpen} isOpen={isOpen} initialFocusRef={inputRef}>
      <PopoverTrigger>
        <Button w="full" bg="gray.100" _hover={{ opacity: 0.8 }} p={0}>
          <Input
            isDisabled={project ? true : false}
            value={isOpen ? query : projectName}
            placeholder="Project"
            color={project ? "blue" : "black"}
            fontWeight={project ? 600 : "auto"}
            onFocus={project ? () => {} : onOpen}
            onBlur={handleInputBlur}
            onChange={
              project
                ? () => {}
                : (e) => {
                    setQuery(e.target.value);
                    if (!isOpen && e.target.value) {
                      onOpen();
                    }
                  }
            }
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        width="100%"
        border="1px solid #ececec"
        boxShadow="0px 0px 10px 3px rgba(0, 0, 0, 0.2)"
      >
        <PopoverBody maxHeight="250px" width="220px" overflow="auto">
          <div id="filteredprojectswrapper">
            {filteredProjects?.length > 0 ? (
              filteredProjects.map((proj, index) => (
                <Box
                  key={proj.id}
                  ref={(el) => (itemsRef.current[index] = el)}
                  marginY={0}
                  borderRadius="15px"
                  width="100%"
                  padding={3}
                  textAlign="left"
                  paddingY={1}
                  onClick={(e) => handleProjectClick(e, proj)}
                  onMouseEnter={() => {
                    setHoverIndex(index);
                    setFocusIndex(index); // Ensure the focus index updates on hover
                  }}
                  onMouseLeave={() => {
                    setHoverIndex(-1);
                    setFocusIndex(-1); // Reset the focus index on leave
                  }}
                  backgroundColor={
                    focusIndex === index || hoverIndex === index
                      ? "gray.100"
                      : "transparent"
                  }
                  transition="background-color 0.2s"
                  cursor="pointer"
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  outline={focusIndex === index ? "2px solid #C9A8FF" : "none"}
                  mb={1}
                  _hover={{
                    backgroundColor: "gray.100",
                  }}
                >
                  <Text>{proj.projectName}</Text>
                  {projectName === proj.projectName && (
                    <Check size={16} color="#4A5568" />
                  )}
                </Box>
              ))
            ) : (
              <Text color="black">
                {projects && projects.length > 0
                  ? "No such project exists."
                  : "No projects under this organization."}
              </Text>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProjectDropDown;