import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  VStack,
  Text,
  Box,
  Spinner,
  Select,
  Flex,
  Badge,
  Link,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { ServiceState } from "../../Context/ServiceProvider";
import { RefreshCwIcon } from "lucide-react";

const NotificationsPopOver = ({ children }) => {
  const {
    notifications,
    isLoading,
    hasMore,
    handleLoadMore,
    setFilter,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    unreadCount,
    loadLatestNotifications,
  } = ServiceState();

  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent width="350px" maxHeight="500px">
        <PopoverArrow />
        <PopoverCloseButton
          mt={2}
          color="gray.600"
          borderRadius="sm"
          size="sm"
          _hover={{ bg: "gray.100", color: "gray.800" }}
          aria-label="Close"
        />
        <PopoverHeader borderBottomWidth="1px" py={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex justifyContent="space-between" alignItems="center" gap={1}>
              <Tooltip label="Refresh" aria-label="Refresh">
                <IconButton
                  icon={<RefreshCwIcon size={"1.2em"} />}
                  bgColor={"inherit"}
                  size={"sm"}
                  onClick={loadLatestNotifications}
                  px={0}
                />
              </Tooltip>
              <Text fontWeight="bold" fontSize="lg">
                Notifications{" "}
                {`${unreadCount > 0 ? `(${notifications?.length})` : ""}`}
              </Text>
            </Flex>
            <Select
              onChange={(e) => setFilter(e.target.value)}
              size="sm"
              width="auto"
              mr={8}
              borderRadius={"md"}
              borderColor="gray.300"
              _hover={{ borderColor: "gray.400" }}
              _focus={{
                borderColor: "blue.500",
                boxShadow: "0 0 0 2px #90cdf4",
              }}
            >
              <option value="unread">Unread</option>
              <option value="read">Read</option>
              <option value="all">All</option>
            </Select>
          </Flex>
        </PopoverHeader>
        <PopoverBody maxHeight="350px" overflowY="auto">
          {notifications && notifications.length > 0 ? (
            <VStack align="stretch" spacing={2}>
              {notifications.map((notification) => (
                <Box
                  key={notification?._id}
                  p={2}
                  bg={notification?.read ? "gray.50" : "blue.50"}
                  borderRadius="md"
                  boxShadow="sm"
                  transition="all 0.3s"
                  opacity={notification?.read ? 0.7 : 1}
                  fontSize="sm"
                >
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <Text fontWeight={notification?.read ? "normal" : "bold"}>
                      {notification?.title}
                    </Text>
                    <Flex gap={2} alignItems="center">
                      {notification?.type && (
                        <Badge colorScheme="purple" fontSize="xs">
                          {notification.type}
                        </Badge>
                      )}
                    </Flex>
                  </Flex>

                  <Text mt={1}>{notification?.message}</Text>

                  <Text fontSize="xs" color="gray.500" mt={1}>
                    Created:{" "}
                    {notification?.createdAt
                      ? format(new Date(notification.createdAt), "MMM d, HH:mm")
                      : "Invalid date"}
                  </Text>

                  {notification?.expiresAt && (
                    <Text fontSize="xs" color="gray.500">
                      Expires:{" "}
                      {format(new Date(notification.expiresAt), "MMM d, yyyy")}
                    </Text>
                  )}

                  {notification?.priority && (
                    <Text fontSize="xs" color="gray.500">
                      Priority: {notification.priority}
                    </Text>
                  )}

                  <Flex
                    mt={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {notification?.actionUrl && (
                      <Link
                        href={notification.actionUrl}
                        color="blue.500"
                        fontSize="xs"
                      >
                        View Details
                      </Link>
                    )}
                    {!notification?.read && (
                      <Button
                        size="xs"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() =>
                          markNotificationAsRead(notification?._id)
                        }
                      >
                        Mark as Read
                      </Button>
                    )}
                  </Flex>
                </Box>
              ))}
              {isLoading && <Spinner size="sm" />}
            </VStack>
          ) : (
            <Text textAlign="center" py={4} fontSize="sm">
              You're all caught up! No new notifications.
            </Text>
          )}
        </PopoverBody>
        <PopoverFooter>
          <Flex justifyContent="space-between">
            {unreadCount > 0 && (
              <Button
                onClick={markAllNotificationsAsRead}
                size="sm"
                colorScheme="blue"
                variant="ghost"
              >
                Mark all as read
              </Button>
            )}
            {hasMore && (
              <Button
                onClick={handleLoadMore}
                size="sm"
                variant="ghost"
                ml="auto"
              >
                {isLoading ? <Spinner size={"sm"} /> : "Load More"}
              </Button>
            )}
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsPopOver;
