import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  useToast,
  Text,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { RefreshCcwIcon } from "lucide-react";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import CreateSprintModal from "./Sprints/CreateSprintModal";
import SprintBox from "./Sprints/SprintBox";
import { useColorModeValue } from "@chakra-ui/react";
export default function Backlog({ viewingProject }) {
  const { getSprints, getAllSprints, updateTaskSprint, createNewSprint } =
    UserState();
  const { isPhone, screenWidth } = LayoutState();
  const {
    isOpen: isCreateSprintModalOpen,
    onOpen: openCreateSprintModal,
    onClose: closeCreateSprintModal,
  } = useDisclosure();
  const [activeSprint, setActiveSprint] = useState(null);
  const [backlogSprint, setBacklogSprint] = useState(null);
  const [nextSprints, setNextSprints] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState({
    activeSprint: false,
    backlogSprint: false,
    nextSprints: false,
  });
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isFetching, setIsFetching] = useState(false);
  const [filteredSprints, setFilteredSprints] = useState([]);
  const [loadingSprintTasks, setLoadingSprintTasks] = useState(true);
  const [reloadFlag, setReloadFlag] = useState(0);

  const scrollContainerRef = useRef(null);
  const autoScrollingRef = useRef(false);
  const scrollSpeedRef = useRef(0);
  const lastScrollTopRef = useRef(0);
  const draggedTaskRef = useRef(null);
  const hoveredSprintRef = useRef(null);
  const toast = useToast();

  const bgColor = useColorModeValue("#f0e6f6", "#2a1e2f");
  const headingColor = useColorModeValue("#4a2a5a", "#d4b8e8");
  const buttonBgColor = useColorModeValue("#a67db7", "#8a63a8");
  const buttonHoverBgColor = useColorModeValue("#956ba6", "#7b5696");
  const taskBg = useColorModeValue("white", "gray.700");

  const autoScroll = useCallback(() => {
    if (autoScrollingRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.scrollTop += scrollSpeedRef.current;

      if (container.scrollTop !== lastScrollTopRef.current) {
        lastScrollTopRef.current = container.scrollTop;
        requestAnimationFrame(autoScroll);
      } else {
        autoScrollingRef.current = false;
      }
    }
  }, []);

  const startAutoScroll = useCallback(
    (speed) => {
      if (!autoScrollingRef.current) {
        autoScrollingRef.current = true;
        scrollSpeedRef.current = speed;
        requestAnimationFrame(autoScroll);
      } else {
        scrollSpeedRef.current = speed;
      }
    },
    [autoScroll]
  );

  const stopAutoScroll = useCallback(() => {
    autoScrollingRef.current = false;
  }, []);

  const findClosestSprint = useCallback((clientY) => {
    const sprintBoxes = document.querySelectorAll(".sprint-box-main");
    let closestSprint = null;
    let minDistance = Infinity;

    sprintBoxes.forEach((sprintBox) => {
      const rect = sprintBox.getBoundingClientRect();
      const distance = Math.abs(rect.top + rect.height / 2 - clientY);
      if (distance < minDistance) {
        minDistance = distance;
        closestSprint = sprintBox;
      }
    });

    return closestSprint;
  }, []);

  const onDragUpdate = useCallback(
    (update) => {
      const { destination, draggableId } = update;
      if (!destination) return;

      console.log(update);
      const container = scrollContainerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const scrollSensitivity = 100;
      const maxScrollSpeed = 15;

      const draggable = document.querySelector(
        `[data-rbd-draggable-id="${draggableId}"]`
      );

      if (!draggable) return;

      const draggableRect = draggable.getBoundingClientRect();

      const clientY = draggableRect.y;

      draggedTaskRef.current = draggableId;

      if (clientY < containerRect.top + scrollSensitivity) {
        const speed = Math.min(
          scrollSensitivity - (clientY - containerRect.top),
          maxScrollSpeed
        );
        startAutoScroll(-speed);
      } else if (clientY > containerRect.bottom - scrollSensitivity) {
        const speed = Math.min(
          clientY - (containerRect.bottom - scrollSensitivity),
          maxScrollSpeed
        );
        startAutoScroll(speed);
      } else {
        stopAutoScroll();
      }

      const closestSprint = findClosestSprint(clientY);
      if (closestSprint && closestSprint !== hoveredSprintRef.current) {
        if (hoveredSprintRef.current) {
          hoveredSprintRef.current.style.boxShadow = "";
        }
        closestSprint.style.boxShadow = "0 0 0 2px #805AD5";
        hoveredSprintRef.current = closestSprint;
      }
    },
    [startAutoScroll, stopAutoScroll, findClosestSprint]
  );

  const onDragEnd = useCallback(
    async (result) => {
      const { source, draggableId } = result;
      stopAutoScroll();

      if (hoveredSprintRef.current) {
        hoveredSprintRef.current.style.boxShadow = "";
      }

      const destinationSprintId = hoveredSprintRef.current?.id;
      if (!destinationSprintId || destinationSprintId === source.droppableId) {
        console.log("same sprint");
        console.log(`destination sprint id:`, destinationSprintId);
        console.log(`source sprint id:`, source.droppableId);
        return;
      }

      const sourceSprint = [activeSprint, backlogSprint, ...nextSprints].find(
        (sprint) => sprint?.id === source.droppableId
      );
      console.log(`source sprint: `, sourceSprint);
      const destSprint = [activeSprint, backlogSprint, ...nextSprints].find(
        (sprint) => sprint?.id === destinationSprintId
      );
      console.log(`destination sprint: `, destSprint);

      if (!sourceSprint || !destSprint) return;

      const taskIndex = sourceSprint.tasks.findIndex(
        (task) => task.id === draggableId
      );
      if (taskIndex === -1) return;

      const [movedTask] = sourceSprint.tasks.splice(taskIndex, 1);
      destSprint.tasks.push(movedTask);

      console.log(movedTask);

      if (sourceSprint.id === activeSprint?.id) {
        setActiveSprint({ ...sourceSprint });
      } else if (sourceSprint.id === backlogSprint?.id) {
        setBacklogSprint({ ...sourceSprint });
      } else {
        setNextSprints((prevSprints) =>
          prevSprints.map((sprint) =>
            sprint.id === sourceSprint.id ? { ...sourceSprint } : sprint
          )
        );
      }

      if (destSprint.id === activeSprint?.id) {
        setActiveSprint({ ...destSprint });
      } else if (destSprint.id === backlogSprint?.id) {
        setBacklogSprint({ ...destSprint });
      } else {
        setNextSprints((prevSprints) =>
          prevSprints.map((sprint) =>
            sprint.id === destSprint.id ? { ...destSprint } : sprint
          )
        );
      }

      try {
        await updateTaskSprint(viewingProject.id, destinationSprintId, [
          movedTask.id,
        ]);
      } catch (error) {
        console.error("Error updating task sprint:", error);
        toast({
          title: "Error updating task sprint",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [
      activeSprint,
      backlogSprint,
      nextSprints,
      updateTaskSprint,
      viewingProject.id,
      toast,
      stopAutoScroll,
    ]
  );

  const createSprint = async (name = "", type = "next") => {
    const sprintTypes = {
      next: "nextSprints",
      backlog: "backlogSprint",
      active: "activeSprint",
    };
    const currentSprint = sprintTypes[type];
    setLoading((obj) => ({ ...obj, [currentSprint]: true }));
    await createNewSprint(viewingProject?.id, name, type);
    setLoading((obj) => ({ ...obj, [type]: false }));
  };

  const fetchSprintsData = async (shouldFetchTasks = false) => {
    setLoading({
      activeSprint: true,
      backlogSprint: true,
      nextSprints: true,
    });

    let allSprintIds = [];

    viewingProject.sprints.activeSprint &&
      viewingProject.sprints.activeSprint !== "None" &&
      allSprintIds.push(viewingProject.sprints.activeSprint);

    viewingProject.sprints.backlogSprint &&
      viewingProject.sprints.backlogSprint !== "None" &&
      allSprintIds.push(viewingProject.sprints.backlogSprint);

    viewingProject.sprints.nextSprints?.length > 0 &&
      allSprintIds.push(...viewingProject.sprints.nextSprints);

    try {
      if (allSprintIds.length === 0) {
        toast({
          title: "No Sprints found.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const sprintsData = await getSprints(
        viewingProject.id,
        allSprintIds,
        shouldFetchTasks
      );

      if (sprintsData) {
        return sprintsData;
      } else {
        throw new Error("Error fetching sprints");
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Error fetching sprints",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading({
        activeSprint: false,
        backlogSprint: false,
        nextSprints: false,
      });
    }
  };

  const renderSprint = (sprint) => {
    if (!sprint) {
      return (
        <Box
          width={"100%"}
          height={"300px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text>Sprint Not Found.</Text>
        </Box>
      );
    }

    return (
      <Droppable droppableId={sprint.id} key={sprint.id}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="sprint-box-main"
            id={sprint.id}
          >
            <SprintBox
              loadingTasks={loadingSprintTasks}
              viewingProject={viewingProject}
              isActive={sprint?.isActive}
              isAnySprintActive={activeSprint?.id ? true : false}
              sprint={sprint}
              loading={
                sprint?.id === backlogSprint?.id ? loading.backlogSprint : false
              }
              isBacklog={sprint?.id === backlogSprint?.id}
              setSprint={
                sprint?.isActive
                  ? setActiveSprint
                  : sprint?.id === backlogSprint?.id
                  ? setBacklogSprint
                  : setNextSprints
              }
            >
              {/* <AnimatePresence>
                {sprint.tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided, snapshot) => (
                      <motion.div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          ...provided.draggableProps.style,
                          transform: snapshot.isDragging
                            ? provided.draggableProps.style.transform
                            : "none",
                        }}
                      >
                        <Box
                          p={2}
                          bg={taskBg}
                          boxShadow="md"
                          borderRadius="md"
                          mb={2}
                        >
                          {task.title}
                        </Box>
                      </motion.div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder} */}
            </SprintBox>
          </Box>
        )}
      </Droppable>
    );
  };

  const handleRefresh = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  const filterSprints = () => {
    if (!searchQuery) return nextSprints;

    let filteredSprintsData = [];

    if (!isFetching && searchQuery && debouncedSearchQuery) {
      setIsFetching(true);
      getAllSprints(viewingProject?.id, debouncedSearchQuery)
        .then((data) => {
          filteredSprintsData.push(...data);

          setFilteredSprints(filteredSprintsData);
          return filteredSprintsData;
        })
        .catch(() => console.log(`No sprints found for ${searchQuery}`))
        .finally(() => {
          setIsFetching(false);
          setDebouncedSearchQuery("");

          return [];
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      let query = searchQuery?.toLowerCase() || "";
      if (query.startsWith("sprint")) {
        query = query.slice(6).trim();
      }
      setDebouncedSearchQuery(query);
    }, 600);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    filterSprints();
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setLoading({
      activeSprint: true,
      nextSprints: true,
      backlogSprint: true,
    });

    // fetch sprints without tasks data to reduce load time
    fetchSprintsData()
      .then((allSprints) => {
        let activeSprint = null;
        let backlogSprint = null;
        let nextSprintsArray = [];

        if (!allSprints) {
          throw new Error("allSprints is empty");
        }
        allSprints?.forEach((spr) => {
          if (spr.isActive === "true" || spr.isActive === true) {
            activeSprint = spr;
          } else if (spr.name === "Backlog" && spr.num === 0) {
            backlogSprint = spr;
          } else {
            nextSprintsArray.push(spr);
          }
        });

        setNextSprints(nextSprintsArray);
        setActiveSprint(activeSprint);
        setBacklogSprint(backlogSprint);

        // now fetch sprints data with tasks data to populate the tasks lists
        fetchSprintsData(true)
          .then((allSprints) => {
            let activeSprint = null;
            let backlogSprint = null;
            let nextSprintsArray = [];

            if (!allSprints) {
              throw new Error("allSprints is empty");
            }
            allSprints?.forEach((spr) => {
              if (spr.isActive === "true" || spr.isActive === true) {
                activeSprint = spr;
              } else if (spr.name === "Backlog" && spr.num === 0) {
                backlogSprint = spr;
              } else {
                nextSprintsArray.push(spr);
              }
            });

            setNextSprints(nextSprintsArray);
            setActiveSprint(activeSprint);
            setBacklogSprint(backlogSprint);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoadingSprintTasks(false);
          });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading({
          activeSprint: false,
          nextSprints: false,
          backlogSprint: false,
        });

        if (
          !viewingProject?.sprints?.backlogSprint ||
          viewingProject?.sprints?.backlogSprint === "None"
        ) {
          (async () => {
            try {
              const result = await createSprint("Backlog", "backlog");
              if (result.status === "success") {
                setBacklogSprint(result.data);
              }
            } catch (error) {
              console.error(error);
            }
          })();
        }
      });
  }, [viewingProject, reloadFlag]);

  return (
    <Box height="100%" display="flex" flexDir="column" bg={"inherit"}>
      {isCreateSprintModalOpen && (
        <CreateSprintModal
          projectId={viewingProject.id}
          isOpen={isCreateSprintModalOpen}
          onClose={closeCreateSprintModal}
        />
      )}
      <Flex
        align="center"
        justifyContent={"space-between"}
        mx={4}
        mb={2}
        gap={3}
      >
        <InputGroup flex={1}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search Sprints"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>
        <Flex alignItems={"center"} gap={2}>
          <Button
            leftIcon={<AddIcon />}
            onClick={openCreateSprintModal}
            size="sm"
            colorScheme="purple"
            minWidth={"130px"}
          >
            Create Sprint
          </Button>
          <Tooltip label="Refresh Sprints">
            <Button onClick={handleRefresh} size="sm" bgColor={"transparent"}>
              <RefreshCcwIcon />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Box
        height="100%"
        overflowY="auto"
        mb={2}
        flex={1}
        ref={scrollContainerRef}
        px={2}
      >
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <VStack spacing={4} align="stretch">
            {!searchQuery && activeSprint && renderSprint(activeSprint)}
            {!loading.nextSprints && !isFetching ? (
              !searchQuery ? (
                nextSprints.map((sprint) => renderSprint(sprint))
              ) : filteredSprints &&
                Array.isArray(filteredSprints) &&
                filteredSprints.length > 0 ? (
                filteredSprints.map((sprint) => renderSprint(sprint))
              ) : (
                <Box
                  width={"100%"}
                  height={"300px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  No Sprints Matched Your Search.
                </Box>
              )
            ) : (
              <Box
                width={"100%"}
                height={"300px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Spinner />
              </Box>
            )}
            {!searchQuery && backlogSprint && renderSprint(backlogSprint)}
            <Box height={"30px"} />
          </VStack>
        </DragDropContext>
      </Box>
    </Box>
  );
}
