import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Avatar,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import UserAvatar from "../UserAvatar/UserAvatar";
import { AuthState } from "../../../Context/AuthProvider";
import { findFirstName, findName } from "../MyTasks/utils";
import { Check } from "lucide-react";

const ReporterDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  setReviewerId,
  reviewerId,
  projectId,
  assigneeId,
  forUpdation = false,
  isTriggerBgWhite = false,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const { organizationMembers, selectedOrganization, updateTaskReviewer } =
    UserState();
  const { user } = AuthState();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const [reviewer, setReviewer] = useState(null);
  const [reviewerName, setReviewerName] = useState("");
  const [projectMembers, setProjectMembers] = useState([]);
  const toast = useToast();

  const changeReporter = async (reviewerId) => {
    if (forUpdation) {
      await updateTaskReviewer(reviewerId);
    }
  };

  const handleSelect = (selectedUser) => {
    setReviewerId(selectedUser.firebaseUserId);
    setQuery(`${selectedUser.firstName} ${selectedUser.lastName}`);
    setReviewer(selectedUser);
    setReviewerName(`${selectedUser.firstName} ${selectedUser.lastName}`);
    onClose();
    changeReporter(selectedUser.firebaseUserId);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "Enter" || e.key === "ArrowDown") {
        e.preventDefault();
        onOpen();
        return;
      }
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev < filteredUsers.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case "Enter":
        e.preventDefault();
        if (selectedIndex >= 0 && filteredUsers[selectedIndex]) {
          handleSelect(filteredUsers[selectedIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        onClose();
        break;
      case "Tab":
        onClose();
        break;
      default:
        break;
    }
  };

  const filterUsers = () => {
    if (!projectId) {
      toast({
        title: "You need to select a project first.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      setQuery("");
      return;
    }

    const words = query.toLowerCase().split(" ");
    const filtered = projectMembers.filter(
      (member) =>
        member.firebaseUserId !== assigneeId &&
        words.every((word) =>
          `${member.firstName} ${member.lastName} ${member.designation}`
            .toLowerCase()
            .includes(word)
        )
    );

    setFilteredUsers(filtered);
    setSelectedIndex(filtered.length > 0 ? 0 : -1);
  };

  useEffect(() => {
    if (!selectedOrganization.isPersonal) {
      // Set initial reviewer on mount or when reviewerId changes
      if (reviewerId && !reviewer) {
        const foundReviewer = organizationMembers?.find(
          (member) => member.firebaseUserId === reviewerId
        );
        if (foundReviewer) {
          setReviewerName(
            `${foundReviewer.firstName} ${foundReviewer.lastName}`
          );
          setReviewer(foundReviewer);
        }
      }

      // Handle query changes
      if (isOpen) {
        if (query === "") {
          setFilteredUsers(projectMembers?.slice(0, 4) || []);
        } else {
          filterUsers();
        }
      }
    }
  }, [query, isOpen, reviewerId, organizationMembers]);

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      setReviewerId(user?.firebaseUserId);
      setReviewerName(`${user?.firstName} ${user?.lastName}`);
      setReviewer(user);
      return;
    }

    if (organizationMembers && projectId) {
      const members = organizationMembers.filter(
        (member) =>
          member?.projectIds?.includes(projectId) ||
          member?.projectId === projectId
      );
      setProjectMembers(members);

      // Show initial suggestions when dropdown opens
      if (isOpen && query === "") {
        setFilteredUsers(members.slice(0, 4));
      }
    }
  }, [projectId, organizationMembers, isOpen]);

  useEffect(() => {
    if (selectedIndex >= 0 && listRef.current) {
      const element = listRef.current.children[selectedIndex];
      if (element) {
        element.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    }
  }, [selectedIndex]);

  const UserItem = ({ user: member, isSelected }) => (
    <HStack
      px={4}
      py={2}
      bg={isSelected ? "blue.50" : "white"}
      _hover={{ bg: "gray.50" }}
      cursor="pointer"
      onClick={() => handleSelect(member)}
    >
      <Avatar
        size="sm"
        name={`${member.firstName.toUpperCase()} ${member.lastName.toUpperCase()}`}
        src={member.imageUrl}
      />
      <VStack spacing={0} align="start" flex={1}>
        <Text fontWeight="medium">
          {`${member.firstName.toUpperCase()} ${member.lastName.toUpperCase()}`}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {member.designation}
        </Text>
      </VStack>
      {member.firebaseUserId === reviewerId && (
        <Check size={16} className="text-blue-500" />
      )}
    </HStack>
  );
  

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button
          w="full"
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          _hover={{ opacity: 0.8 }}
          p={0}
        >
          <InputGroup w="full">
            {reviewerName && !isOpen && (
              <InputLeftElement>
                <Avatar
                  name={reviewerName}
                  size="xs"
                  src={reviewer?.imageUrl}
                />
              </InputLeftElement>
            )}
            <Input
              ref={inputRef}
              w="full"
              isDisabled={!projectId}
              value={isOpen ? query.toUpperCase() : reviewerName.toUpperCase()}
              placeholder={!projectId ? "Select a project first" : "Reviewer"}
              color={selectedOrganization.isPersonal ? "blue.500" : "black"}
              fontWeight={
                selectedOrganization.isPersonal ? "semibold" : "normal"
              }
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              onClick={() => !isOpen && onOpen()}
            />
          </InputGroup>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w="300px"
        border="1px solid"
        borderColor="gray.200"
        boxShadow="lg"
      >
        <PopoverBody p={0}>
          <VStack
            ref={listRef}
            spacing={0}
            maxH="250px"
            overflowY="auto"
            align="stretch"
          >
            {filteredUsers.length > 0 ? (
              filteredUsers.map((member, index) => (
                <UserItem
                  key={member.firebaseUserId}
                  user={member}
                  isSelected={index === selectedIndex}
                />
              ))
            ) : (
              <Text p={4} color="gray.500">
                {query ? "No matching reviewers found" : "Enter reviewer name"}
              </Text>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ReporterDropDown;
