import { Box, Button, Flex, Text } from "@chakra-ui/react";

import React, { useRef } from "react";
import ProfileSetting from "./ProfileSettings/ProfileSetting";
import { LayoutState } from "../../Context/LayoutProvider";

const SettingExpanded = ({ setSelectedSetting, selectedSetting }) => {
  const { screenWidth } = LayoutState();
  const componentRef = useRef(null);

  const settingWindow = {
    profile: <ProfileSetting />,
  };

  return (
    <Box
      ref={componentRef}
      flexDir={"column"}
      height={`100%`}
      width={"100% !important"}
      overflow={"scroll !important"}
      flex={1}
      marginTop={screenWidth < 580 ? "15px" : 0}
      marginLeft={screenWidth < 580 ? "10px" : 0}
      marginRight={screenWidth < 500 ? "3px" : 2}
      marginBottom={"10px"}
      paddingLeft={screenWidth < 580 ? "10px" : "25px"}
      paddingRight={screenWidth < 580 ? "10px" : "25px"}
      paddingTop={screenWidth < 580 ? "10px" : "20px"}
      bgColor={"inherit"}
    >
      <Flex justifyContent={"stretch"} marginBottom={"10px"}>
        <Button flex={1} onClick={() => setSelectedSetting("")}>
          Back to Options
        </Button>
      </Flex>
      {selectedSetting ? (
        <Box height={"100%"} flex={1} display={"flex"} flexDir={"column"}>
          {settingWindow[selectedSetting]}
        </Box>
      ) : (
        <Text
          width={"100% !important"}
          height={"100% !important"}
          display={"flex"}
          justifyContent={"center"}
          marginRight={"20px"}
          padding={"90px"}
        >
          Select an Option to Configure
        </Text>
      )}
    </Box>
  );
};

export default SettingExpanded;
