import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGetRefreshToken = (auth, initialRender) => {
  const location = useLocation();
  const [refreshToken, setRefreshToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // will run upon currentUser initialisation, sign in, sign out
    const handleAuthStateChange = (currentUser) => {
      if (initialRender.current) {
        // console.log("here");
        return;
      }
      // console.log("here post initial render");
      try {
        // console.log(currentUser);
        if (currentUser) {
          currentUser.reload();
          const storedRefreshToken = localStorage.getItem("refresh_token");
          if (!storedRefreshToken) {
            // console.log("No refresh token found but user is signed in");

            const token = currentUser?.stsTokenManager?.refreshToken;

            if (!token) {
              throw new Error("User is logged out. Login again to continue.");
              // signout
            }

            localStorage.setItem("refresh_token", token);
            // console.log(token);

            setRefreshToken(token);
            // console.log(token);

            return;
          }

          setRefreshToken(storedRefreshToken);
        } else {
          // will be triggered whenever user signs out
          // console.log("user is signed out");
          // signOut(auth);

          localStorage.removeItem("refresh_token");
          // localStorage.removeItem("selected_organization");

          setRefreshToken(null); // will trigger access token and user to be set to null in useeffects of useGetAccessToken and useGetUser hooks

          if (
            location.pathname !== "/auth" &&
            location.pathname !== "/" &&
            !location.pathname.startsWith("/invite")
          ) {
            console.log("navigate to auth signin");
            // navigate("/auth"); // navigation to /auth is handled in AuthProvider.js in the useEffect [user]
            throw new Error(
              "Unauthorized. Need to log in in order to access this page."
            );
          }
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // This will run once when the component mounts
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  return { refreshToken, loading, error };
};

export default useGetRefreshToken;
