"use client";

import {
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
  Button,
  VStack,
  Textarea,
  Text,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../../Context/UserProvider";

const CreateProjectsModal = ({ isOpen, onClose }) => {
  const { user, selectedOrganization, createProject } = UserState();
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [management, setManagement] = useState("agile");
  const toast = useToast();

  const handleCreateNewProject = async (e) => {
    if (!projectName || !description) {
      toast({
        title: "Insufficient data",
        description: "All fields are required to create a new project.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (selectedOrganization.members[user.firebaseUserId] !== "Admin") {
      toast({
        title: "Cannot create project.",
        description: "Only Organization Admins can create new projects.",
        status: "warning",
        duration: 2500,
        isClosable: true,
      });
      return;
    }

    try {
      const requestData = {
        projectName,
        description,
        management,
      };

      const newProject = await createProject(requestData);

      if (!newProject?.id) {
        toast({
          title: "Error occured.",
          description: "Project creation failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        onClose();
        setDescription("");
        setProjectName("");
        setManagement("agile");
      }
    } catch (err) {
      toast({
        title: "Error creating project",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen && !selectedOrganization) {
      toast({
        title: "Cannot create project",
        description:
          "An organization must be selected before creating a new project",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={3} margin="10px" borderRadius="xl" boxShadow="xl">
        <ModalHeader
          bg="gray.50"
          borderTopRadius="xl"
          borderBottom="1px"
          borderColor="gray.200"
          py={4}
        >
          <Text fontSize="xl" fontWeight="bold">
            Create New Project
          </Text>
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody py={6}>
          <VStack spacing={5}>
            <FormControl>
              <FormLabel fontWeight="medium">Project Name</FormLabel>
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Enter project name"
                borderRadius="md"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter project description"
                borderRadius="md"
                rows={4}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">Management Preference</FormLabel>
              <HStack justify="center" gap={2}>
                <Button
                  variant={management === "agile" ? "solid" : "outline"}
                  onClick={() => setManagement("agile")}
                >
                  Agile
                </Button>
                <Button
                  variant={management === "kanban" ? "solid" : "outline"}
                  onClick={() => setManagement("kanban")}
                >
                  Kanban
                </Button>
              </HStack>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.200" py={4}>
          <Button
            colorScheme="blue"
            onClick={handleCreateNewProject}
            borderRadius="md"
            px={8}
          >
            Create Project
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProjectsModal;
