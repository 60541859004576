import React, { useState, useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import Navbar from "./Navbar";
import Board from "./Board";
import Backlogs from "./Backlogs";
import Overview from "./ProjectSettings/Settings";
import Milestones from "./Milestones/Milestones";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";
import KanbanBoard from "./KanbanBoard";
import Inventory from "./Inventory/Inventory";

const Main = ({ viewingProject }) => {
  const { sidebarWidth, closeSidebar, isSidebarOpen } = LayoutState();
  const [selectedNav, setSelectedNav] = useState(
    viewingProject?.management === "agile" ? "board" : "kanban_board"
  );
  const [firstTimeViewing, setFirstTimeViewing] = useState(true);
  const [topHeight, setTopHeight] = useState(115);
  const [projectMembers, setProjectMembers] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [renderedComponentHeight, setRenderedComponentHeight] =
    useState(`calc(100vh - 155px)`);

  const { organizationMembers } = UserState();

  const navs = {
    agile: [
      { id: 1, name: "overview", value: "Overview" },
      { id: 2, name: "board", value: "Board" },
      { id: 3, name: "backlog", value: "Backlog" },
      { id: 4, name: "milestones", value: "Milestones" },
      { id: 5, name: "inventory", value: "Inventory" },
    ],
    kanban: [
      { id: 1, name: "overview", value: "Overview" },
      { id: 2, name: "kanban_board", value: "Board" },
      { id: 3, name: "inventory", value: "Inventory" },
    ],
  };

  const components = {
    overview: (
      <Overview
        viewingProject={viewingProject}
        projectMembers={projectMembers}
      />
    ),
    board: (
      <Board
        viewingProject={viewingProject}
        assignees={projectMembers}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
      />
    ),
    backlog: <Backlogs viewingProject={viewingProject} />,
    milestones: <Milestones viewingProject={viewingProject} />,
    kanban_board: (
      <KanbanBoard
        viewingProject={viewingProject}
        assignees={projectMembers}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
      />
    ),
    inventory: <Inventory viewingProject={viewingProject} />,
  };

  useEffect(() => {
    if (!firstTimeViewing) {
      return;
    } else {
      setFirstTimeViewing(false);
    }
    if (viewingProject?.management === "agile") {
      if (
        (!viewingProject?.sprints?.activeSprint ||
          viewingProject?.sprints?.activeSprint === "None") &&
        selectedNav === "board"
      ) {
        setSelectedNav("backlog");
      } else {
        setSelectedNav("board");
      }
    } else if (viewingProject?.management === "kanban") {
      setSelectedNav("kanban_board");
    }
  }, [viewingProject]);

  useEffect(() => {
    if (viewingProject?.id) {
      setProjectMembers(() =>
        organizationMembers.filter((member) => {
          return (
            member?.projectIds?.includes(viewingProject.id) ||
            member?.projectId === viewingProject.id
          );
        })
      );

      if (
        viewingProject?.management === "agile" &&
        selectedNav === "kanban_board"
      ) {
        setSelectedNav("board");
      } else if (
        viewingProject?.management === "kanban" &&
        selectedNav === "board"
      ) {
        setSelectedNav("kanban_board");
      }
    }
  }, [viewingProject, organizationMembers]);

  useEffect(() => {
    if (selectedNav === "board" || selectedNav === "kanban_board") {
      if (isFullscreen) {
        setRenderedComponentHeight(`calc(100vh - ${topHeight}px)`);
      } else {
        const topElement = document.getElementById(
          "project-page-navbar-container-0"
        );
        let heightToLeave = 155;
        if (topElement) {
          heightToLeave =
            topElement.clientHeight + topElement.getBoundingClientRect().top;
        }

        setRenderedComponentHeight(`calc(100vh - ${heightToLeave}px)`);
      }
    } else {
      setRenderedComponentHeight(`calc(100vh - ${topHeight + 10}px)`);
    }
  }, [selectedNav, isFullscreen]);

  useEffect(() => {
    if (isFullscreen && isSidebarOpen) {
      closeSidebar();
    }

    const headingElement =
      document.getElementsByClassName("header-container")[0];
    if (headingElement) {
      const heightToLeave =
        headingElement.clientHeight +
        headingElement.getBoundingClientRect().top;
      setTopHeight(heightToLeave);
    }
  }, [isFullscreen]);

  return (
    <Box
      overflowY="auto"
      flex={1}
      marginRight={0}
      marginLeft={sidebarWidth}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      display={"flex"}
      flexDir={"column"}
      height={"100vh"}
      maxHeight={"100vh"}
      id="project-main-container"
    >
      {!isFullscreen && (
        <Heading
          my={0}
          py={3}
          pb={0}
          ml={4}
          as="h1"
          size="lg"
          fontWeight="bold"
          color="gray.700"
          id="project-page-heading"
        >
          {viewingProject?.projectName || "Project"}
        </Heading>
      )}
      {!isFullscreen && (
        <Navbar
          viewingProject={viewingProject}
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
          navs={navs}
        />
      )}
      <Box
        width={"100%"}
        height={renderedComponentHeight}
        paddingY={2}
        id="project-page-component-container-0"
      >
        {components[selectedNav]}
      </Box>
    </Box>
  );
};

export default Main;
