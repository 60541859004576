import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";
import { BsFillBuildingFill } from "react-icons/bs";
import { EmailIcon, StarIcon } from "@chakra-ui/icons";
import { AuthState } from "../../../Context/AuthProvider";
import Skills from "./Skills";
import { useNavigate } from "react-router-dom";
import OrgItem from "./OrgItem";

const Details = () => {
  const { organizations, reloadUser, leaveOrganization } = UserState();
  const { user } = AuthState();
  const { screenWidth } = LayoutState();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [weeklyHours, setWeeklyHours] = useState(user?.weeklyHours);
  const [userOrgs, setUserOrgs] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  const leaveOrg = async (org) => {
    try {
      await leaveOrganization(org.id);
      reloadUser();
      navigate("/dashboard");
    } catch (error) {
      toast({
        title: "Couldn't leave Organization",
        isClosable: true,
        duration: 1000,
      });
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  // };

  // useEffect(() => {
  //   setUserOrgs(() => {
  //     user && Array.isArray(user.orgs) && user.orgs?.length > 0 && organizations
  //       ? user.orgs.map((orgId) => {
  //           const org = organizations.find((org) => org.id === orgId);
  //           org.userRole = user.roles[org.id];
  //           return org;
  //         })
  //       : []})
  // }, organizations)

  useEffect(() => {
    if (!user) {
      return;
    }

    setUserOrgs(() => {
      const orgs = Array.from(Object.keys(user.orgs));
      if (orgs.length > 0 && organizations.length > 0) {
        const orgsRoles = orgs.reduce((acc, orgId) => {
          const org = organizations.find((org) => org.id === orgId);
          if (org) {
            org.userRole = user?.orgs[org.id];
            acc.push(org);
          }
          return acc;
        }, []);

        return orgsRoles;
      } else {
        return [];
      }
    });
  }, [organizations]);

  return (
    <Box
      marginTop={"25px"}
      width={"100%"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"left"}
      height={"100%"}
      flex={1}
      gap={5}
    >
      <Stack direction={screenWidth < 500 ? "column" : "row"} gap={5}>
        <FormControl id="signup-first-name" isReadOnly>
          <FormLabel>First Name</FormLabel>
          <Input
            borderColor={"gray !important"}
            _hover={{
              borderColor: "gray.400 !important",
              cursor: "pointer !important",
            }}
            placeholder="Enter Your First Name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </FormControl>
        <FormControl id="signup-last-name" isReadOnly>
          <FormLabel>Last Name</FormLabel>
          <Input
            borderColor={"gray !important"}
            _hover={{
              borderColor: "gray.400 !important",
              cursor: "pointer !important",
            }}
            placeholder="Enter Your Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </FormControl>
      </Stack>
      <FormControl id="signup-email" isReadOnly>
        <FormLabel>Email</FormLabel>
        <InputGroup size={"md"}>
          <InputLeftElement>
            <EmailIcon />
          </InputLeftElement>
          <Input
            borderColor={"gray !important"}
            _hover={{
              borderColor: "gray.400 !important",
              cursor: "pointer !important",
            }}
            placeholder="Enter Your Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </InputGroup>
      </FormControl>
      <FormControl id="setting-page-profile-email" isReadOnly>
        <FormLabel>Weekly Hours</FormLabel>
        <InputGroup size={"md"}>
          <Input
            borderColor={"gray !important"}
            _hover={{
              borderColor: "gray.400 !important",
              cursor: "pointer !important",
            }}
            placeholder="Enter Your Weekly Hours"
            onChange={(e) => {
              setWeeklyHours(e.target.value);
            }}
            value={weeklyHours}
          />
        </InputGroup>
      </FormControl>
      <FormControl id="company-names" isReadOnly>
        <FormLabel>
          {userOrgs?.length > 1 ? "Organizations" : "Organization"}
        </FormLabel>
        <VStack spacing={"5px"}>
          {userOrgs &&
            Array.isArray(userOrgs) &&
            userOrgs.length > 0 &&
            userOrgs.map((org) => (
              <OrgItem key={org.id} org={org} leaveOrg={leaveOrg} />
            ))}
        </VStack>
      </FormControl>
      <Skills />
    </Box>
  );
};

export default Details;
