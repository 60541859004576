import { useEffect, useRef, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { USER_BASE_URL as baseUrl } from "../baseurls";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { useToast } from "@chakra-ui/react";
import { googleProvider, microsoftProvider } from "../firebaseconfig";

const useUser = (auth, accessToken) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);
  const preventExecution = useRef(false);
  const isReloadingUserMetrics = useRef(false);
  const isUpdatingProfilePicUrlRef = useRef(false);
  const toast = useToast();
  const checkUserExists = async (email, firebaseUserId, userAccessToken) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userAccessToken}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/check_user_exists/${email}/${firebaseUserId}`,
        config
      );

      return response.data.exists;
    } catch (error) {
      throw error;
    }
  };

  const updateUserProfilePicture = async (formData) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${baseUrl}/user/uploadpic/${user.id}`,
        formData,
        config
      );

      if (response.status === 200) {
        toast({
          title: "Profile picture updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      const path = response?.data?.file_path;
      if (!path) {
        throw new Error("recieved empty url string from server");
      }

      setUser((val) => ({ ...val, imagePath: path }));
    } catch (err) {
      // console.log(err);
      // setError("Failed to update profile picture");
      console.log("Failed to update profile picture", err);
      throw new Error(err);
    }

    return true;
  };

  const findMembersDataOnPlatform = async (emails) => {
    try {
      const response = await axios.post(
        `${baseUrl}/find_users`,
        { emails },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response?.data?.users;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const leaveOrganization = async (orgId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${baseUrl}/leave_organization`,
        { orgId },
        config
      );
    } catch (err) {
      // console.log(err);
      // setError("Failed to leave organization");
      console.log("Failed to leave organization", err);
      throw new Error(err);
    }
  };

  const getSignedProfilePicUrl = async () => {
    if (!user || !user?.imagePath || !isUpdatingProfilePicUrlRef.current) {
      console.log(`Not Updating profile pic url.`);
      return;
    }

    isUpdatingProfilePicUrlRef.current = false;

    console.log("hello here in getSignedProfilePicUrl");

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/user/viewpic/${user.id}`,
        config
      );

      const url = response?.data?.url;

      if (url) {
        preventUserFetch();
        setUser((object) => {
          // console.log({ ...object, imageUrl: url });

          return { ...object, imageUrl: url };
        });
      }
    } catch (err) {
      // console.log(err);
      // setError("Failed to get signed url for profile pic");
      // throw new Error("Failed to add members");
      console.log("Failed to get signed url for profile pic", err);
    }
  };

  const createFirebaseUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  };

  const deleteFirebaseUser = async (user) => {
    try {
      await deleteUser(user);
      // console.log("User deleted successfully");
    } catch (error) {
      // console.error("Error deleting user:", error);
      throw error;
    }
  };

  const saveUserData = async (userData) => {
    try {
      const response = await axios.post(`${baseUrl}/create_user`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateUserSkills = async (skills) => {
    if (!skills || !user || !accessToken || Object.keys(skills).length === 0) {
      return;
    }

    try {
      const response = await axios.put(
        `${baseUrl}//update_user_skills/${user.firebaseUserId}`,
        { skills },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      reloadUserMetrics();
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // provider: google or microsoft
  const handle3rdPartyAuth = async (provider) => {
    try {
      let result = null;
      if (provider.toLowerCase() === "google") {
        result = await signInWithPopup(auth, googleProvider);
        console.log("Google User info:", result.user);
      } else if (provider.toLowerCase() === "microsoft") {
        result = await signInWithPopup(auth, microsoftProvider);
        console.log("Microsoft User info:", result.user);
      } else {
        throw new Error("Invalid provider");
      }

      return result?.user;
    } catch (error) {
      console.error(`Error during 3rd party sign-in using ${provider}:`, error);
      // throw error;
    }
  };

  const preventUserFetch = () => {
    preventExecution.current = true;
  };

  const allowUserFetch = () => {
    preventExecution.current = false;
  };

  const reloadUserMetrics = () => {
    isReloadingUserMetrics.current = true;

    reload();
  };

  const userMetricsReloadComplete = () => {
    isReloadingUserMetrics.current = false;
  };

  const reload = () => {
    allowUserFetch();
    setReloadFlag((prevValue) => prevValue + 1);
  };

  const getUserData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.get(`${baseUrl}/fetch_user`, config);

      const userData = response?.data?.data;
      console.log("userdata -> ", userData);

      if (userData) {
        // console.log(userData);
        setUser(userData);
      } else {
        throw new Error("User not found");
      }
    } catch (err) {
      setError("Error while fetching user data");
    } finally {
      setLoading(false);
      preventUserFetch();
    }
  };

  useEffect(() => {
    if (preventExecution.current) {
      // allowUserFetch();
      return;
    }

    if (!accessToken) {
      setUser(null);
      if (accessToken === undefined) {
        setError("Cannot fetch user data without access token");
      }
      return;
    }
    // console.log(auth);

    const sendEmailVerificationLink = async () => {
      try {
        if (!preventExecution.current) {
          await sendEmailVerification(auth.currentUser);
          toast({
            title: `Verification link sent.`,
            description: `Verification link has been sent to your email.`,
            status: "success",
            duration: 3800,
            isClosable: true,
          });
        }
        preventExecution.current = true;
        setLoading(false);
      } catch (error) {
        preventExecution.current = true;
        setLoading(false);
      }
    };

    if (!auth.currentUser.emailVerified) {
      // console.log("here");
      sendEmailVerificationLink();
    }

    getUserData();
  }, [accessToken, reloadFlag]);

  useEffect(() => {
    let intervalId;

    intervalId = setInterval(() => {
      console.log("this is running: getSignedProfilePicUrl()");
      isUpdatingProfilePicUrlRef.current = true;
      getSignedProfilePicUrl();
    }, 58 * 60 * 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user]);

  //
  const fetchMemberData = async (firebaseUserId, orgId) => {
    if (!firebaseUserId || !orgId) {
      setError("Missing userId or orgId");
      return;
    }

    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": orgId,
        },
      };
      const response = await axios.get(
        `${baseUrl}/get-user-data?userId=${firebaseUserId}&orgId=${orgId}`,
        config
      );
      return response?.data?.data;
    } catch (err) {
      // setError(err.response?.data?.message || "Failed to fetch user data");
      console.log("error from fetchMemberData in useUser", err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    user,
    setUser,
    loading,
    error,
    reload,
    reloadUserMetrics,
    isReloadingUserMetrics,
    userMetricsReloadComplete,
    preventUserFetch,
    allowUserFetch,
    createFirebaseUser,
    deleteFirebaseUser,
    saveUserData,
    updateUserSkills,
    leaveOrganization,
    findMembersDataOnPlatform,
    handle3rdPartyAuth,
    getUserData,
    checkUserExists,
    updateUserProfilePicture,
    isUpdatingProfilePicUrlRef,
    isLoading,
    fetchMemberData,
  };
};

export default useUser;
