import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import Main from "../AssistantPageComponents/Main";
import { useDisclosure } from "@chakra-ui/react";
const FloatingChatbot = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleChatbot = () => {
    setIsChatbotOpen((prev) => !prev);
  };

  return (
    <>
      {/* Floating Action Button */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "60px",
          height: "60px",
          backgroundColor: "#fff",
          borderRadius: "50%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 1000,
        }}
        onClick={toggleChatbot}
        aria-label="Toggle Chatbot"
        role="button"
      >
        <img
          src="/favicon.ico"
          alt="lumiproject Icon"
          style={{ width: "32px", height: "32px" }}
        />
      </div>

      {/* Chatbot Modal or Drawer */}
      {isChatbotOpen && (
        <Box
          position="fixed"
          bottom="80px"
          right="20px"
          width="300px"
          height="400px"
          bg="white"
          borderRadius="lg"
          shadow="lg"
          overflowY={"scroll"}
          zIndex={1000}
          p={4}
        >
          <Main isOpen={isOpen} onClose={onClose} chatIconCall={true} />
        </Box>
      )}
    </>
  );
};

export default FloatingChatbot;
