import {
  Avatar,
  Box,
  Button,
  Image,
  Input,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { IoMdDocument } from "react-icons/io";
import ExpandedCommentView from "./ExpandedCommentView";
import { useNavigate } from "react-router-dom";

const AllTaskComments = ({ setTask, task }) => {
  const { user } = AuthState();
  const {
    taskComments,
    deleteTaskComment,
    updateTaskComment,
    loadMoreTaskComments,
    organizationMembers,
  } = UserState();
  const [editingComment, setEditingComment] = useState(null);
  const [updatedCommentContent, setUpdatedCommentContent] = useState("");
  const [updatedCommentTaggedUsers, setUpdatedCommentTaggedUsers] = useState(
    []
  );
  const [componentHeight, setComponentHeight] = useState("100%");
  const [expandedComment, setExpandedComment] = useState(null);
  const commentsContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleCommentExpansion = (e) => {
    const commentId = e.target.id;
    const commentToExpand = taskComments.find(
      (comment) => comment.id === commentId
    );
    if (commentToExpand?.id) {
      setExpandedComment(commentToExpand);
    } else {
      setExpandedComment(null);
    }
  };

  const handleCloseExpanded = () => {
    setExpandedComment(null);
  };

  const handleCommentDelete = async (e, comment) => {
    try {
      await deleteTaskComment(comment.id);
      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask.commentsCount - 1,
      }));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleCancelUpdate = (e) => {
    setUpdatedCommentContent("");
    setUpdatedCommentTaggedUsers([]);
    setEditingComment(null);
  };

  const handleCommentEditClicked = async (e, comment) => {
    if (!editingComment) {
      setUpdatedCommentContent(comment.content);
      setUpdatedCommentTaggedUsers(comment.taggedUsers || []);
      setEditingComment(comment.id);
      return;
    }

    if (updatedCommentContent === "") {
      await handleCommentDelete(e, comment);
    } else {
      const newTaggedUsers = updatedCommentContent.match(/@(\w+\s\w+)/g) || [];
      if (updatedCommentContent !== comment.content || newTaggedUsers.length) {
        try {
          await updateTaskComment(
            comment.id,
            updatedCommentContent,
            newTaggedUsers
          );
        } catch (error) {
          console.error("Error updating comment:", error);
        }
      }

      setUpdatedCommentTaggedUsers([]);
      setUpdatedCommentContent("");
    }
    setEditingComment(null);
  };

  const highlightTaggedUsers = (content, taggedUsers) => {
    if (!taggedUsers || taggedUsers.length === 0) return content;

    const parts = content.split(/@(\w+\s\w+)/g);
    // get tagged user's full name from firebaseUserId
    const taggedUsersData = organizationMembers.filter((member) =>
      taggedUsers.includes(member.firebaseUserId)
    );
    return parts.map((part, index) => {
      const taggedUser = taggedUsersData.find(
        (user) => `${user.firstName} ${user.lastName}` === part
      );
      if (taggedUser) {
        return (
          <Tooltip label="Visit Profile">
            <Text
              as="span"
              key={index}
              fontWeight="bold"
              color="blue.500"
              whiteSpace={"pre-wrap"}
              onClick={() => {
                navigate(`/profile/${taggedUser.firebaseUserId}`);
              }}
              cursor={"pointer"}
            >
              @{part}
            </Text>
          </Tooltip>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [taskComments]);

  useEffect(() => {
    const tabListElement = document.getElementById("view-task-modal-tabs-list");

    const tabListTop = tabListElement
      ? tabListElement.getBoundingClientRect().top + tabListElement.offsetHeight
      : 0;

    const modalElement = document.getElementsByClassName(
      "view-task-modal-content"
    )[0];
    const modalHeight = modalElement
      ? modalElement.getBoundingClientRect().height
      : 0;

    const height = modalHeight - tabListTop - 150;
    setComponentHeight(`${height}px`);
  }, []);

  return (
    <Box
      ref={commentsContainerRef}
      marginTop={1}
      paddingTop={0}
      display="flex"
      flexDir="column"
      justifyContent="flex-end"
      paddingY={2}
      height="100%"
      flex={1}
      overflowY="auto"
    >
      <Box
        marginTop={0}
        paddingTop={0}
        display="flex"
        flexDir="column"
        flex={1}
      >
        {!taskComments ||
        (Array.isArray(taskComments) && taskComments.length <= 0) ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            <Text color="gray.500" textAlign={"center"}>
              No comments under this task.
            </Text>
          </Box>
        ) : (
          <VStack
            spacing={2}
            alignItems="stretch"
            width="100%"
            height={componentHeight}
            // overflowX={"hidden"}
          >
            {taskComments.map((comment) => {
              if (comment.authorId === user.firebaseUserId) {
                comment.author = user;
              }

              return (
                <Box
                  key={comment.id}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  mb={2}
                  ml={
                    comment.author.firebaseUserId === user?.firebaseUserId &&
                    "auto"
                  }
                >
                  <Box display="flex" alignItems="flex-start" width="100%">
                    {comment.author.firebaseUserId !== user?.firebaseUserId && (
                      <Avatar
                        src={comment.author?.imageUrl}
                        name={
                          comment.author
                            ? `${comment.author.firstName} ${comment.author.lastName}`
                            : ""
                        }
                        size="sm"
                        marginRight={2}
                        marginTop={3}
                      />
                    )}
                    <Box
                      bgColor={
                        comment.author.firebaseUserId === user?.firebaseUserId
                          ? "blue.50"
                          : "white"
                      }
                      color="gray.800"
                      padding={1}
                      margin={0}
                      borderRadius="lg"
                      boxShadow="md"
                      borderWidth="1px"
                      borderColor="gray.200"
                      width="fit-content"
                      maxWidth="100%"
                    >
                      <Text
                        fontSize="xs"
                        fontWeight="600"
                        color="gray.600"
                        mb={1}
                        ml={2}
                      >
                        {comment.author
                          ? `${comment.author.firstName} ${comment.author.lastName}`
                          : ""}
                      </Text>
                      {editingComment === comment.id ? (
                        <Input
                          type="text"
                          value={updatedCommentContent}
                          onChange={(e) =>
                            setUpdatedCommentContent(e.target.value)
                          }
                          size="sm"
                          mb={2}
                        />
                      ) : (
                        <Text
                          fontSize="sm"
                          mb={2}
                          ml={2}
                          whiteSpace={"no-wrap"}
                        >
                          {highlightTaggedUsers(
                            comment.content,
                            comment.taggedUsers
                          )}
                        </Text>
                      )}
                      {comment.attachment && (
                        <Box mb={2}>
                          {!comment.attachment.includes(".pdf") ? (
                            <Image
                              id={comment.id}
                              src={`${comment.attachment}`}
                              onClick={handleCommentExpansion}
                              maxHeight="50px"
                              _hover={{ cursor: "pointer" }}
                              alt={`${comment.attachment
                                .substring(
                                  comment.attachment.indexOf("_"),
                                  comment.attachment.indexOf("?")
                                )
                                .substring(0, 10)}...`}
                            />
                          ) : (
                            <Box
                              display="flex"
                              alignItems="center"
                              _hover={{ cursor: "pointer" }}
                            >
                              <IoMdDocument />
                              <Link
                                href={`${comment.attachment}`}
                                ml={1}
                                fontSize="sm"
                              >
                                {comment.attachment
                                  .substring(
                                    comment.attachment.indexOf("_") + 1,
                                    comment.attachment.indexOf("?")
                                  )
                                  .substring(0, 10)}
                                ...
                              </Link>
                            </Box>
                          )}
                        </Box>
                      )}
                      {comment.authorId === user.firebaseUserId && (
                        <Box fontSize="xs">
                          <Button
                            size="xs"
                            variant="ghost"
                            colorScheme="blue"
                            mr={2}
                            onClick={(e) =>
                              handleCommentEditClicked(e, comment)
                            }
                          >
                            {editingComment === comment.id ? "Save" : "Edit"}
                          </Button>
                          <Button
                            size="xs"
                            variant="ghost"
                            colorScheme="red"
                            onClick={(e) => handleCommentDelete(e, comment)}
                            display={editingComment ? "none" : "inline-flex"}
                          >
                            Delete
                          </Button>
                          <Button
                            size="xs"
                            variant="ghost"
                            colorScheme="red"
                            onClick={(e) => handleCancelUpdate(e, comment)}
                            display={
                              editingComment && editingComment === comment.id
                                ? "inline-flex"
                                : "none"
                            }
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}
                    </Box>
                    {comment.author.firebaseUserId === user?.firebaseUserId && (
                      <Avatar
                        src={comment.author?.imageUrl}
                        name={
                          comment.author
                            ? `${comment.author.firstName} ${comment.author.lastName}`
                            : ""
                        }
                        size="sm"
                        ml={2}
                        marginTop={3}
                      />
                    )}
                  </Box>
                  <Box
                    fontSize="xs"
                    color="gray.500"
                    mt={1}
                    ml={
                      comment.author.firebaseUserId !== user?.firebaseUserId &&
                      12
                    }
                    mr={
                      comment.author.firebaseUserId === user?.firebaseUserId &&
                      12
                    }
                    display="flex"
                    flexDir="row"
                    gap={2}
                  >
                    <Box>
                      {new Date(comment.createdAt).toLocaleDateString()}
                    </Box>
                    <Box>
                      {new Date(comment.createdAt).toLocaleTimeString()}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>
      {taskComments &&
        Array.isArray(taskComments) &&
        task &&
        taskComments.length < task.commentsCount && (
          <Box
            color="blue.500"
            fontSize="sm"
            fontWeight="600"
            marginTop={2}
            onClick={loadMoreTaskComments}
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Load ({task.commentsCount - taskComments.length}) more comments...
          </Box>
        )}
      {expandedComment && (
        <ExpandedCommentView
          isOpen={!!expandedComment}
          onClose={handleCloseExpanded}
          comment={expandedComment}
        />
      )}
    </Box>
  );
};

export default AllTaskComments;
