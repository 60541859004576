"use client";

import { Box, Text, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";

const Settings = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { selectedOrganization } = UserState();
  const { isSidebarOpen } = LayoutState();

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  return (
    <>
      {isSidebarOpen ? (
        <Box className="settings-container" px={4} pb={0}>
          <Box
            as="button"
            onClick={() => {
              if (selectedOrganization?.isVerified) {
                handleSettingsClick();
              }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="full"
            py={3}
            px={4}
            borderRadius="full"
            bg={isHovered ? "blue.500" : "gray.100"}
            color={isHovered ? "white" : "gray.800"}
            fontWeight="semibold"
            transition="all 0.3s ease"
            _hover={{ transform: "scale(1.05)" }}
            _active={{ transform: "scale(0.95)" }}
            _focus={{ boxShadow: "outline" }}
            boxShadow={"1px 1px 10px #ececec"}
            border={"2px solid #ececec"}
          >
            <IoMdSettings style={{ marginRight: "8px", fontSize: "20px" }} />
            <Text>Settings</Text>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"md"}
          p={1}
          cursor={"pointer"}
          onClick={() => {
            if (selectedOrganization?.isVerified) {
              handleSettingsClick();
            }
          }}
        >
          <IoMdSettings size={"1.5rem"} />
        </Box>
      )}
    </>
  );
};

export default Settings;
