import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  useToast,
  FormHelperText,
  useColorModeValue,
  Icon,
  InputGroup,
  InputLeftElement,
  Flex,
  Image,
} from "@chakra-ui/react";
import { FaBuilding, FaGlobe } from "react-icons/fa";
import { UserState } from "../Context/UserProvider";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LayoutState } from "../Context/LayoutProvider";
import lumilogo from "../assets/lumiproject-logo.png";
import luminame from "../assets/lumiproject-name.png";

const CreateOrganizationPage = () => {
  const [orgName, setOrgName] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  const [pmbotName, setPmbotName] = useState("");
  const { selectOrganization, setOrganizations, createOrganization } =
    UserState();
  const { sidebarWidth, screenWidth } = LayoutState();

  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const isValidDomain = () => {
    const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z]{2,}$/i;

    return domainRegex.test(orgDomain);
  };

  const handleCreateNewOrgClicked = async (e) => {
    e.preventDefault();
    if (!orgName || !orgDomain) {
      toast({
        title: "Insufficient data",
        description: `Both organization name and domain are required fields to create a new organization.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else if (!isValidDomain()) {
      toast({
        title: "Invalid domain.",
        description: orgDomain,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const newOrganization = await createOrganization(null, {
        orgName,
        orgDomain,
        pmbotName,
      });

      if (!newOrganization) {
        throw new Error("error in creating organization");
      }

      selectOrganization(newOrganization.id);
      setOrganizations((orgs) =>
        orgs ? [...orgs, { ...newOrganization }] : [{ ...newOrganization }]
      );

      toast({
        title: "Organization created successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate("/dashboard"); // Redirect to dashboard after creation
    } catch (err) {
      console.error(err);
      toast({
        title: "Error creating organization",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={"white"}
      boxShadow={"md"}
      position="fixed"
      top="57px"
      left={sidebarWidth}
      right="0"
      bottom="0"
      transition="left 0.3s ease"
      overflow="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={{ base: 4, md: 8 }} // Add padding for smaller screens
      flexDir={"column"}
    >
      <Box
        bg={cardBgColor}
        borderRadius="lg"
        boxShadow="xl"
        p={4}
        ml={sidebarWidth}
        borderWidth={1}
        borderColor={borderColor}
        width={{ base: "100%", md: "container.md", sm: "container.sm" }} // Adjust width for smaller screens
        maxWidth="100%"
        maxH={"80vh"}
        overflow={"auto"}
        mx={{ base: 2, md: "auto" }} // Center the box with margin for smaller screens
      >
        <Heading as="h1" size="lg" textAlign="left" mb={7}>
          Create {screenWidth > 500 && " A New "} Organization
        </Heading>
        <Box as="form" onSubmit={handleCreateNewOrgClicked}>
          <VStack spacing={6} align="stretch">
            <FormControl isRequired>
              <FormLabel>Organization Name</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaBuilding} color="gray.300" />
                </InputLeftElement>
                <Input
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  placeholder="Enter organization name"
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Organization Domain</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaGlobe} color="gray.300" />
                </InputLeftElement>
                <Input
                  value={orgDomain}
                  onChange={(e) => setOrgDomain(e.target.value)}
                  placeholder="Enter organization domain"
                />
              </InputGroup>
              <FormHelperText>
                Example: hello.com, lumiproject.app, lumiorc.com <br />
                Note: You will be asked to verify your domain after creating the
                organization
              </FormHelperText>
            </FormControl>
            {/* <FormControl>
              <FormLabel>PM-Bot Name</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaRobot} color="gray.300" />
                </InputLeftElement>
                <Input
                  value={pmbotName}
                  onChange={(e) => setPmbotName(e.target.value)}
                  placeholder="Enter PM-Bot name (optional)"
                />
              </InputGroup>
              <FormHelperText>
                You can choose a custom name for your Organization's PM-Bot.
              </FormHelperText>
            </FormControl> */}
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              mt={4}
              isFullWidth
              fontWeight="bold"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
              transition="all 0.2s"
            >
              Create Organization
            </Button>
          </VStack>
        </Box>
      </Box>
      {screenWidth > 500 && (
        <Flex
          onClick={() => navigate("/")}
          cursor="pointer"
          alignItems={"center"}
          justifyContent={"center"}
          mt={12}
        >
          <Image src={lumilogo} height={"80px"} />
          <Image src={luminame} height={"80px"} />
        </Flex>
      )}
    </Box>
  );
};

export default CreateOrganizationPage;
