import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { LayoutState } from "../../../Context/LayoutProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { UserState } from "../../../Context/UserProvider";
import CreateMyTaskModal from "./CreateMyTaskModal";
import ViewTaskModal from "./ViewTaskModal";
import DeleteTaskButton from "./DeleteTaskButton";
import StatusDropDown from "../Dropdowns/StatusDropDown";
import PriorityDropDown from "../Dropdowns/PriorityDropDown";
import { taskStatusStyle, taskStatusText, taskPriorityStyles } from "./styles";
import { isSelected, findFirstName, findName } from "./utils";
import { FilterIcon } from "lucide-react";

const taskStatusStyleLight = {
  done: "#C8E6C9",
  inProgress: "#F9E79F",
  blocked: "#FFC2C7",
  toDo: "#F5F5F5",
  review: "#B3E5FC",
};

const MyTasks = ({ isPhoneSideBarOpen, taskRef }) => {
  const { isPhone, screenWidth } = LayoutState();
  const {
    myTasks,
    projects,
    organizationMembers,
    setMyTasks,
    updateTaskPriority,
    updateTaskStatus,
  } = UserState();
  const { user } = AuthState();
  const [tasksToDisplay, setTasksToDisplay] = useState([]);
  const [selectedMyTasks, setSelectedMyTasks] = useState([]);
  const [spacingFromTop, setSpacingFromTop] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewModalTask, setViewModalTask] = useState(null);
  const [clickedTask, setClickedTask] = useState(null);
  const [clickedTaskStatus, setClickedTaskStatus] = useState(null);
  const [clickedTaskPriority, setClickedTaskPriority] = useState(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [taskNameFilter, setTaskNameFilter] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [sprintFilter, setSprintFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBgColor = useColorModeValue("gray.50", "gray.700");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("purple.50", "purple.900");

  const closeViewTaskModal = () => {
    setIsViewModalOpen(false);
    setViewModalTask(null);
  };

  const handleDropDownsSectionClick = (e, task) => {
    setClickedTask(task);
    setViewModalTask(null);
  };

  const selectUnselectAll = () => {
    if (!allSelected) {
      setSelectedMyTasks([...(tasksToDisplay || [])]);
    } else {
      setSelectedMyTasks([]);
    }
  };

  const resetClickedTask = () => {
    setClickedTask(null);
    setClickedTaskPriority(null);
    setClickedTaskStatus(null);
  };

  useEffect(() => {
    if (
      !myTasks ||
      !Array.isArray(myTasks) ||
      myTasks.length === 0 ||
      !viewModalTask
    ) {
      return;
    }

    let hasChangedFlag = false;

    const updatedMyTasks = myTasks.map((myTask) => {
      if (myTask.id !== viewModalTask.id) {
        return myTask;
      }

      if (myTask.commentsCount !== viewModalTask.commentsCount) {
        hasChangedFlag = true;
      }

      return viewModalTask;
    });

    if (hasChangedFlag) {
      setMyTasks(updatedMyTasks);
    }
  }, [viewModalTask]);

  useEffect(() => {
    if (clickedTask) {
      setClickedTaskStatus(clickedTask?.status);
      setClickedTaskPriority(clickedTask?.priority);
      closeViewTaskModal();
    }
  }, [clickedTask]);

  useEffect(() => {
    if (
      clickedTask &&
      clickedTaskPriority &&
      clickedTaskStatus &&
      (clickedTaskPriority !== clickedTask?.priority ||
        clickedTaskStatus !== clickedTask?.status)
    ) {
      let clickedTaskIdx = -1;
      for (const idx in myTasks) {
        if (myTasks[idx].id === clickedTask.id) {
          clickedTaskIdx = idx;
          break;
        }
      }

      if (clickedTaskPriority !== clickedTask.priority) {
        updateTaskPriority(clickedTask, clickedTaskPriority, clickedTaskIdx);
      }

      if (clickedTaskStatus !== clickedTask.status) {
        updateTaskStatus(
          clickedTask,
          clickedTaskStatus,
          clickedTask.status,
          clickedTaskIdx
        );
      }

      resetClickedTask();
    }
  }, [clickedTaskPriority, clickedTaskStatus]);

  useEffect(() => {
    if (
      selectedMyTasks?.length === tasksToDisplay?.length &&
      selectedMyTasks?.length > 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedMyTasks]);

  useEffect(() => {
    setTasksToDisplay(() => {
      let activeSprints = [];

      if (sprintFilter) {
        activeSprints = projects.map((project) => project.sprints.activeSprint);
      }

      const tasks = myTasks.filter((task) => {
        return (
          task.status !== "done" &&
          (task.title.toLowerCase().includes(taskNameFilter.toLowerCase()) ||
            task?.task_uid
              ?.toLowerCase()
              .includes(taskNameFilter.toLowerCase()) ||
            Object.entries(task?.labels || {}).some(
              ([key, value]) =>
                key.toLowerCase().includes(taskNameFilter.toLowerCase()) ||
                value.toLowerCase().includes(taskNameFilter.toLowerCase())
            )) &&
          (projectFilter === "" || task.projectId === projectFilter) &&
          (sprintFilter === false || activeSprints.includes(task.sprintId)) &&
          (statusFilter === "" || task.status === statusFilter) &&
          (priorityFilter === "" || task.priority === priorityFilter)
        );
      });

      return tasks;
    });
  }, [
    myTasks,
    taskNameFilter,
    projectFilter,
    sprintFilter,
    statusFilter,
    priorityFilter,
  ]);

  useEffect(() => {
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpacingFromTop(headerContainer.clientHeight);
  }, []);

  const isCompactView = screenWidth < 1145;

  const FilterPopover = () => (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button leftIcon={<FilterIcon size={"1em"} />} size="md" minW={"90px"}>
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <VStack spacing={2}>
            {screenWidth < 550 && (
              <Box width={"100%"}>
                <Flex
                  as="label"
                  alignItems="center"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  px={3}
                  py={2}
                  cursor="pointer"
                >
                  <Checkbox
                    colorScheme="blue"
                    isChecked={sprintFilter}
                    onChange={(e) => setSprintFilter(e.target.checked)}
                    mr={3}
                  />
                  <Text fontSize="sm" fontWeight={500}>
                    Active Sprint Only
                  </Text>
                </Flex>
              </Box>
            )}
            <Select
              placeholder="Project"
              value={projectFilter}
              onChange={(e) => setProjectFilter(e.target.value)}
            >
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.projectName}
                </option>
              ))}
            </Select>
            <Select
              placeholder="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="toDo">To Do</option>
              <option value="inProgress">In Progress</option>
              <option value="blocked">Blocked</option>
              <option value="review">Review</option>
            </Select>
            <Select
              placeholder="Priority"
              value={priorityFilter}
              onChange={(e) => setPriorityFilter(e.target.value)}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </Select>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    <Box className="my-tasks-component" ref={taskRef} style={{ all: "unset" }}>
      {isViewModalOpen && (
        <ViewTaskModal
          isOpen={isViewModalOpen}
          onClose={closeViewTaskModal}
          task={viewModalTask}
          setTask={setViewModalTask}
        />
      )}
      {isOpen && <CreateMyTaskModal isOpen={isOpen} onClose={onClose} />}

      <Flex justify="space-between" align="center" mb={2} mx={2} mt={5}>
        <Text fontSize="2xl" fontWeight="bold" color={textColor}>
          Task Tracker
        </Text>

        {selectedMyTasks && selectedMyTasks.length > 0 ? (
          <DeleteTaskButton
            selectedMyTasks={selectedMyTasks}
            setSelectedMyTasks={setSelectedMyTasks}
          />
        ) : (
          <>
            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              size={"sm"}
              onClick={onOpen}
            >
              Create Task
            </Button>
          </>
        )}
      </Flex>

      <Flex direction="column" mb={4} mx={2} gap={2}>
        <Flex
          gap={2}
          justify={screenWidth >= 1245 ? "center" : "space-between"}
        >
          {screenWidth >= 550 && (
            <Box width={"200px"}>
              <Flex
                as="label"
                alignItems="center"
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                px={3}
                py={2}
                cursor="pointer"
              >
                <Checkbox
                  colorScheme="blue"
                  isChecked={sprintFilter}
                  onChange={(e) => setSprintFilter(e.target.checked)}
                  mr={3}
                />
                <Text fontSize="sm" fontWeight={500} whiteSpace={"nowrap"}>
                  Active Sprint Only
                </Text>
              </Flex>
            </Box>
          )}
          <InputGroup width={isCompactView ? "100%" : "280px"}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search task"
              value={taskNameFilter}
              onChange={(e) => setTaskNameFilter(e.target.value)}
            />
          </InputGroup>
          {isCompactView ? (
            <FilterPopover />
          ) : (
            <>
              <Select
                placeholder="Project"
                value={projectFilter}
                onChange={(e) => setProjectFilter(e.target.value)}
                width="200px"
              >
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.projectName}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Status"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                width="200px"
              >
                <option value="toDo">To Do</option>
                <option value="inProgress">In Progress</option>
                <option value="blocked">Blocked</option>
                <option value="review">Review</option>
              </Select>
              <Select
                placeholder="Priority"
                value={priorityFilter}
                onChange={(e) => setPriorityFilter(e.target.value)}
                width="200px"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </Select>
            </>
          )}
        </Flex>
      </Flex>

      {tasksToDisplay &&
      Array.isArray(tasksToDisplay) &&
      tasksToDisplay?.length > 0 ? (
        <TableContainer
          maxHeight={`calc(100vh - ${spacingFromTop + 90}px)`}
          overflowY={"auto"}
          overflowX="auto"
          mr={2}
        >
          <Table variant="simple">
            <Thead
              position={"sticky"}
              top={0}
              bgColor={headerBgColor}
              zIndex={"1"}
            >
              <Tr>
                <Th width={"50px"}>
                  <Checkbox
                    isChecked={allSelected ? true : false}
                    onChange={selectUnselectAll}
                    colorScheme="purple"
                  />
                </Th>
                <Th
                  textAlign={"center"}
                  width={"80px"}
                  maxWidth={"80px"}
                  overflowX={"scroll"}
                  pl={0}
                >
                  Task #
                </Th>
                <Th textAlign={"left"} minWidth={"250px"} maxWidth={"100%"}>
                  Title
                </Th>
                <Th
                  textAlign={"center"}
                  width={"150px"}
                  maxW={"150px"}
                  overflowX={"scroll"}
                >
                  Project
                </Th>
                <Th
                  textAlign={"center"}
                  width={"120px"}
                  maxW={"120px"}
                  overflowX={"scroll"}
                >
                  Duration
                </Th>
                <Th
                  textAlign={"center"}
                  width={"160px"}
                  maxW={"160px"}
                  overflowX={"scroll"}
                >
                  Reviewer
                </Th>
                <Th
                  textAlign={"center"}
                  width={"180px"}
                  maxW={"180px"}
                  overflowX={"scroll"}
                >
                  Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {tasksToDisplay &&
                tasksToDisplay?.map((task) => {
                  return (
                    <Tr
                      _hover={{ bg: hoverBgColor, cursor: "pointer" }}
                      key={task?.id}
                      id={`myTask-${task?.id}`}
                      bg={
                        isSelected(task, selectedMyTasks)
                          ? selectedBgColor
                          : "transparent"
                      }
                    >
                      <Td>
                        <Checkbox
                          isChecked={
                            isSelected(task, selectedMyTasks) ? true : false
                          }
                          colorScheme="purple"
                          onChange={(e) => {
                            e.stopPropagation();
                            if (isSelected(task, selectedMyTasks)) {
                              const newSelectedTasks = [];

                              for (const selectedMyTask of selectedMyTasks) {
                                if (task.id !== selectedMyTask.id) {
                                  newSelectedTasks.push(selectedMyTask);
                                }
                              }

                              if (newSelectedTasks.length === 0) {
                                setSelectedMyTasks([]);
                              } else {
                                setSelectedMyTasks(newSelectedTasks);
                              }
                            } else {
                              setSelectedMyTasks((val) =>
                                val ? [...val, task] : [task]
                              );
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Td>
                      <Td
                        textAlign={"center"}
                        width={"80px"}
                        maxWidth={"80px"}
                        onClick={(e) => {
                          setIsViewModalOpen((val) => !val);
                          setViewModalTask(task);
                          return;
                        }}
                        overflowX={"scroll"}
                        pl={0}
                      >
                        {task?.task_uid || task?.id?.slice(-8)}
                      </Td>
                      <Td
                        textAlign={"left"}
                        minWidth={"250px"}
                        maxWidth={"100%"}
                        onClick={(e) => {
                          setIsViewModalOpen((val) => !val);
                          setViewModalTask(task);
                          return;
                        }}
                        overflowX={"scroll"}
                        whiteSpace="nowrap"
                      >
                        {task?.title ? task.title.substring(0, 100) : ""}
                      </Td>
                      <Td
                        textAlign={"center"}
                        onClick={(e) => {
                          setIsViewModalOpen((val) => !val);
                          setViewModalTask(task);
                          return;
                        }}
                        width={"150px"}
                        maxW={"150px"}
                        overflowX={"scroll"}
                      >
                        {
                          projects?.find(
                            (project) => project?.id === task?.projectId
                          )?.projectName
                        }
                      </Td>

                      <Td
                        textAlign={"center"}
                        onClick={(e) => {
                          setIsViewModalOpen((val) => !val);
                          setViewModalTask(task);
                          return;
                        }}
                        width={"120px"}
                        maxW={"120px"}
                        overflowX={"scroll"}
                      >
                        {task?.duration ? task.duration : ""}
                      </Td>
                      <Td
                        textAlign={"center"}
                        onClick={(e) => {
                          setIsViewModalOpen((val) => !val);
                          setViewModalTask(task);
                          return;
                        }}
                        width={"120px"}
                        overflowX={"scroll"}
                        maxWidth={"160px"}
                        alignItems={"center"}
                      >
                        {task?.reviewerId && (
                          <Box display={"flex"} gap={2} alignItems={"center"}>
                            <Avatar
                              size={"xs"}
                              name={findName(
                                task?.reviewerId,
                                organizationMembers,
                                user
                              )}
                              src={
                                organizationMembers?.find(
                                  (member) =>
                                    member?.firebaseUserId === task?.reviewerId
                                )?.imageUrl || ""
                              }
                            />
                            {findFirstName(
                              task?.reviewerId,
                              organizationMembers,
                              user
                            )}
                          </Box>
                        )}
                      </Td>
                      <Td
                        textAlign={"center"}
                        display={"flex"}
                        gap={2}
                        onClick={(e) => handleDropDownsSectionClick(e, task)}
                        justifyContent={"center"}
                      >
                        <Box
                          display="flex"
                          justifyContent="right"
                          maxW={"180px"}
                          width="180px"
                          padding={0}
                          margin={0}
                          gap={2}
                          className={`myTask-${task.id}-dropdowns`}
                          overflowX={"scroll"}
                        >
                          <StatusDropDown
                            status={clickedTaskStatus}
                            setStatus={setClickedTaskStatus}
                            isParent={task?.subTasks?.length > 0}
                          >
                            <Button
                              size={"sm"}
                              display={"block"}
                              minWidth={"117px !important"}
                              bgColor={taskStatusStyleLight[task?.status]}
                              color={"gray.800 !important"}
                            >
                              {task?.status ? taskStatusText[task?.status] : ""}
                            </Button>
                          </StatusDropDown>
                          <PriorityDropDown
                            priority={clickedTaskPriority}
                            setPriority={setClickedTaskPriority}
                          >
                            <Button style={{ all: "unset" }}>
                              {task?.priority && task?.status !== "done"
                                ? taskPriorityStyles[task.priority]
                                : ""}
                            </Button>
                          </PriorityDropDown>
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          paddingY={"50px"}
        >
          {taskNameFilter ||
          sprintFilter ||
          statusFilter ||
          priorityFilter ||
          projectFilter ? (
            <Text>No match found.</Text>
          ) : (
            <Text>No task is currently assigned to you.</Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MyTasks;
