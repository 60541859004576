import React, { useEffect } from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import Header from "../DashboardComponents/Header";
import SideBar from "../DashboardComponents/SideBar";
import { Outlet } from "react-router-dom";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";

const Layout = () => {
  const { organizations, selectedOrganization } = UserState();
  const { isPhone, isSidebarOpen, openSidebar, closeSidebar } = LayoutState();

  useEffect(() => {
    !isPhone ? openSidebar() : closeSidebar();
  }, [isPhone]);

  useEffect(() => {
    if (!(organizations && organizations?.length > 0)) {
      closeSidebar();
    }
  }, [organizations]);

  useEffect(() => {
    if (!selectedOrganization?.isVerified) {
      closeSidebar();
    }
  }, [selectedOrganization]);

  return (
    <Box height="100vh" width="100vw" id="layout-container-0">
      <Header />

      <SideBar />

      <Grid templateColumns={`1fr`} height="calc(100vh - 60px)">
        {/* Main Content Area */}
        {!(isSidebarOpen && isPhone) && (
          <GridItem as="main" overflow={"auto"} id="main-content-container-0">
            <Outlet context={{ isSidebarOpen: isSidebarOpen }} />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};

export default Layout;
