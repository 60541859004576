import { Box, Text, Divider, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { ImStatsBars } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";

const Dashboard = ({ setClicked }) => {
  const navigate = useNavigate();
  const { selectedOrganization } = UserState();
  const { isSidebarOpen } = LayoutState();

  const handleDashboardIconClick = (e) => {
    navigate("/dashboard");
    setClicked(true);
  };

  return (
    <>
      {isSidebarOpen ? (
        <Box
          display={"flex"}
          justifyContent={"left"}
          alignItems={"center"}
          fontSize={"16px"}
          border={"transparent"}
          borderBottom={"1px solid"}
          borderColor={"gray.300"}
          paddingY={2}
          _hover={{ bg: "gray.50", cursor: "pointer" }}
          _expanded={{ bg: "gray.50", fontWeight: "semibold" }}
          borderRadius="md"
          onClick={() => {
            if (selectedOrganization?.isVerified) {
              handleDashboardIconClick();
            }
          }}
          transition="all 0.2s"
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            paddingRight={5}
            gap={2}
            paddingLeft={4}
          >
            <ImStatsBars />
            <Text textAlign={"center"}>Dashboard</Text>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"md"}
          bgColor={"gray.50"}
          boxShadow={"md"}
          p={1}
          cursor={"pointer"}
          onClick={() => {
            if (selectedOrganization?.isVerified) {
              handleDashboardIconClick();
            }
          }}
        >
          <ImStatsBars size={"1.5rem"} />
        </Box>
      )}
    </>
  );
};

export default Dashboard;
