import { useEffect, useState } from "react";
import axios from "axios";
import { PROJ_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useProjects = (selectedOrganization) => {
  const { accessToken } = AuthState();
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);

  const reload = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  const getProjectReport = async ({ projectId }) => {
    if (!(projectId && selectedOrganization?.id)) {
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/project/${projectId}/report`,
        config
      );

      if (response?.data?.status === "success") {
        setProjects((prevProjects) => {
          return prevProjects.map((project) => {
            if (project.id === projectId) {
              return response?.data?.data;
            } else {
              return project;
            }
          });
        });
      }
    } catch (error) {
      console.log("Error while fetching project report", error);
    }
  };

  const createProject = async (projectData) => {
    try {
      if (!selectedOrganization?.isVerified) {
        throw new Error("Organization is not verified.");
      }
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/create_project`,
        projectData,
        config
      );

      if (response?.data?.status === "success") {
        const newProject = response?.data?.data;
        if (newProject && newProject.id) {
          setProjects((prevProjects) => [...(prevProjects || []), newProject]);
          return newProject;
        } else {
          throw new Error("Couldn't create project.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteProject = async (projectId) => {
    if (!projectId) {
      console.log(`Project ID not found when deleting project.`);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.delete(
        `${baseUrl}/project/${projectId}`,
        config
      );

      if (response?.data?.status === "success") {
        setProjects((prevProjects) => {
          return prevProjects.filter((project) => project.id !== projectId);
        });
        return response?.data;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateProject = async (updatingFields, projectId) => {
    if (!projectId) {
      console.log(`Project ID not found when updating project.`);
      return;
    }

    if (
      !updatingFields?.projectName &&
      !updatingFields?.description &&
      !updatingFields?.proAcronym
    ) {
      console.log(
        `Either project name or description or project acronym should be provided to update.`
      );
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/project/${projectId}`,
        updatingFields,
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;
        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });
          return updatedProject;
        } else {
          throw new Error("Couldn't update project.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateProjectManagement = async (management, projectId) => {
    if (!projectId) {
      console.log(`Project ID not found when updating project.`);
      return;
    }

    if (
      !management ||
      (management.toLowerCase() !== "agile" &&
        management.toLowerCase() !== "kanban")
    ) {
      console.log(`Management type must be either kanban or agile.`);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/project/${projectId}/management`,
        { management },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;
        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });
          return updatedProject;
        } else {
          throw new Error("Couldn't update project.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const inviteMembersToProject = async (members = [], projectId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      if (
        Array.isArray(members) &&
        members.length > 0 &&
        typeof members[0] !== "string"
      ) {
        if (members[0]?.firebaseUserId) {
          members = members.map((member) => member.firebaseUserId);
        } else {
          throw new Error("members is not an array of firebaseUserIds");
        }
      }

      const response = await axios.post(
        `${baseUrl}/projects/${projectId}/members`,
        { members },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return updatedProject;
        } else {
          throw new Error("Couldn't add members to project");
        }
      }
    } catch (err) {
      console.error("Error while adding members to project", err);
    }
  };

  const removeMembersFromProject = async (members = [], projectId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      if (
        Array.isArray(members) &&
        members.length > 0 &&
        typeof members[0] !== "string"
      ) {
        if (members[0]?.firebaseUserId) {
          members = members.map((member) => member.firebaseUserId);
        } else {
          throw new Error("members is not an array of firebaseUserIds");
        }
      }

      const response = await axios.post(
        `${baseUrl}/projects/${projectId}/members/delete`,
        { members },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return updatedProject;
        } else if (
          response?.data?.message ===
          "Cannot remove all members from project. Delete the project instead."
        ) {
          throw new Error({
            customMessage: "Cannot remove all members from project",
          });
        } else {
          throw new Error({ customMessage: "Couldn't add members to project" });
        }
      }
    } catch (err) {
      console.error("Error while removing members to project", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while removing members from project");
    }
  };

  const getAllProjectTasks = async (projectId, dateFilters) => {
    if (!projectId) {
      console.log(`Need ProjectId to fetch all sprints.`);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const startDate = dateFilters?.startDate
        ? new Date(dateFilters.startDate).setUTCHours(0, 0, 0, 0)
        : null;

      const endDate = dateFilters?.endDate
        ? new Date(dateFilters.endDate).setUTCHours(23, 59, 59, 999)
        : null;

      const startDateISO = startDate ? new Date(startDate).toISOString() : null;
      const endDateISO = endDate ? new Date(endDate).toISOString() : null;

      const response = await axios.get(
        `${baseUrl}/projects/${projectId}/tasks?startDate=${startDateISO}&endDate=${endDateISO}`,
        config
      );

      if (response?.data?.status === "success") {
        return response?.data?.data;
      }
    } catch (error) {
      console.log(
        "Error while fetching all project tasks",
        error?.response?.data?.message ||
          error?.message ||
          error?.response?.message ||
          error
      );
    }
  };

  const getAllSprints = async (projectId, query) => {
    if (!projectId) {
      console.log(`Need ProjectId to fetch all sprints.`);
      return;
    }

    try {
      const config = {
        params: {
          query,
        },
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/projects/${projectId}/sprints/all`,
        config
      );

      if (response?.data?.status === "success") {
        return response?.data?.data;
      }
    } catch (error) {
      console.error(
        "Error while getting sprints",
        error?.response?.data?.message ||
          error?.message ||
          error?.response?.message ||
          error
      );
    }
  };

  const getSprints = async (projectId, sprintIds, shouldFetchTasks = false) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/projects/${projectId}/sprints`,
        { sprintIds, shouldFetchTasks },
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.data;
      }
    } catch (err) {
      console.error("Error while getting sprints", err);
    }

    return null;
  };

  const createNewSprint = async (
    projectId,
    name = null,
    goal = "",
    duration = 7,
    type = null
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/projects/${projectId}/sprints/create`,
        { name, type, goal, duration },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.project;
        const createdSprint = response?.data?.sprint;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return createdSprint;
        } else {
          throw new Error({ customMessage: "Couldn't create a new sprint." });
        }
      }
    } catch (err) {
      console.error("Error while creating a new sprint.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while creating a new sprint.");
    }
  };

  const updateSprint = async (
    projectId,
    sprintId,
    goal = null,
    name = null,
    duration = null
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}`,
        { name, duration, goal },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          return updateProject;
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startSprint = async (projectId, sprintId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}/activate`,
        {},
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return;
        }
      } else {
        throw new Error({ customMessage: "Couldn't start the sprint." });
      }
    } catch (err) {
      console.error("Error while starting a sprint.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while starting a sprint.");
    }
  };

  const stopSprint = async (projectId, sprintId, undoneTaskIds = []) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}/deactivate`,
        { undoneTaskIds },
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return;
        }
      } else {
        throw new Error({ customMessage: "Couldn't stop the sprint." });
      }
    } catch (err) {
      console.error("Error while stopping a sprint.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while stopping a sprint.");
    }
  };

  const deleteSprint = async (projectId, sprintId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.delete(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}`,
        config
      );

      if (response?.data?.status === "success") {
        const updatedProject = response?.data?.data;

        if (updatedProject && updatedProject.id) {
          setProjects((prevProjects) => {
            return prevProjects.map((project) => {
              if (project.id === updatedProject.id) {
                return updatedProject;
              } else {
                return project;
              }
            });
          });

          return;
        }
      } else {
        throw new Error({ customMessage: "Couldn't delete the sprint." });
      }
    } catch (err) {
      console.error("Error while deleting a sprint.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while deleting a sprint.");
    }
  };

  const updateTaskSprint = async (projectId, sprintId, taskIds = []) => {
    try {
      if (!taskIds) {
        return;
      }

      if (Array.isArray(taskIds) && taskIds.length === 0) {
        return;
      } else if (!Array.isArray(taskIds)) {
        taskIds = [taskIds];
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}/tasks`,
        { taskIds },
        config
      );

      if (response?.data?.status === "success") {
        console.log("task sprint updated successfully");
        console.log(response?.data?.data);
      } else {
        throw new Error({
          customMessage: "Couldn't update the sprint for tasks.",
        });
      }
      return { status: "success" };
    } catch (err) {
      console.error("Error while adding a task to a sprint.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while updating a sprint task.");
      return { status: "error" };
    }
  };

  useEffect(() => {
    if (!selectedOrganization) {
      setProjects([]);
      // if (selectedOrganization === undefined) {
      //   setError(
      //     "Cannot fetch selected organization projects data without selected organization data"
      //   );
      // }
      return;
    }

    const getProjectsData = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "x-org-id": selectedOrganization.id,
          },
        };

        const response = await axios.get(
          `${baseUrl}/fetch_projects?orgId=${selectedOrganization.id}`,
          config
        );

        const projectsData = response?.data?.data;

        if (projectsData && Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          throw new Error("Projects Data not found");
        }
      } catch (err) {
        // console.log(err);
        setError("Error while fetching selected organization projects");
      } finally {
        setLoading(false);
      }
    };

    getProjectsData();
  }, [selectedOrganization, reloadFlag]);

  return {
    projects,
    setProjects,
    getProjectReport,
    createProject,
    deleteProject,
    updateProject,
    updateProjectManagement,
    inviteMembersToProject,
    removeMembersFromProject,
    createNewSprint,
    updateSprint,
    getSprints,
    getAllSprints,
    startSprint,
    stopSprint,
    deleteSprint,
    updateTaskSprint,
    getAllProjectTasks,
    loading,
    error,
    setError,
    reload,
  };
};

export default useProjects;
