import React from "react";

import TaskExpanded from "../Components/Miscellaneous/MyTasks/TaskExpanded";
import { Box } from "@chakra-ui/react";
import { UserState } from "../Context/UserProvider";

function Task() {
  return (
    <Box w={"100vw"} h={"100vh"} overflowY={"auto"}>
      <TaskExpanded />
    </Box>
  );
}

export default Task;
