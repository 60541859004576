import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  Spinner,
  HStack,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import { UserState } from "../../Context/UserProvider";
import MarkdownRenderer from "./MarkdownRenderer";

const ChatbotInterface = ({
  queries,
  pmbotName,
  messages,
  setMessages,
  requiredFieldsRef,
  selectedProject,
  setSelectedProject,
  currentStep,
  setCurrentStep,
}) => {
  const { user } = UserState();
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState("");
  const streamingMessageRef = useRef("");

  const setRequiredFields = (fields) => {
    requiredFieldsRef.current = fields;
  };

  const { projects, organizationMembers } = UserState();

  useEffect(() => {
    if (selectedProject) {
      // update assignees
      setAssignees(() =>
        organizationMembers.filter((member) => {
          return (
            member?.projectIds?.includes(selectedProject.id) ||
            member?.projectId === selectedProject.id
          );
        })
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    if (currentStep === 0) {
      addBotMessage(
        `Hello${user.firstName ? `, ${user.firstName}` : ""}! Welcome to the ${
          pmbotName || "chatbot"
        }.`
      );
      setCurrentStep(1);
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 1) {
      if (projects.length === 1) {
        handleProjectSelect(projects[0].id);
      } else {
        addBotMessage(
          "Which project would you like to know more details about?"
        );
        addUserMessage("Select a project from the list below.");
      }
    }
  }, [currentStep, projects]);

  const addBotMessage = (content) => {
    setMessages((prev) => [...prev, { type: "bot", content }]);
  };

  const addUserMessage = (content) => {
    setMessages((prev) => [...prev, { type: "user", content }]);
  };

  const updateLastBotMessage = (content) => {
    setMessages((prev) => {
      const newMessages = [...prev];
      const lastBotMessageIndex = newMessages.findLastIndex(
        (msg) => msg.type === "bot"
      );
      if (lastBotMessageIndex !== -1) {
        newMessages[lastBotMessageIndex] = {
          ...newMessages[lastBotMessageIndex],
          content,
        };
      }
      return newMessages;
    });
  };

  const handleProjectSelect = (projectId) => {
    const proj = projects?.find((p) => p.id === projectId);
    setSelectedProject(proj);
    addUserMessage(
      `Selected project: ${
        projects.find((p) => p.id === projectId)?.projectName
      }`
    );
    addBotMessage(
      `Great! You've selected ${
        projects.find((p) => p.id === projectId)?.projectName
      }. What would you like to know about this project?`
    );
    setCurrentStep(2);
  };

  const handleQuerySelect = (query) => {
    setSelectedQuery(query);
    addUserMessage(`Selected query: ${query.value}`);
    if (query.requiredFields.includes("assigneeId")) {
      addBotMessage("Please select an assignee:");

      setCurrentStep(3);
    } else {
      handleQuerySubmit(query);
    }
  };

  const handleAssigneeSelect = (assigneeId) => {
    requiredFieldsRef.current = {
      ...(requiredFieldsRef?.current || {}),
      assigneeId,
    };

    const assignee = assignees.find((a) => a.firebaseUserId === assigneeId);
    const name = assignee?.firstName;
    const message = `Selected assignee: ${name}`;
    addUserMessage(message);

    handleQuerySubmit(selectedQuery);
  };

  const removeLastBotMessage = () => {
    setMessages((prev) => {
      const newMessages = [...prev];
      const lastBotMessageIndex = newMessages.findLastIndex(
        (msg) => msg.type === "bot"
      );
      if (lastBotMessageIndex !== -1) {
        newMessages.splice(lastBotMessageIndex, 1);
      }
      return newMessages;
    });
  };

  useEffect(() => {
    if (isStreaming) {
      updateLastBotMessage(streamingMessage);
    }
  }, [streamingMessage]);

  const handleQuerySubmit = async (query) => {
    addBotMessage(`Processing your request for ${query.value}...`);
    setIsStreaming(true);
    streamingMessageRef.current = "";

    try {
      const result = await query.func(
        {
          ...requiredFieldsRef?.current,
          projectId: selectedProject?.id,
        },
        setStreamingMessage,
        setIsStreaming
      );

      while (isStreaming);

      removeLastBotMessage();
      addBotMessage(result);
      setStreamingMessage("");

      setCurrentStep(4);
    } catch (error) {
      setIsStreaming(false);
      addBotMessage(
        "I'm sorry, there was an error processing your request. Please try again."
      );
      console.log(error);
    }
  };

  const handleFeedback = (helpful) => {
    addUserMessage(helpful ? "Yes, it was helpful" : "No, it wasn't helpful");
    if (helpful) {
      addBotMessage("Great! Is there anything else you'd like to know or do?");
      setCurrentStep(2);
    } else {
      addBotMessage(
        "I'm sorry the response wasn't helpful. Would you like to try again or select a different query or a different project?"
      );

      setCurrentStep(5);
    }
  };

  const handleUnhelpfulChoice = (choice) => {
    if (choice === 0) {
      addUserMessage("I'd like to try again");
      addBotMessage("Sure, let's try that again.");
      handleQuerySubmit(selectedQuery);
    } else if (choice === 1) {
      addUserMessage("I'd like to try a different query");
      addBotMessage("Sure, what would you like to know about this project?");
      setCurrentStep(2);
    } else {
      addUserMessage("I'd like to select a different project");
      setSelectedProject(null);
      setSelectedQuery(null);
      setRequiredFields({});
      setCurrentStep(1);
    }
  };

  const renderUserInput = () => {
    if (isStreaming) {
      return <Spinner />;
    }

    switch (currentStep) {
      case 1:
        return (
          <VStack spacing={2} align="flex-end" justify={"flex-end"}>
            {projects.map((project) => (
              <Button
                key={project.id}
                width={"100%"}
                maxW={"210px"}
                onClick={() => handleProjectSelect(project.id)}
                colorScheme="blue"
              >
                {project.projectName}
              </Button>
            ))}
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={2} align="flex-end" justify={"flex-end"}>
            {/* add a button to select a different project, if there are more than 1 projects. */}
            {projects.length > 1 && (
              <Button
                key={"sel-proj"}
                onClick={() => {
                  addUserMessage("I'd like to select a different project");
                  setSelectedProject(null);
                  setSelectedQuery(null);
                  setRequiredFields({});
                  setCurrentStep(1);
                }}
                width={"100%"}
                maxW={"210px"}
                colorScheme="blue"
              >
                {"Select a different project"}
              </Button>
            )}
            {queries.map((query) => (
              <Button
                key={query.id}
                onClick={() => handleQuerySelect(query)}
                width={"100%"}
                maxW={"210px"}
                colorScheme="blue"
              >
                {query.value}
              </Button>
            ))}
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={2} align="flex-end" justify={"flex-end"}>
            {assignees.map((assignee) => (
              <Button
                key={assignee.firebaseUserId}
                onClick={() => {
                  handleAssigneeSelect(assignee.firebaseUserId);
                }}
                width="100%"
                maxW={"210px"}
                colorScheme="blue"
                justifyContent="left"
                leftIcon={
                  <Avatar
                    name={assignee.firstName}
                    size="xs"
                    src={assignee?.imageUrl}
                  />
                }
                overflowX={"scroll"}
              >
                {assignee.firstName} {assignee.lastName}
              </Button>
            ))}
          </VStack>
        );
      case 4:
        return (
          <VStack align="flex-end" justify="flex-end">
            <Text>Was this response helpful?</Text>
            <HStack>
              <Button onClick={() => handleFeedback(true)}>Yes</Button>
              <Button onClick={() => handleFeedback(false)}>No</Button>
            </HStack>
          </VStack>
        );
      case 5:
        return (
          <VStack spacing={2} align="flex-end" justify="flex-end">
            <Button
              onClick={() => handleUnhelpfulChoice(0)}
              width={"100%"}
              maxW={"220px"}
              colorScheme="blue"
            >
              Try again
            </Button>
            <Button
              onClick={() => handleUnhelpfulChoice(1)}
              width={"100%"}
              maxW={"220px"}
              colorScheme="blue"
            >
              Try a different query
            </Button>
            <Button
              onClick={() => handleUnhelpfulChoice(2)}
              width={"100%"}
              maxW={"220px"}
              colorScheme="blue"
            >
              Select a different project
            </Button>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch" overflowY="auto" mb={4}>
        {messages.map((message, index) => (
          <Box
            key={index}
            alignSelf={message.type === "user" ? "flex-end" : "flex-start"}
            bg={message.type === "user" ? "blue.500" : "gray.100"}
            color={message.type === "user" ? "white" : "black"}
            p={2}
            pb={1}
            borderRadius="md"
          >
            <MarkdownRenderer content={message.content} />
          </Box>
        ))}
      </VStack>
      {renderUserInput()}
    </Box>
  );
};

export default ChatbotInterface;
