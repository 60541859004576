import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Loading from "../Components/Miscellaneous/Loading/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserState } from "../Context/UserProvider";

const Invite = () => {
  const { verifyOrganizationInvite, acceptOrganizationInvite } = UserState();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const getFullURL = () => {
    const fullURL = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    return fullURL;
  };

  const verifyInvite = async (url) => {
    try {
      const response = await verifyOrganizationInvite(url);

      const { data, action } = response;
      console.log("Data:", data);
      console.log("Action:", action);

      // Perform actions based on the result
      if (action === "accept") {
        // accept invite api call
        await acceptOrganizationInvite(data);
        navigate("/dashboard");
      } else if (action === "signin") {
        navigate("/auth", { state: { component: "signin", data } });
      } else if (action === "signup") {
        navigate("/auth", { state: { component: "signup", data } });
      }
    } catch (error) {
      console.log(error);
      navigate("/auth", { state: { component: "signup" } });
      toast({
        title: "Could not verify organization invite.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }
  };

  useEffect(() => {
    const url = getFullURL();
    // verify organization invite token
    // response will contain action to be taken (accpet - accept and navigate to /dashboard, signin - signin to accept, signup - signup to accept)
    const { data, action } = verifyInvite(url);
  }, []);

  return (
    <Box display={"flex"} height={"100vh"} width={"100vw"}>
      <Loading />
    </Box>
  );
};

export default Invite;
