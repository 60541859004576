// const BASE_URL = "http://192.168.1.11";
const isLocal = process.env.REACT_APP_IS_LOCAL === "true";

const PROJ_BASE_URL = isLocal
  ? "http://localhost:3003"
  : process.env.REACT_APP_PROJECT_SERVICE_URL;
const ORG_BASE_URL = isLocal
  ? "http://localhost:3004"
  : process.env.REACT_APP_ORGANIZATION_SERVICE_URL;
const TASK_BASE_URL = isLocal
  ? "http://localhost:3002"
  : process.env.REACT_APP_TASK_SERVICE_URL;
const USER_BASE_URL = isLocal
  ? "http://localhost:3001"
  : process.env.REACT_APP_USER_SERVICE_URL;
const GEMINI_BASE_URL = isLocal
  ? "http://localhost:3006"
  : process.env.REACT_APP_GEMINI_SERVICE_URL;
const NOTIFICATION_BASE_URL = isLocal
  ? "http://localhost:3009/api/v1"
  : process.env.REACT_APP_NOTIFICATION_SERVICE_URL;
const SLACK_BASE_URL = isLocal
  ? "http://localhost:3010"
  : process.env.REACT_APP_SLACK_SERVICE_URL;
const GCS_COMMENT_ATTACHMENTS_BASE_URL =
  process.env.REACT_APP_GCS_ATTACHMENTS_SERVICE_URL;

export {
  PROJ_BASE_URL,
  ORG_BASE_URL,
  TASK_BASE_URL,
  USER_BASE_URL,
  GEMINI_BASE_URL,
  NOTIFICATION_BASE_URL,
  SLACK_BASE_URL,
  GCS_COMMENT_ATTACHMENTS_BASE_URL,
};
