import React, { useEffect, useState } from "react";
import PhoneSideBar from "../Miscellaneous/Sidebars/PhoneSideBar";
import PermanentSideBar from "../Miscellaneous/Sidebars/PermanentSideBar";
import { LayoutState } from "../../Context/LayoutProvider";
import { Box } from "@chakra-ui/react";
import { ChevronsUp } from "lucide-react";

const SideBar = () => {
  const { isPhone, sidebarWidth, isSidebarOpen, openSidebar, closeSidebar } =
    LayoutState();

  return (
    <Box
      id="sidebar-container-0"
      position="fixed"
      top={0}
      width={`${sidebarWidth}px`}
      height={"100vh"}
      overflow={"visible"}
      zIndex={20}
      transition="all 0.3s ease-in-out"
    >
      {isPhone ? <PhoneSideBar /> : <PermanentSideBar />}
      {!(isSidebarOpen && isPhone) && (
        <Box
          position="absolute"
          top="58px"
          right={"-15px"}
          transform="translateY(-50%)"
          bg="gray.200"
          borderRadius="full"
          p={1}
          boxShadow="md"
          cursor="pointer"
          onClick={isSidebarOpen ? closeSidebar : openSidebar}
          zIndex={99}
          overflowX={"visible"}
        >
          <ChevronsUp
            style={{
              transform: `rotate(${!isSidebarOpen ? "90deg" : "270deg"})`,
            }}
            size={"1.4rem"}
          />
        </Box>
      )}
    </Box>
  );
};

export default SideBar;
