"use client";

import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Container,
  SimpleGrid,
  useColorModeValue,
  Image,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { LayoutState } from "../../Context/LayoutProvider";
import lumibrand from "../../assets/lumilogo.svg";
import luminame from "../../assets/lumiproject-name.png";
import lumilogo from "../../assets/lumiproject-logo.png";

const FeatureCard = ({ title, description }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        p={6}
        borderRadius="lg"
        boxShadow="lg"
        bg={bgColor}
        border="1px solid"
        borderColor={borderColor}
        height="100%"
      >
        <Heading as="h3" size="md" mb={4}>
          {title}
        </Heading>
        <Text>{description}</Text>
      </Box>
    </motion.div>
  );
};

const BrandingSection = ({ headerHeight, brandNameRef }) => {
  const navigate = useNavigate();

  const bgGradient = useColorModeValue(
    "linear(to-br, blue.50, purple.50)",
    "linear(to-br, blue.900, purple.900)"
  );
  const textColor = useColorModeValue("gray.800", "white");
  const buttonBgColor = useColorModeValue("white", "gray.800");
  const buttonHoverBgColor = useColorModeValue("gray.100", "gray.700");

  const features = [
    {
      title: "AI-Powered Insights",
      description:
        "Leverage cutting-edge AI to optimize your project management workflow and make data-driven decisions.",
    },
    {
      title: "Seamless Collaboration",
      description:
        "Foster teamwork with real-time updates, shared workspaces, and intuitive communication tools.",
    },
    {
      title: "Advanced Analytics",
      description:
        "Gain deep insights into project performance with comprehensive metrics and customizable dashboards.",
    },
    {
      title: "Automated Workflows",
      description:
        "Streamline repetitive tasks and processes to boost productivity and reduce human error.",
    },
  ];

  return (
    <Box
      minHeight={`calc(100vh - ${headerHeight}px)`}
      py={16}
      bgGradient={bgGradient}
      color={textColor}
    >
      <Container maxW="container.xl">
        <VStack spacing={12} align="center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* <Heading
              ref={brandNameRef}
              fontSize={{ base: "5xl", md: "6xl", lg: "7xl" }}
              fontWeight={800}
              textAlign="center"
              bgGradient="linear(to-r, blue.400, purple.500)"
              bgClip="text"
              id="landing-brand-name"
            >
              lumiproject
              <Text
                as="sub"
                fontSize="0.4em"
                color={textColor}
                ml={1}
                fontWeight="normal"
              >
                (beta)
              </Text>
            </Heading> */}
            <Flex>
              <Image src={lumilogo} />
              <Image src={luminame} />
            </Flex>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Text
              fontSize={{ base: "xl", md: "2xl" }}
              textAlign="center"
              maxW="3xl"
              fontWeight={500}
            >
              Revolutionize Your Project Management with AI-Powered Efficiency
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              gap={4}
            >
              <Button
                size="lg"
                onClick={() =>
                  navigate("/auth", { state: { component: "signup" } })
                }
                bg={buttonBgColor}
                color={textColor}
                _hover={{
                  bg: buttonHoverBgColor,
                  transform: "translateY(-2px)",
                }}
                boxShadow="md"
                transition="all 0.3s"
              >
                Try it Now!
              </Button>
              <Button
                size="lg"
                onClick={() =>
                  navigate("/auth", { state: { component: "signup" } })
                }
                variant="outline"
                _hover={{
                  bg: buttonHoverBgColor,
                  transform: "translateY(-2px)",
                }}
                transition="all 0.3s"
              >
                See How it Works
              </Button>
            </Box>
          </motion.div>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full" pt={16}>
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default BrandingSection;
