import { createContext, useContext } from "react";
import useNotifications from "../hooks/useNotifications";
import useAssistant from "../hooks/useAssistant";

const ServiceContext = createContext();

const ServiceProvider = ({ children }) => {
  const {
    notifications,
    isLoading,
    hasMore,
    unreadCount,
    handleLoadMore,
    sendNotification,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    setFilter,
    loadLatestNotifications,
  } = useNotifications();

  const {
    meetingPoints,
    generateProjectReport,
    userProgress,
    bestContributor,
    summarizeCurrentSprint,
    projectHealth,
    teamBandwidth,
    riskBlockers,
    teamBurnrate,
    getAssigneeRecommendations,
  } = useAssistant();

  return (
    <ServiceContext.Provider
      value={{
        // Notifications
        notifications,
        isLoading,
        hasMore,
        unreadCount,
        handleLoadMore,
        sendNotification,
        markAllNotificationsAsRead,
        markNotificationAsRead,
        setFilter,
        loadLatestNotifications,

        // Assistant
        meetingPoints,
        generateProjectReport,
        userProgress,
        bestContributor,
        summarizeCurrentSprint,
        projectHealth,
        teamBandwidth,
        riskBlockers,
        teamBurnrate,
        getAssigneeRecommendations,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const ServiceState = () => {
  return useContext(ServiceContext);
};

export default ServiceProvider;
