import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  VStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { FilterIcon } from "lucide-react";

const FilterPopover = ({ filters, setFilters }) => {
  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button leftIcon={<FilterIcon size="1em" />} size="md">
          More Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <VStack spacing={2} align="stretch">
            <Text fontWeight="bold">Due Date</Text>
            <Input
              type="date"
              value={filters.dueDate?.start || ""}
              onChange={(e) =>
                handleFilterChange("dueDate", {
                  ...filters.dueDate,
                  start: e.target.value,
                })
              }
              placeholder="Start Date"
            />
            <Input
              type="date"
              value={filters.dueDate?.end || ""}
              onChange={(e) =>
                handleFilterChange("dueDate", {
                  ...filters.dueDate,
                  end: e.target.value,
                })
              }
              placeholder="End Date"
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FilterPopover;
