import { Box, Text, Divider, useToast, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { ImStatsBars } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { UserState } from "../../../Context/UserProvider";
import { BotMessageSquare } from "lucide-react";
import { LayoutState } from "../../../Context/LayoutProvider";

const Assistant = ({ setClicked }) => {
  const { selectedOrganization } = UserState();
  const { isSidebarOpen } = LayoutState();
  const navigate = useNavigate();
  const toast = useToast();

  const handleAssistantIconClick = (e) => {
    console.log("Selected Organization:", selectedOrganization); // Debugging

    if (
      selectedOrganization?.isAiEnabled ||
      selectedOrganization?.trialActive
    ) {
      navigate(`/assistant/${selectedOrganization.id}`);
      if (setClicked) {
        setClicked(true); // Call if defined
      } 
    } else {
      toast({
        description: "Assistant hasn't been activated for this organization.",
        isClosable: true,
        status: "warning",
        duration: 2000,
      });
    }
  };

  return (
    <>
      {isSidebarOpen ? (
        <Box
          display={"flex"}
          justifyContent={"left"}
          alignItems={"center"}
          fontSize={"16px"}
          border={"transparent"}
          borderBottom={"1px solid"}
          borderColor={"gray.300"}
          paddingY={2}
          _hover={{ bg: "gray.50", cursor: "pointer" }}
          _expanded={{ bg: "gray.50", fontWeight: "semibold" }}
          onClick={() => {
            if (selectedOrganization?.isVerified) {
              handleAssistantIconClick();
            }
          }}
          borderRadius="md"
          transition="all 0.2s"
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            paddingRight={5}
            gap={2}
            paddingLeft={4}
          >
            <BotMessageSquare size={"1.3rem"} />
            <Text textAlign={"center"}>
              {selectedOrganization?.pmbotName || "Assistant"}
            </Text>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"md"}
          bgColor={"gray.50"}
          boxShadow={"md"}
          p={1}
          cursor={"pointer"}
          onClick={() => {
            if (selectedOrganization?.isVerified) {
              handleAssistantIconClick();
            }
          }}
        >
          <Tooltip label={"Assistant"} aria-label={"Assistant"}>
            <BotMessageSquare size={"1.5rem"} />
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default Assistant;
