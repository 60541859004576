import axios from "axios"; // Ensure axios is imported
import { GEMINI_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";
import { UserState } from "../Context/UserProvider";
import { useEffect, useState } from "react";

const useAssistant = () => {
  const { accessToken } = AuthState();
  const { selectedOrganization } = UserState();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

  useEffect(() => {
    if (selectedOrganization) {
      setSelectedOrganizationId(selectedOrganization.id);
    }
  }, [selectedOrganization]);

  const fetchApi = async (url, method, body) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "x-org-id": selectedOrganizationId,
      };

      const response = await fetch(url, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorBody}`
        );
      }

      return response; // Return the response for further processing
    } catch (err) {
      console.log("Error in fetchApi:", err);
      throw err;
    }
  };

  const streamResponse = async (response, setResponseStateVariable) => {
    try {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const extractedText = extractText(chunk);

        // console.log("Extracted chunk:", extractedText);

        setResponseStateVariable((prev) => prev + extractedText);

        fullResponse += extractedText;

        // Optional: Real-time logic can be added here
        // console.log("Received chunk:", chunk);
      }

      return fullResponse;
    } catch (err) {
      console.log("Error in streamResponse:", err);
      throw err;
    }
  };

  const extractText = (chunk) => {
    const textMatch = chunk.match(/parts\s*{\s*text:\s*"([^"]*)"/);
    return textMatch ? textMatch[1] : "";
  };

  const meetingPoints = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/meeting_points`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in meetingPoints:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const generateProjectReport = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/summary_project`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in userProgress:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const userProgress = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/user_summary`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in userProgress:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const bestContributor = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/who_contributed_most`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in bestContributor:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const summarizeCurrentSprint = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/summary_active_sprint`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in summarizeCurrentSprint:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const projectHealth = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/project_health`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in projectHealth:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const teamBandwidth = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/team_bandwidth`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in teamBandwidth:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const riskBlockers = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/risk_blockers`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in riskBlockers:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const teamBurnrate = async (
    fields,
    setResponseStateVariable,
    setIsStreaming
  ) => {
    const url = `${baseUrl}/team_burnrate`;

    try {
      const response = await fetchApi(url, "POST", fields);
      const fullResponse = await streamResponse(
        response,
        setResponseStateVariable
      );
      return fullResponse;
    } catch (err) {
      console.log("Error in teamBurnrate:", err);
      throw err;
    } finally {
      setIsStreaming(false);
    }
  };

  const getAssigneeRecommendations = async (projectId, title, description) => {
    const url = `${baseUrl}/recommend_assignee`;

    if (!projectId || !title || !description) {
      throw new Error("projectId, title, and description are required");
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganizationId,
        },
      };
      const response = await axios.post(
        url,
        {
          projectId,
          title,
          description,
        },
        config
      );

      console.log(response.data);

      return response?.data?.recommendations;
    } catch (err) {
      console.log("Error in getAssigneeRecommendations:", err);
      throw err;
    }
  };

  return {
    meetingPoints,
    generateProjectReport,
    userProgress,
    bestContributor,
    summarizeCurrentSprint,
    projectHealth,
    teamBandwidth,
    riskBlockers,
    teamBurnrate,
    getAssigneeRecommendations,
  };
};

export default useAssistant;
