import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Divider,
  Badge,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import StatusDropDown from "../Dropdowns/StatusDropDown";
import PriorityDropDown, { PriorityIcon } from "../Dropdowns/PriorityDropDown";
import MilestoneDropDown from "../Dropdowns/MilestoneDropDown";
import ReporterDropDown from "../Dropdowns/ReporterDropDown";
import AssigneeDropDown from "../Dropdowns/AssigneeDropDown";
import AddComment from "../Comments/AddComment";
import AllTaskComments from "../Comments/AllTaskComments";
import { priorityStyleLight, taskStatusStyleLight } from "./styles";
import {
  ChevronLeftIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { Plus, Trash2 } from "lucide-react";
import isEqual from "lodash/isEqual";

const TaskExpanded = () => {
  const {
    fetchTaskDetail,
    setMyTasks,
    setReportTasks,
    updateTaskPriority,
    updateTaskStatus,
    updateTask,
  } = UserState();
  const { user } = AuthState();
  const { screenWidth, sidebarWidth } = LayoutState();
  const { taskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [task, setTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("toDo");
  const [priority, setPriority] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [duration, setDuration] = useState(0);
  const [milestone, setMilestone] = useState({ id: "", title: "" });
  const [assigneeId, setAssigneeId] = useState("");
  const [reviewerId, setReviewerId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [parentTaskId, setParentTaskId] = useState("");
  const [parentTaskTitle, setParentTaskTitle] = useState("");
  const [labels, setLabels] = useState([]);

  const taskStatusText = {
    done: "Done",
    inProgress: "In Progress",
    blocked: "Blocked",
    toDo: "To Do",
    review: "Review",
  };

  const priorityText = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  useEffect(() => {
    fetchTask();
  }, [taskId]);

  const fetchTask = async () => {
    setIsLoading(true);
    try {
      const response = await fetchTaskDetail(taskId);
      const [taskData] = Array.isArray(response?.data) ? response.data : [];
      if (taskData) {
        setTask(taskData);
        setEditedTask(taskData);
        setTitle(taskData.title);
        setDescription(taskData.description);
        setStatus(taskData.status);
        setPriority(taskData.priority);
        setDueDate(taskData.dueDate);
        setDuration(taskData.duration);
        setMilestone(taskData.milestone);
        setAssigneeId(taskData.assigneeId || "");
        setReviewerId(taskData.reviewerId || "");
        setProjectId(taskData.projectId || "");
        setParentTaskId(taskData.parentId || "");
        setLabels(
          taskData.labels
            ? Object.entries(taskData.labels).map(([key, value]) => ({
                key,
                value,
              }))
            : []
        );
      }
    } catch (error) {
      console.error("Error fetching task:", error);
      toast({
        title: "Failed to fetch task.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (parentTaskId) {
      fetchTaskDetail(parentTaskId)
        .then((parentTask) => {
          setParentTaskTitle(parentTask.data[0].title);
        })
        .catch((error) => {
          console.error("Error fetching parent task details:", error);
        });
    }
  }, [parentTaskId]);

  const handleSave = async () => {
    const updatedTask = {
      ...editedTask,
      title,
      description,
      status,
      priority,
      dueDate,
      duration,
      milestone,
      assigneeId,
      reviewerId,
      labels: Object.fromEntries(labels.map(({ key, value }) => [key, value])),
    };

    try {
      await updateTask(task, updatedTask);
      setTask(updatedTask);
      setIsEditing(false);
      toast({
        title: "Task updated successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating task:", error);
      toast({
        title: "Failed to update task.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleAddLabel = () => {
    setLabels([...labels, { key: "", value: "" }]);
  };

  const handleRemoveLabel = (index) => {
    setLabels(labels.filter((_, i) => i !== index));
  };

  const handleLabelChange = (index, field, value) => {
    const updatedLabels = [...labels];
    updatedLabels[index][field] = value;
    setLabels(updatedLabels);
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box p={4} ml={sidebarWidth}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          aria-label="Go back"
        />
        {isEditing ? (
          <HStack>
            <Button
              leftIcon={<CheckIcon />}
              colorScheme="green"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              leftIcon={<CloseIcon />}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </HStack>
        ) : (
          <Button leftIcon={<EditIcon />} onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </Flex>

      <VStack spacing={6} align="stretch">
        <FormControl>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Task Title"
            size="lg"
            fontWeight="bold"
            isReadOnly={!isEditing}
          />
        </FormControl>

        {parentTaskId && (
          <HStack>
            <Text fontWeight="bold">Parent Task:</Text>
            <Text>{parentTaskTitle}</Text>
          </HStack>
        )}

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Task Description"
            size="md"
            minHeight="150px"
            isReadOnly={!isEditing}
          />
        </FormControl>

        <Divider />

        <Flex flexWrap="wrap" justifyContent="space-between">
          <FormControl flex="1" mr={2} mb={4}>
            <FormLabel>Status</FormLabel>
            <StatusDropDown
              status={status}
              setStatus={setStatus}
              isParent={task?.subTasks?.length > 0}
              isDisabled={!isEditing}
            >
              <Button
                w="full"
                bg={taskStatusStyleLight[status]}
                color="black"
                _hover={{ opacity: 0.8 }}
              >
                {taskStatusText[status]}
              </Button>
            </StatusDropDown>
          </FormControl>

          <FormControl flex="1" ml={2} mb={4}>
            <FormLabel>Priority</FormLabel>
            <PriorityDropDown
              priority={priority}
              setPriority={setPriority}
              isDisabled={!isEditing}
            >
              <Button
                w="full"
                bg={priorityStyleLight[priority]}
                color={priority ? "black" : "gray.800"}
                _hover={{ opacity: 0.8 }}
              >
                <Flex alignItems="center">
                  {priority && <PriorityIcon priority={priority} mr={2} />}
                  <Text>
                    {priority ? priorityText[priority] : "Select Priority"}
                  </Text>
                </Flex>
              </Button>
            </PriorityDropDown>
          </FormControl>
        </Flex>

        <Flex flexWrap="wrap" justifyContent="space-between">
          <FormControl flex="1" mr={2} mb={4}>
            <FormLabel>Duration (hours)</FormLabel>
            <Input
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              placeholder="Duration"
              type="number"
              min="0"
              step="0.1"
              isReadOnly={!isEditing}
            />
          </FormControl>

          <FormControl flex="1" ml={2} mb={4}>
            <FormLabel>Due Date</FormLabel>
            <Input
              value={dueDate || ""}
              onChange={(e) => setDueDate(e.target.value)}
              type="date"
              isReadOnly={!isEditing}
            />
          </FormControl>
        </Flex>

        <FormControl mb={4}>
          <FormLabel>Milestone</FormLabel>
          <MilestoneDropDown
            projectId={projectId}
            milestone={milestone}
            setMilestone={setMilestone}
            task={task}
            forUpdation={true}
            setTask={setTask}
            isTriggerBgWhite={true}
            isDisabled={!isEditing}
          />
        </FormControl>

        <Flex flexWrap="wrap" justifyContent="space-between">
          <FormControl flex="1" mr={2} mb={4}>
            <FormLabel>Assignee</FormLabel>
            <AssigneeDropDown
              assigneeId={assigneeId}
              setAssigneeId={setAssigneeId}
              projectId={projectId}
              reviewerId={reviewerId}
              forUpdation={true}
              isTriggerBgWhite={true}
              isDisabled={!isEditing}
            />
          </FormControl>

          <FormControl flex="1" ml={2} mb={4}>
            <FormLabel>Reviewer</FormLabel>
            <ReporterDropDown
              reviewerId={reviewerId}
              setReviewerId={setReviewerId}
              assigneeId={assigneeId}
              projectId={projectId}
              forUpdation={true}
              isTriggerBgWhite={true}
              isDisabled={!isEditing}
            />
          </FormControl>
        </Flex>

        <Box>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" mr={2}>
              Labels
            </Text>
            {isEditing && (
              <Tooltip label="Add Label">
                <IconButton
                  icon={<Plus size={16} />}
                  size="sm"
                  onClick={handleAddLabel}
                  aria-label="Add label"
                />
              </Tooltip>
            )}
          </Flex>
          {labels.map((label, index) => (
            <Flex key={index} mb={2} alignItems="center">
              <Input
                placeholder="Key"
                value={label.key}
                onChange={(e) =>
                  handleLabelChange(index, "key", e.target.value)
                }
                size="sm"
                mr={2}
                isReadOnly={!isEditing}
              />
              <Input
                placeholder="Value"
                value={label.value}
                onChange={(e) =>
                  handleLabelChange(index, "value", e.target.value)
                }
                size="sm"
                mr={2}
                isReadOnly={!isEditing}
              />
              {isEditing && (
                <IconButton
                  icon={<Trash2 size={16} />}
                  size="sm"
                  onClick={() => handleRemoveLabel(index)}
                  aria-label="Remove label"
                />
              )}
            </Flex>
          ))}
        </Box>

        <Divider />

        <Box>
          <Text fontWeight="bold" mb={2}>
            Comments
          </Text>
          <AllTaskComments task={task} setTask={setTask} />
          <AddComment setTask={setTask} />
        </Box>
      </VStack>
    </Box>
  );
};

export default TaskExpanded;
