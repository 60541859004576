"use client";

import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Image,
  VStack,
  Text,
  Container,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { LayoutState } from "../../Context/LayoutProvider";
import FeatureBox from "./FeatureBox";
import taskCreationImage from "../../assets/task_creation.png";
import dashboardPageImage from "../../assets/dashboard_page.png";

export default function Features({ headerHeight, featuresRef }) {
  const { screenWidth } = LayoutState();
  const [currentItem, setCurrentItem] = useState(0);

  const features = [
    {
      idx: 0,
      title: "Precision User Tracking",
      description:
        "Unlock advanced analytics with our intuitive platform. Dive deep into user insights and make data-driven decisions effortlessly. Track user behavior, engagement, and project progress in real-time.",
      icon: "📊",
    },
    {
      idx: 1,
      title: "Meet Lumiproject: Your AI Assistant",
      description:
        "Experience seamless project management with Lumiproject, your AI-powered companion that keeps everything on track. From task allocation to deadline reminders, Lumiproject is your 24/7 project management ally.",
      icon: "🤖",
    },
    {
      idx: 2,
      title: "Visual Project Progress",
      description:
        "See your project's success unfold with our intuitive visual overview. One click reveals actionable insights, beautiful charts, and progress indicators that make project tracking a breeze.",
      icon: "📈",
    },
    {
      idx: 3,
      title: "All-in-One Solution",
      description:
        "Simplify your workflow with the ultimate project management tool. Streamline tasks, enhance collaboration, and boost productivity all in one place. Say goodbye to juggling multiple tools and hello to efficiency.",
      icon: "🚀",
    },
  ];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % features.length);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [currentItem]);

  const bgGradient = useColorModeValue(
    "linear(to-r, blue.100, purple.100)",
    "linear(to-r, blue.900, purple.900)"
  );
  const textColor = useColorModeValue("gray.800", "gray.100");
  const headingColor = useColorModeValue(
    "linear(to-r, blue.600, purple.600)",
    "linear(to-r, blue.300, purple.300)"
  );

  return (
    <Box
      ref={featuresRef}
      minHeight={`calc(100vh - ${headerHeight}px)`}
      display="flex"
      flexDirection="column"
      id="features"
      py={20}
      bgGradient={bgGradient}
      color={textColor}
    >
      <Container maxW="container.xl">
        <VStack spacing={12} align="center" mb={16}>
          <Heading
            as={motion.h2}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
            fontWeight="bold"
            textAlign="center"
            bgGradient={headingColor}
            bgClip="text"
          >
            Revolutionize Your Project Management
          </Heading>
          <Text
            as={motion.p}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            maxW="3xl"
            textAlign="center"
            fontSize="xl"
            color={textColor}
          >
            Harness the power of AI and intuitive analytics to transform the way
            you manage projects. Lumiproject brings cutting-edge technology to
            your fingertips, making project management smarter, faster, and more
            efficient than ever before.
          </Text>
        </VStack>

        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={16}
          alignItems="center"
        >
          <VStack spacing={8} align="stretch">
            <AnimatePresence mode="wait">
              {features.map((feature) => (
                <motion.div
                  key={feature.idx}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{
                    opacity: currentItem === feature.idx ? 1 : 0.5,
                    x: 0,
                  }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  <FeatureBox
                    isActive={currentItem === feature.idx}
                    feature={feature}
                    setCurrentItem={setCurrentItem}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </VStack>

          {screenWidth >= 900 && (
            <Box position="relative" h="500px" w="full" maxW="600px">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentItem}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.5 }}
                >
                  <Image
                    src={
                      currentItem % 2 === 0
                        ? taskCreationImage
                        : dashboardPageImage
                    }
                    alt={features[currentItem].title}
                    objectFit="cover"
                    w="full"
                    h="full"
                    boxShadow="2xl"
                  />
                </motion.div>
              </AnimatePresence>
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="rgba(0, 0, 0, 0.1)"
                backdropFilter="blur(8px)"
                borderRadius="2xl"
                zIndex={-1}
              />
            </Box>
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
