import { Box, Flex } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";
import ChatbotInterface from "./ChatbotInterface";
import ChatbotHeader from "./ChatbotHeader";
import { ServiceState } from "../../Context/ServiceProvider";

const Main = ({ isOpen, onClose, chatIconCall = false }) => {
  const { selectedOrganization } = UserState();
  const { isPhone, sidebarWidth } = LayoutState();
  const [spacingFromTop, setSpacingFromTop] = useState(60);
  const [chatbotHeaderHeight, setChatbotHeaderHeight] = useState(65);
  const [pmbotName, setPmbotName] = useState("Assistant");
  const [messages, setMessages] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const requiredFieldsRef = useRef({});

  const {
    meetingPoints,
    generateProjectReport,
    userProgress,
    bestContributor,
    summarizeCurrentSprint,
    projectHealth,
    teamBandwidth,
    riskBlockers,
    teamBurnrate,
  } = ServiceState();
  /**
   * Step 1: Ask the user to select a project. If there is only 1 project, auto select it and prompt the user that this project has been selected.
   * Step 2: Text the user that so and so project has been selected.
   * Step 3: The user gets to choose a query from the available queries.
   * Step 4: The user clicks on a query.
   * Step 5: If any fields are required for the query, ask the user to enter the values.
   * Step 6: Make the query and get the response.
   * Step 7: Show the response to the user.
   * Step 8: Ask the user is the response was helpful in Yes and No buttons.
   * Step 9: If the response was not helpful, ask the user to select a different query.
   */

  // available queries to the user
  // {id: just an id for the query, value: the name of the query, func: the function to call, requiredFields: the fields that are required for the query, type: the type of the query, postResponse: operations that can be performed post response of the query}
  // PS: if there is only 1 project, auto select it and no "Different Project" postResponse option in that case.
  // PS: after every query response is displayed, we will be showing the postResponse options and in a seperate line below it, we will show other query options.
  const queries = [
    {
      id: 1,
      value: "Meeting Agenda",
      func: meetingPoints,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 2,
      value: "Project Report",
      func: generateProjectReport,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 3,
      value: "User's Progress",
      func: userProgress,
      requiredFields: ["projectId", "assigneeId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project", "Different Assignee"],
    },
    {
      id: 4,
      value: "Best Contributor",
      func: bestContributor,
      requiredFields: ["projectId"],
      type: "user",
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 5,
      value: "Sprint's Summary",
      func: summarizeCurrentSprint,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 6,
      value: "Project Health",
      func: projectHealth,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 7,
      value: "Team Bandwidth",
      func: teamBandwidth,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 8,
      value: "Risk & Blockers",
      func: riskBlockers,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
    {
      id: 9,
      value: "Team Burnrate",
      func: teamBurnrate,
      requiredFields: ["projectId"],
      type: "user",
      specialOptions: ["Copy", "Download as Pdf"],
      postResponse: ["Repeat", "Different Project"],
    },
  ];

  const refreshChat = () => {
    setSelectedProject(null);
    setCurrentStep(0);
    setMessages([]);
    requiredFieldsRef.current = {};
  };

  useEffect(() => {
    if (selectedOrganization) {
      setPmbotName(selectedOrganization.pmbotName);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpacingFromTop(headerContainer.clientHeight);
  }, []);

  return (
    <Box
      // Conditional styles based on `chatIconCall` prop
      overflowY={"auto"}
      flex={1}
      marginRight={0}
      marginLeft={chatIconCall ? 0 : sidebarWidth} // Remove sidebar offset in floating mode
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      display={"flex"}
      flexDir={"column"}
      gap={5}
      maxHeight={
        chatIconCall
          ? "100%" // Use full height in floating mode
          : `calc(100vh - ${spacingFromTop}px)`
      }
    >
      <Box flex={1}>
        {isPhone && isOpen && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="1"
            bg="transparent"
            bgColor={"#0000002b"}
            onClick={onClose}
          />
        )}

        <Flex
          direction="column"
          flex={1}
          overflow={chatIconCall ? "hidden" : "auto"} // Prevent content scrolling in floating UI
          height={chatIconCall ? "100%" : "auto"} // Ensure proper height in floating mode
        >
          {/* Adjust header position based on mode */}
          <Flex dir="row" width={"100%"} position={"absolute"}>
            <ChatbotHeader pmbotName={pmbotName} refreshChat={refreshChat} />
          </Flex>
          <Box
            flex={1}
            overflow="hidden"
            p={4}
            display="flex"
            flexDir={"column"}
            id="chat-container"
            marginTop={chatIconCall ? 75 : `${chatbotHeaderHeight}px`}
          >
            <ChatbotInterface
              queries={queries}
              pmbotName={pmbotName}
              messages={messages}
              setMessages={setMessages}
              requiredFieldsRef={requiredFieldsRef}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Main;
