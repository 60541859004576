import { useDisclosure } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";

const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [isPhone, setIsPhone] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(200);
  const {
    isOpen: isSidebarOpen,
    onOpen: openSidebar,
    onClose: closeSidebar,
  } = useDisclosure();

  useEffect(() => {
    // set sidebar width
    if (isSidebarOpen) {
      if (isPhone) {
        setSidebarWidth(screenWidth);
      } else {
        setSidebarWidth(200);
      }
    } else {
      setSidebarWidth(55);
    }
  }, [isPhone, isSidebarOpen]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth <= 780) {
        setIsPhone(true);
      } else {
        setIsPhone(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        screenWidth,
        setScreenWidth,
        isPhone,
        setIsPhone,
        sidebarWidth,
        setSidebarWidth,
        isSidebarOpen,
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const LayoutState = () => {
  return useContext(LayoutContext);
};

export default LayoutProvider;
