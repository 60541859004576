import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
  Text,
} from "@chakra-ui/react";

const DateRangePicker = ({
  startDate,
  endDate,
  onDateChange,
  viewingProject,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const handleApply = () => {
    onDateChange(localStartDate, localEndDate);
    setIsOpen(false);
  };

  const formatDate = (date) => {
    return date?.toISOString().split("T")[0];
  };

  const handleQuickSelect = (days) => {
    if (days === 0) {
      setLocalStartDate(new Date(viewingProject?.createdAt));
      setLocalEndDate(new Date());
      return;
    }
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - days);
    setLocalStartDate(start);
    setLocalEndDate(end);
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button onClick={() => setIsOpen(!isOpen)}>
          {startDate && formatDate(startDate)} -{" "}
          {endDate && formatDate(endDate)}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <VStack spacing={4}>
          <Flex justify="space-between" width="100%" flexDir={"column"}>
            <Box display={"flex"} gap={3} alignItems={"center"}>
              <Text>From</Text>
              <Input
                type="date"
                value={formatDate(localStartDate)}
                onChange={(e) => setLocalStartDate(new Date(e.target.value))}
              />
            </Box>
            <Box display={"flex"} gap={7} alignItems={"center"}>
              <Text>Till</Text>
              <Input
                type="date"
                value={formatDate(localEndDate)}
                onChange={(e) => setLocalEndDate(new Date(e.target.value))}
              />
            </Box>
          </Flex>
          <Flex width="100%" gap={1} flexWrap={"wrap"} justify={"center"}>
            <Button
              size="sm"
              onClick={() => handleQuickSelect(7)}
              width={"120px"}
            >
              Last 7 days
            </Button>
            <Button
              size="sm"
              onClick={() => handleQuickSelect(30)}
              width={"120px"}
            >
              Last 30 days
            </Button>
            <Button
              size="sm"
              onClick={() => handleQuickSelect(90)}
              width={"120px"}
            >
              Last 90 days
            </Button>
            <Button
              size="sm"
              onClick={() => handleQuickSelect(0)}
              width={"120px"}
            >
              All time
            </Button>
          </Flex>
          <Button colorScheme="blue" onClick={handleApply}>
            Apply
          </Button>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default DateRangePicker;
