import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Heading,
  Avatar,
  Badge,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Divider,
  Tag,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { UserState } from "../Context/UserProvider";
import { LayoutState } from "../Context/LayoutProvider";
import { useParams } from "react-router-dom";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const MyProfile = () => {
  const { sidebarWidth } = LayoutState();
  const [profileData, setProfileData] = useState(null);
  const [firebaseUserId, setFirebaseUserId] = useState("");
  const [skills, setSkills] = useState({});
  const [error, setError] = useState(false);
  const { userId } = useParams();

  const { fetchMemberData, selectedOrganization } = UserState();

  const fetchData = async () => {
    try {
      setError(false);
      const data = await fetchMemberData(
        firebaseUserId,
        selectedOrganization?.id
      );
      setProfileData(data);
      setSkills(data?.skills || {});
    } catch (e) {
      console.error("Error fetching profile data:", e);
      setError(true);
    }
  };

  useEffect(() => {
    if (firebaseUserId) {
      fetchData();
    }
  }, [firebaseUserId]);

  useEffect(() => {
    setFirebaseUserId(userId);
  }, []);

  const {
    firstName = "",
    lastName = "",
    email = "",
    imageUrl = "",
    weeklyHours = 0,
    currentBandwidth = 0,
    currentTaskHours = 0,
    tasksCompleted = 0,
    tasksFailed = 0,
    tasksSuccessful = 0,
    totalTasks = 0,
  } = profileData || {};

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.200");

  const taskChartData = {
    labels: ["Completed", "Active", "Pending"],
    datasets: [
      {
        data: [
          tasksCompleted,
          currentTaskHours,
          totalTasks - tasksCompleted - tasksFailed,
        ],
        backgroundColor: ["#48BB78", "#4299E1", "#ECC94B"],
        hoverBackgroundColor: ["#68D391", "#63B3ED", "#F6E05E"],
      },
    ],
  };

  const hoursChartData = {
    labels: ["Current Bandwidth", "Current Task Hours", "Remaining Hours"],
    datasets: [
      {
        data: [
          currentBandwidth,
          currentTaskHours,
          weeklyHours - currentBandwidth - currentTaskHours,
        ],
        backgroundColor: ["#9F7AEA", "#ED64A6", "#4FD1C5"],
        hoverBackgroundColor: ["#B794F4", "#F687B3", "#76E4F7"],
      },
    ],
  };

  const skillsChartData = {
    labels: Object.keys(skills),
    datasets: [
      {
        label: "Skill Level",
        data: Object.values(skills),
        backgroundColor: "#4FD1C5",
        borderColor: "#2C7A7B",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  if (error) {
    return (
      <Flex h="100vh" align="center" justify="center">
        <Text fontSize="lg" color="red.500">
          Failed to load user data. Please try again.
        </Text>
      </Flex>
    );
  }

  return (
    <Box minH="100vh" bg={bgColor} ml={sidebarWidth} p={6}>
      <VStack spacing={8} align="stretch">
        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="space-between"
        >
          <HStack spacing={4}>
            <Avatar
              size="xl"
              src={imageUrl}
              name={`${firstName} ${lastName}`}
            />
            <VStack align="start" spacing={1}>
              <Heading size="lg">{`${firstName} ${lastName}`}</Heading>
              <Text fontSize="md" color={textColor}>
                {email}
              </Text>
            </VStack>
          </HStack>
          <HStack mt={{ base: 4, md: 0 }}>
            <Badge colorScheme="purple">{`Bandwidth: ${currentBandwidth}`}</Badge>
            <Badge colorScheme="blue">{`Weekly Hours: ${weeklyHours}`}</Badge>
          </HStack>
        </Flex>

        <Divider />

        {/* Stats Section */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          <Stat>
            <StatLabel>Total Tasks</StatLabel>
            <StatNumber>{totalTasks}</StatNumber>
            <StatHelpText>All assigned tasks</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Completed Tasks</StatLabel>
            <StatNumber>{tasksCompleted}</StatNumber>
            <StatHelpText>Successfully finished</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Current Task Hours</StatLabel>
            <StatNumber>{currentTaskHours}</StatNumber>
            <StatHelpText>Hours on active tasks</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Completion Rate</StatLabel>
            <StatNumber>{`${
              totalTasks > 0
                ? ((tasksCompleted / totalTasks) * 100).toFixed(1)
                : 0
            }%`}</StatNumber>
            <Progress
              value={(tasksCompleted / totalTasks) * 100}
              colorScheme="green"
              size="sm"
              mt={2}
            />
          </Stat>
        </SimpleGrid>

        <Divider />

        {/* Charts Section */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          <Box height="300px">
            <Heading size="md" mb={4}>
              Tasks Breakdown
            </Heading>
            <Doughnut data={taskChartData} options={chartOptions} />
          </Box>
          <Box height="300px">
            <Heading size="md" mb={4}>
              Hours Breakdown
            </Heading>
            <Doughnut data={hoursChartData} options={chartOptions} />
          </Box>
          <Box height="300px">
            <Heading size="md" mb={4}>
              Skills Overview
            </Heading>
            <Bar
              data={skillsChartData}
              options={{
                ...chartOptions,
                scales: {
                  y: {
                    beginAtZero: true,
                    max: 10,
                  },
                },
              }}
            />
          </Box>
        </SimpleGrid>

        <Divider />

        {/* Skills Section */}
        <Box>
          <Heading size="md" mb={4}>
            Skills
          </Heading>
          <Wrap spacing={2}>
            {Object.entries(skills).map(([skill, level]) => (
              <WrapItem key={skill}>
                <Tag size="lg" colorScheme="teal" borderRadius="full">
                  {`${skill} (${level})`}
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </VStack>
    </Box>
  );
};

export default MyProfile;
