import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const MotionBox = motion(Box);

const Navbar = ({ navs, selectedNav, setSelectedNav, viewingProject }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("#96c9f9", "blue.200");
  const selectedTextColor = useColorModeValue("gray.600", "gray.800");

  const handleNavClicked = (nav) => {
    setSelectedNav(nav.name);
  };

  return (
    <Box
      as="nav"
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
      bgColor={bgColor}
      color={"gray.600"}
      boxShadow={"sm"}
      borderRadius={"md"}
      overflowX={"scroll"}
      id="project-page-navbar-container-0"
      minHeight={"40px"}
    >
      {navs[viewingProject?.management || "agile"].map((nav) => (
        <MotionBox
          key={nav.id}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          width={"100%"}
          bg={selectedNav === nav.name ? selectedBgColor : "transparent"}
        >
          <Button
            width={"100%"}
            bg={"inherit"}
            color={selectedNav === nav.name ? selectedTextColor : "inherit"}
            _hover={{
              bg: selectedNav === nav.name ? selectedBgColor : hoverBgColor,
            }}
            leftIcon={nav.icon}
            fontWeight={selectedNav === nav.name ? "bold" : "normal"}
            transition="all 0.2s"
            onClick={() => handleNavClicked(nav)}
            borderRadius={"md"}
          >
            {nav.value}
          </Button>
        </MotionBox>
      ))}
    </Box>
  );
};

export default Navbar;
