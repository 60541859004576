import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useDisclosure,
  Heading,
  Tooltip,
  useColorModeValue,
  Flex,
  Avatar,
  Input,
  InputGroup,
  InputLeftElement,
  AvatarGroup,
} from "@chakra-ui/react";
import { Scrollbar } from "react-scrollbars-custom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import CreateMyTaskModal from "../Miscellaneous/MyTasks/CreateMyTaskModal";
import { UserState } from "../../Context/UserProvider";
import { AuthState } from "../../Context/AuthProvider";
import ViewTaskModal from "../Miscellaneous/MyTasks/ViewTaskModal";
import { LayoutState } from "../../Context/LayoutProvider";
import { CheckIcon, Expand, Minimize } from "lucide-react";
import TaskCard from "./TaskCard";
import { addDays } from "date-fns";
import DateRangePicker from "./DateRangePicker";

const statuses = [
  { value: "To Do", name: "toDo", color: "gray.100" },
  { value: "In Progress", name: "inProgress", color: "blue.100" },
  { value: "Review", name: "review", color: "yellow.100" },
  { value: "Done", name: "done", color: "green.100" },
  { value: "Blocked", name: "blocked", color: "red.100" },
];

export default function KanbanBoard({
  assignees,
  viewingProject,
  isFullscreen,
  setIsFullscreen,
}) {
  const [statusColumnHeight, setStatusColumnHeight] = useState(
    isFullscreen ? `calc(100vh - 136px)` : `calc(100vh - 300px)`
  );
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [viewingTask, setViewingTask] = useState(null);
  const [filters, setFilters] = useState({
    taskQuery: "",
    assigneeIds: [],
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: addDays(new Date(), -14),
    endDate: new Date(),
  });

  const {
    isOpen: isCreateTaskModalOpen,
    onOpen: openCreateTaskModal,
    onClose: closeCreateTaskModal,
  } = useDisclosure();
  const {
    isOpen: isViewTaskModalOpen,
    onOpen: openViewTaskModal,
    onClose: closeViewTaskModal,
  } = useDisclosure();
  const { organizationMembers, updateTaskStatus, getAllProjectTasks } =
    UserState();
  const { user } = AuthState();
  const { screenWidth } = LayoutState();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const columnBgColor = useColorModeValue("white", "gray.800");

  const isAssigneeSelected = (assignee) => {
    return filters.assigneeIds.includes(assignee.firebaseUserId);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { destination, draggableId: taskId } = result;

    const newTasks = Array.from(filteredTasks);
    const taskIndex = newTasks.findIndex((task) => task.id === taskId);

    if (taskIndex === -1) return;

    const [movedTask] = newTasks.splice(taskIndex, 1);
    newTasks.splice(destination.index, 0, movedTask);

    const newStatus = statuses[parseInt(destination.droppableId)].name;
    const prevStatus = movedTask.status;
    movedTask.status = newStatus;

    try {
      if (newStatus !== prevStatus) {
        await updateTaskStatus(movedTask, newStatus, prevStatus);
        setFilteredTasks(newTasks);
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === movedTask.id ? { ...task, status: newStatus } : task
          )
        );
      }
    } catch (err) {
      console.error("Error updating task status:", err);
    }
  };

  const handleDateChange = (start, end) => {
    setDateFilter({ startDate: start, endDate: end });
  };

  useEffect(() => {
    const fetchAllProjectTasks = async () => {
      if (viewingProject) {
        try {
          const allTasks = await getAllProjectTasks(
            viewingProject?.id,
            dateFilter
          );
          setTasks(allTasks);
          setFilteredTasks(allTasks);
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (viewingProject) {
      fetchAllProjectTasks();
    }
  }, [viewingProject, dateFilter]);

  useEffect(() => {
    const topElement = document.getElementById(
      "kanban-board-section-status-box"
    );
    const topHeight =
      topElement.clientHeight + topElement.getBoundingClientRect().top;
    setStatusColumnHeight(`calc(100vh - ${topHeight}px + 10px)`);
  }, [isFullscreen]);

  useEffect(() => {
    const filtered = tasks?.filter((task) => {
      const matchesQuery =
        task?.title?.toLowerCase().includes(filters.taskQuery.toLowerCase()) ||
        task?.task_uid
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase()) ||
        task?.description
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase());

      const matchesAssignee =
        filters.assigneeIds.length === 0 ||
        filters.assigneeIds.includes(task.assigneeId);

      // checks dueDate, createdAt, completedAt

      const taskDate = {
        dueDate: task?.dueDate ? new Date(task.dueDate) : null,
        createdAt: task?.createdAt ? new Date(task.createdAt) : null,
        completedAt: task?.completedAt ? new Date(task.completedAt) : null,
      };

      const matchesDate =
        (taskDate.dueDate >= dateFilter.startDate &&
          taskDate.dueDate <= dateFilter.endDate) ||
        (taskDate.createdAt >= dateFilter.startDate &&
          taskDate.createdAt <= dateFilter.endDate) ||
        (taskDate.completedAt >= dateFilter.startDate &&
          taskDate.completedAt <= dateFilter.endDate);

      return matchesQuery && matchesAssignee && matchesDate;
    });

    setFilteredTasks(filtered);
  }, [filters, tasks, dateFilter]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        setIsFullscreen(true);
      } else if (event.key === "Escape") {
        setIsFullscreen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const IconBox = ({ icon: Icon, onClick, label }) => (
    <Box
      as={Icon}
      size="20px"
      cursor="pointer"
      _hover={{ color: "gray.700", bg: "gray.200", transform: "scale(1.1)" }}
      transition="all 0.2s"
      borderRadius="full"
      onClick={onClick}
      aria-label={label}
    />
  );

  return (
    <Box
      height={"100%"}
      width="100%"
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={1}
      pt={0}
      id="kanban-board-container-0"
    >
      <Flex
        align="center"
        justifyContent="end"
        mx={screenWidth < 700 && 4}
        mb={1}
        flexDir={"row"}
        width={"100%"}
      >
        <Flex
          gap={4}
          flex={1}
          alignItems="flex-start"
          justifyContent={"end"}
          overflowX={"scroll"}
        >
          {isFullscreen && (
            <Tooltip label="Project Name" placement="top">
              <Text
                cursor={"pointer"}
                textAlign={"center"}
                fontSize={"lg"}
                fontWeight={500}
                boxShadow={"md"}
                p={1}
                bgColor={"gray.50"}
                borderRadius={"md"}
              >
                {viewingProject?.projectName}{" "}
              </Text>
            </Tooltip>
          )}
          <Box maxW="420px" overflowX="auto" _hover={{ cursor: "pointer" }}>
            <AvatarGroup size="sm" _hover={{ cursor: "pointer" }}>
              {assignees?.map((assignee, index) => (
                <Tooltip
                  borderRadius={"full"}
                  key={index}
                  label={`${assignee?.firstName?.toUpperCase()} ${assignee?.lastName?.toUpperCase()}`}
                  placement="bottom"
                >
                  <Box position={"relative"} display={"inline-block"}>
                    <Avatar
                      key={index}
                      sx={{ height: "2.5rem", width: "2.5rem" }}
                      name={`${assignee?.firstName} ${assignee?.lastName}`}
                      src={assignee?.imageUrl || ""}
                      title={`${assignee?.firstName} ${assignee?.lastName}`}
                      onClick={() => {
                        setFilters((prev) => {
                          const newAssigneeIds = prev.assigneeIds.includes(
                            assignee?.firebaseUserId
                          )
                            ? prev.assigneeIds.filter(
                                (firebaseUserId) =>
                                  firebaseUserId !== assignee?.firebaseUserId
                              )
                            : [...prev.assigneeIds, assignee?.firebaseUserId];

                          return { ...prev, assigneeIds: newAssigneeIds };
                        });
                      }}
                    />
                    {isAssigneeSelected(assignee) && (
                      <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        bg="green.500"
                        borderRadius="full"
                        width="14px"
                        height="14px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CheckIcon color="white" boxSize="8px" />
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
          <Box>
            <DateRangePicker
              startDate={dateFilter.startDate}
              endDate={dateFilter.endDate}
              onDateChange={handleDateChange}
              viewingProject={viewingProject}
            />
          </Box>
          <InputGroup width={screenWidth < 850 ? "200px" : "350px"}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search tasks"
              value={filters.taskQuery}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, taskQuery: e.target.value }))
              }
            />
          </InputGroup>
        </Flex>
        <Button
          colorScheme="blue"
          leftIcon={<AddIcon />}
          onClick={openCreateTaskModal}
          ml={2}
          minW={"80px"}
        >
          Task
        </Button>
        {!isFullscreen && (
          <Tooltip
            label={isFullscreen ? "ESC" : "CTRL + F"}
            aria-label="Toggle Fullscreen"
            hasArrow
            bg="gray.700"
            color="white"
            padding="2"
            borderRadius="md"
            fontSize="sm"
            m={1}
          >
            <Flex align="center">
              <IconBox
                icon={Expand}
                onClick={() => setIsFullscreen(true)}
                label="Enter Fullscreen"
              />
            </Flex>
          </Tooltip>
        )}
        {isFullscreen && (
          <Box mx={3}>
            <Tooltip
              label="Exit Fullscreen"
              aria-label="Exit Fullscreen"
              m={1}
              hasArrow
              bg="gray.700"
              color="white"
              padding="2"
              borderRadius="md"
              fontSize="sm"
            >
              <Box>
                <IconBox
                  icon={Minimize}
                  onClick={() => setIsFullscreen(false)}
                  label="Exit Fullscreen"
                  fontSize="lg"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box width="100%" mx={2} overflowX={"scroll"}>
          <Flex
            alignItems="flex-start"
            justifyContent={screenWidth > 1600 && "center"}
            align={"center"}
            minWidth="100%"
            pb={2}
          >
            {statuses.map((status, columnIndex) => (
              <Droppable key={status.name} droppableId={columnIndex.toString()}>
                {(provided, snapshot) => (
                  <VStack
                    minWidth="270px"
                    height={statusColumnHeight}
                    borderWidth="1px"
                    borderColor={borderColor}
                    bg={bgColor}
                    p={2}
                    pb={0}
                    borderRadius="md"
                    borderBottomRadius={"0"}
                    boxShadow="md"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    flex={1}
                    mx={"8px"}
                    transition="background-color 0.2s"
                    bgGradient={
                      snapshot.isDraggingOver
                        ? `linear(to-b, ${status.color}, ${columnBgColor})`
                        : undefined
                    }
                  >
                    <Heading
                      size="sm"
                      fontSize={"1em"}
                      mb={2}
                      px={2}
                      color="#4a2a5a"
                      textAlign={"left"}
                      width={"100%"}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box id="kanban-board-section-status-box">
                        {`${status.value.toUpperCase()} (${
                          filteredTasks?.filter(
                            (task) => task.status === status.name
                          ).length
                        })`}
                      </Box>
                      <Box mr={2}>
                        {filteredTasks
                          ?.filter((task) => task.status === status.name)
                          ?.reduce((total, task) => total + task.duration, 0) >
                          0 &&
                          `${filteredTasks
                            ?.filter((task) => task.status === status.name)
                            ?.reduce(
                              (total, task) => total + task.duration,
                              0
                            )}h`}
                      </Box>
                    </Heading>

                    <Box
                      position="relative"
                      height="calc(100% - 40px)"
                      width="100%"
                    >
                      <Scrollbar
                        style={{
                          width: "100%",
                          height: "calc(100% - 14px)",
                          paddingRight: "0px",
                        }}
                        noScrollX={false} // Enable horizontal scrolling
                        noScrollY={false} // Disable vertical scrolling
                        trackYProps={{ style: { width: "6px" } }}
                      >
                        <Box pr={2}>
                          {filteredTasks
                            ?.filter((task) => task.status === status.name)
                            ?.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    opacity={snapshot.isDragging ? 0.8 : 1}
                                    transform={
                                      snapshot.isDragging ? "rotate(3deg)" : ""
                                    }
                                    transition="all 0.2s"
                                  >
                                    <TaskCard
                                      task={task}
                                      organizationMembers={organizationMembers}
                                      setViewingTask={setViewingTask}
                                      onOpen={openViewTaskModal}
                                      user={user}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </Box>
                        <Box height={"200px"} />
                      </Scrollbar>
                    </Box>
                  </VStack>
                )}
              </Droppable>
            ))}
          </Flex>
        </Box>
      </DragDropContext>
      {isCreateTaskModalOpen && (
        <CreateMyTaskModal
          isOpen={isCreateTaskModalOpen}
          onClose={closeCreateTaskModal}
          setAllTasks={setTasks}
          allTasks={tasks}
          project={viewingProject}
        />
      )}
      {isViewTaskModalOpen && (
        <ViewTaskModal
          task={viewingTask}
          setTask={setViewingTask}
          isOpen={isViewTaskModalOpen}
          onClose={() => {
            setViewingTask(null);
            closeViewTaskModal();
          }}
          allTasks={tasks}
          setAllTasks={setTasks}
        />
      )}
    </Box>
  );
}
