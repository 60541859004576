import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import ViewTaskModal from "../MyTasks/ViewTaskModal";
import { AuthState } from "../../../Context/AuthProvider";
import PriorityDropDown from "../Dropdowns/PriorityDropDown";
import StatusDropDown from "../Dropdowns/StatusDropDown";
import {
  taskStatusText,
  taskPriorityStyles,
  taskStatusStyleLight,
} from "../MyTasks/styles";
import { findFirstName, findName, isSelected } from "../MyTasks/utils";
import { Search2Icon } from "@chakra-ui/icons";
import { FilterIcon } from "lucide-react";
import { LayoutState } from "../../../Context/LayoutProvider";

export default function MyReports({ isPhoneSideBarOpen, reportRef }) {
  const {
    reportTasks,
    projects,
    organizationMembers,
    setReportTasks,
    updateTaskStatus,
    updateTaskPriority,
  } = UserState();
  const { user } = AuthState();
  const { screenWidth } = LayoutState();
  const [selectedReportTasks, setSelectedReportTasks] = useState([]);
  const [tasksToDisplay, setTasksToDisplay] = useState([]);
  const [spacingFromTop, setSpacingFromTop] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewModalTask, setViewModalTask] = useState(null);
  const [clickedTask, setClickedTask] = useState(null);
  const [clickedTaskStatus, setClickedTaskStatus] = useState(null);
  const [clickedTaskPriority, setClickedTaskPriority] = useState(null);
  const [hasValidTasks, setHasValidTasks] = useState(false);

  // New state for filters
  const [taskNameFilter, setTaskNameFilter] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [sprintFilter, setSprintFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");
  const [assigneeFilter, setAssigneeFilter] = useState("");

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBgColor = useColorModeValue("gray.50", "gray.700");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("purple.50", "purple.900");

  const isCompactView = screenWidth < 1145;

  const handleDropDownsSectionClick = (e, task) => {
    e.stopPropagation();
    setClickedTask(task);
    setViewModalTask(null);
  };

  const selectUnselectAll = () => {
    if (!allSelected) {
      setSelectedReportTasks([...(tasksToDisplay || [])]);
    } else {
      setSelectedReportTasks([]);
    }
    setAllSelected(!allSelected);
  };

  const resetClickedTask = () => {
    setClickedTask(null);
    setClickedTaskPriority(null);
    setClickedTaskStatus(null);
  };

  const closeViewTaskModal = () => {
    setIsViewModalOpen(false);
    setViewModalTask(null);
  };

  useEffect(() => {
    if (!reportTasks || !viewModalTask) return;

    let hasChangedFlag = false;

    const updatedReportTasks = reportTasks.map((reportTask) => {
      if (reportTask.id !== viewModalTask.id) return reportTask;
      if (reportTask.commentsCount !== viewModalTask.commentsCount) {
        hasChangedFlag = true;
      }
      return viewModalTask;
    });

    if (hasChangedFlag) {
      setReportTasks(updatedReportTasks);
    }
  }, [viewModalTask]);

  useEffect(() => {
    setAllSelected(
      selectedReportTasks?.length === tasksToDisplay?.length &&
        tasksToDisplay?.length > 0
    );
  }, [selectedReportTasks, tasksToDisplay]);

  useEffect(() => {
    setTasksToDisplay(() => {
      let activeSprints = [];

      if (sprintFilter) {
        activeSprints = projects.map((project) => project.sprints.activeSprint);
      }

      return reportTasks?.filter((task) => {
        return (
          task.status !== "done" &&
          (task.title.toLowerCase().includes(taskNameFilter.toLowerCase()) ||
            task?.task_uid
              ?.toLowerCase()
              .includes(taskNameFilter.toLowerCase())) &&
          (projectFilter === "" || task.projectId === projectFilter) &&
          (sprintFilter === false || activeSprints.includes(task.sprintId)) &&
          (statusFilter === "" || task.status === statusFilter) &&
          (priorityFilter === "" || task.priority === priorityFilter) &&
          (assigneeFilter === "" || task.assigneeId === assigneeFilter)
        );
      });
    });
  }, [
    reportTasks,
    taskNameFilter,
    projectFilter,
    sprintFilter,
    statusFilter,
    priorityFilter,
    assigneeFilter,
  ]);

  useEffect(() => {
    if (clickedTask) {
      setClickedTaskStatus(clickedTask.status);
      setClickedTaskPriority(clickedTask.priority);
      closeViewTaskModal();
    }
  }, [clickedTask]);

  useEffect(() => {
    if (
      clickedTask &&
      (clickedTaskPriority !== clickedTask.priority ||
        clickedTaskStatus !== clickedTask.status)
    ) {
      const clickedTaskIdx = reportTasks.findIndex(
        (task) => task.id === clickedTask.id
      );

      if (clickedTaskPriority !== clickedTask.priority) {
        updateTaskPriority(
          clickedTask,
          clickedTaskPriority,
          -1,
          clickedTaskIdx
        );
      }

      if (clickedTaskStatus !== clickedTask.status) {
        updateTaskStatus(
          clickedTask,
          clickedTaskStatus,
          clickedTask.status,
          -1,
          clickedTaskIdx
        );
      }

      resetClickedTask();
    }
  }, [clickedTaskPriority, clickedTaskStatus]);

  useEffect(() => {
    if (reportTasks && reportTasks.length > 0) {
      const validTask = reportTasks.find((task) => task.status !== "done");
      if (validTask?.title) {
        setHasValidTasks(true);
      }
    }
  }, [reportTasks]);

  useEffect(() => {
    const headerContainer = document.querySelector(".header-container");
    if (headerContainer) {
      setSpacingFromTop(headerContainer.clientHeight);
    }
  }, []);

  const FilterPopover = () => (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button leftIcon={<FilterIcon size="1em" />} size="md" minW="90px">
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <VStack spacing={2}>
            {screenWidth < 550 && (
              <Box width="100%">
                <Flex
                  as="label"
                  alignItems="center"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  px={3}
                  py={2}
                  cursor="pointer"
                >
                  <Checkbox
                    colorScheme="blue"
                    isChecked={sprintFilter}
                    onChange={(e) => setSprintFilter(e.target.checked)}
                    mr={3}
                  />
                  <Text fontSize="sm" fontWeight={500}>
                    Active Sprint Only
                  </Text>
                </Flex>
              </Box>
            )}
            <Select
              placeholder="Project"
              value={projectFilter}
              onChange={(e) => setProjectFilter(e.target.value)}
            >
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.projectName}
                </option>
              ))}
            </Select>
            <Select
              placeholder="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="toDo">To Do</option>
              <option value="inProgress">In Progress</option>
              <option value="blocked">Blocked</option>
              <option value="review">Review</option>
            </Select>
            <Select
              placeholder="Priority"
              value={priorityFilter}
              onChange={(e) => setPriorityFilter(e.target.value)}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </Select>
            <Select
              placeholder="Assignee"
              value={assigneeFilter}
              onChange={(e) => setAssigneeFilter(e.target.value)}
            >
              {organizationMembers.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.firstName} {member.lastName}
                </option>
              ))}
            </Select>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    hasValidTasks && (
      <Box
        className="my-reports-component"
        ref={reportRef}
        style={{ all: "unset" }}
      >
        {isViewModalOpen && (
          <ViewTaskModal
            isOpen={isViewModalOpen}
            onClose={closeViewTaskModal}
            task={viewModalTask}
            setTask={setViewModalTask}
          />
        )}

        <Flex justify="space-between" align="center" mb={2} mx={2} mt={5}>
          <Text fontSize="23px" fontWeight="bold" color={textColor}>
            Team's Progress
          </Text>
        </Flex>

        {hasValidTasks && (
          <Flex direction="column" mb={4} mx={2} gap={2}>
            <Flex
              gap={2}
              justify={screenWidth >= 1520 ? "center" : "space-between"}
            >
              {screenWidth >= 550 && (
                <Box width="200px">
                  <Flex
                    as="label"
                    alignItems="center"
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    px={3}
                    py={2}
                    cursor="pointer"
                  >
                    <Checkbox
                      colorScheme="blue"
                      isChecked={sprintFilter}
                      onChange={(e) => setSprintFilter(e.target.checked)}
                      mr={3}
                    />
                    <Text fontSize="sm" fontWeight={500} whiteSpace="nowrap">
                      Active Sprint Only
                    </Text>
                  </Flex>
                </Box>
              )}
              <InputGroup width={isCompactView ? "100%" : "280px"}>
                <InputLeftElement pointerEvents="none">
                  <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search task"
                  value={taskNameFilter}
                  onChange={(e) => setTaskNameFilter(e.target.value)}
                />
              </InputGroup>
              {isCompactView ? (
                <FilterPopover />
              ) : (
                <>
                  <Select
                    placeholder="Project"
                    value={projectFilter}
                    onChange={(e) => setProjectFilter(e.target.value)}
                    width="200px"
                  >
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.projectName}
                      </option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Status"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    width="200px"
                  >
                    <option value="toDo">To Do</option>
                    <option value="inProgress">In Progress</option>
                    <option value="blocked">Blocked</option>
                    <option value="review">Review</option>
                  </Select>
                  <Select
                    placeholder="Priority"
                    value={priorityFilter}
                    onChange={(e) => setPriorityFilter(e.target.value)}
                    width="200px"
                  >
                    <option value="low" key={`my-reports-priority-filter-low`}>
                      Low
                    </option>
                    <option
                      value="medium"
                      key={`my-reports-priority-filter-medium`}
                    >
                      Medium
                    </option>
                    <option
                      value="high"
                      key={`my-reports-priority-filter-high`}
                    >
                      High
                    </option>
                  </Select>
                  <Select
                    placeholder="Assignee"
                    value={assigneeFilter}
                    onChange={(e) => setAssigneeFilter(e.target.value)}
                    width="200px"
                  >
                    {organizationMembers.map((member) =>
                      member?.firebaseUserId ? (
                        <option
                          key={`my-reports-assignee-filter-assigneeId-${member.firebaseUserId}`}
                          value={member.firebaseUserId}
                        >
                          <Flex flexDir={"column"}>
                            <Text>
                              {member?.firstName?.toUpperCase()}{" "}
                              {member?.lastName?.toUpperCase()}
                            </Text>
                          </Flex>
                        </option>
                      ) : (
                        <></>
                      )
                    )}
                  </Select>
                </>
              )}
            </Flex>
          </Flex>
        )}

        {tasksToDisplay?.length > 0 ? (
          <TableContainer
            maxHeight={`calc(100vh - ${spacingFromTop + 90}px)`}
            overflowY="auto"
            mr={2}
          >
            <Table variant="simple">
              <Thead position="sticky" top={0} zIndex={1} bg={headerBgColor}>
                <Tr>
                  <Th
                    textAlign="center"
                    width="120px"
                    maxWidth="120px"
                    overflowX="scroll"
                  >
                    Task #
                  </Th>
                  <Th textAlign="left" minWidth="250px" flex={1}>
                    Title
                  </Th>
                  <Th
                    textAlign="center"
                    width="150px"
                    maxW="150px"
                    overflowX="scroll"
                  >
                    Project
                  </Th>
                  <Th
                    textAlign="center"
                    width="120px"
                    maxW="120px"
                    overflowX="scroll"
                  >
                    Duration
                  </Th>
                  <Th
                    textAlign="center"
                    width="120px"
                    maxW="120px"
                    overflowX="scroll"
                  >
                    Assignee
                  </Th>
                  <Th
                    textAlign="center"
                    width="180px"
                    maxW="180px"
                    overflowX="scroll"
                  >
                    Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tasksToDisplay?.map((task) => (
                  <Tr
                    key={task.id}
                    id={`myReports-${task?.id}`}
                    _hover={{ bg: hoverBgColor, cursor: "pointer" }}
                    bg={
                      isSelected(task, selectedReportTasks)
                        ? selectedBgColor
                        : "transparent"
                    }
                  >
                    <Td
                      textAlign="center"
                      width="120px"
                      maxWidth="120px"
                      onClick={() => {
                        setIsViewModalOpen(true);
                        setViewModalTask(task);
                      }}
                      overflowX="scroll"
                    >
                      {task?.task_uid || task?.id?.slice(-8)}
                    </Td>
                    <Td
                      textAlign="left"
                      minWidth="250px"
                      onClick={() => {
                        setIsViewModalOpen(true);
                        setViewModalTask(task);
                      }}
                      overflowX="scroll"
                      whiteSpace="nowrap"
                      flex={1}
                    >
                      {task?.title ? task.title.substring(0, 100) : ""}
                    </Td>
                    <Td
                      textAlign="center"
                      onClick={() => {
                        setIsViewModalOpen(true);
                        setViewModalTask(task);
                      }}
                      width="150px"
                      maxW="150px"
                      overflowX="scroll"
                    >
                      <Text>
                        {
                          projects?.find(
                            (project) => project.id === task.projectId
                          )?.projectName
                        }
                      </Text>
                    </Td>
                    <Td
                      textAlign="center"
                      onClick={() => {
                        setIsViewModalOpen(true);
                        setViewModalTask(task);
                      }}
                      width="120px"
                      maxW="120px"
                      overflowX="scroll"
                    >
                      {task?.duration && task.duration !== "0"
                        ? task.duration
                        : ""}
                    </Td>
                    <Td
                      textAlign="center"
                      onClick={() => {
                        setIsViewModalOpen(true);
                        setViewModalTask(task);
                      }}
                      width="200px"
                      overflowX="hidden"
                      maxWidth="200px"
                    >
                      {task.assigneeId ? (
                        <Flex alignItems="center" gap={2}>
                          <Avatar
                            size={"xs"}
                            name={findName(
                              task?.assigneeId,
                              organizationMembers,
                              user
                            )}
                          />
                          {/* Wrap the Assignee's Name with Tooltip */}
                          <Tooltip
                            label={
                              findName(
                                task?.assigneeId,
                                organizationMembers,
                                user
                              ) || "No Name"
                            }
                            placement="top"
                            hasArrow
                          >
                            <Text isTruncated>
                              {findName(
                                task?.assigneeId,
                                organizationMembers,
                                user
                              )?.split(" ")[0] || "No Assignee"}
                            </Text>
                          </Tooltip>
                        </Flex>
                      ) : (
                        "-"
                      )}
                    </Td>
                    <Td
                      onClick={(e) => handleDropDownsSectionClick(e, task)}
                      textAlign="center"
                      display="flex"
                      gap={2}
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        justifyContent="right"
                        width="180px"
                        padding={0}
                        margin={0}
                        gap={2}
                        className={`myReports-${task.id}-dropdowns`}
                        maxW="180px"
                        overflowX="scroll"
                      >
                        <StatusDropDown
                          status={clickedTaskStatus}
                          setStatus={setClickedTaskStatus}
                          isParent={task?.subTasks?.length > 0}
                        >
                          <Button
                            size="sm"
                            display="block"
                            minWidth="117px !important"
                            bg={taskStatusStyleLight[task?.status]}
                            color="gray.800 !important"
                            _hover={{ opacity: 0.8 }}
                          >
                            {taskStatusText[task?.status]}
                          </Button>
                        </StatusDropDown>
                        <PriorityDropDown
                          priority={clickedTaskPriority}
                          setPriority={setClickedTaskPriority}
                        >
                          <Button style={{ all: "unset" }}>
                            {task?.priority && task?.status !== "done"
                              ? taskPriorityStyles[task.priority]
                              : ""}
                          </Button>
                        </PriorityDropDown>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Flex justify="center" align="center" height="200px">
            <Text fontSize="lg" color={textColor}>
              {hasValidTasks ? "No match found." : "No Active Reports Found."}
            </Text>
          </Flex>
        )}
      </Box>
    )
  );
}
