import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";

export function InviteMembersModal({ isOpen, onClose, organizationMembers }) {
  const { screenWidth } = LayoutState();
  const { addMembersToOrganization } = UserState();
  const [emailsText, setEmailsText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message1: "",
    message2: "",
    message3: "",
    message4: "",
  });
  const emailsTextRef = useRef("");

  const processEmailsText = async () => {
    try {
      if (!emailsText) {
        setErrorMessage({
          message1: "",
          message2: "",
          message3: "",
        });
        return;
      }

      if (emailsText === "") {
        setErrorMessage({
          message1: "Add emails to invite.",
          message2: "",
          message3: "",
        });

        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsLoading(true);

      // Split the emailsText by commas and trim any extra whitespace
      let emailsArray = [];
      if (emailsText) {
        emailsArray = emailsText.split(",").map((email) => email.trim());
      }

      // Separate valid and invalid emails
      const validEmails = [];
      const invalidEmails = [];

      emailsArray.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          invalidEmails.push(email);
        }
      });

      if (invalidEmails.length > 0) {
        setErrorMessage((obj) => ({
          ...obj,
          message1: `Invalid email addresses: ${invalidEmails.join(", ")}`,
          message2: "Please enter valid email addresses.",
        }));
      } else {
        setErrorMessage({
          message1: "",
          message2: "",
          message3: "",
        });
      }

      if (validEmails.length > 0) {
        const uniqueEmailsSet = new Set(validEmails);
        const uniqueEmails = Array.from(uniqueEmailsSet);

        const presentInOrg = [];
        for (const member of organizationMembers) {
          if (uniqueEmailsSet.has(member.email)) {
            presentInOrg.push(member.email);
          }
        }

        const notPresentInOrg =
          uniqueEmails.filter((email) => !presentInOrg.includes(email)) || [];

        if (presentInOrg.length > 0) {
          setErrorMessage((obj) => {
            const newMessage = `Users already in organization: ${presentInOrg.join(
              ", "
            )}`;
            return { ...obj, message3: newMessage };
          });
        }

        if (notPresentInOrg.length > 0) {
          // Add these members to the organization
          try {
            await addMembersToOrganization(notPresentInOrg);

            // set emailsText to invalid emails
            setEmailsText(invalidEmails.join(", "));
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      initialFocusRef={emailsTextRef}
    >
      <ModalOverlay />
      <ModalContent maxWidth={screenWidth < 700 ? "95%" : "600px"}>
        <ModalHeader>Invite Members</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            isInvalid={
              !errorMessage?.message1 &&
              !errorMessage?.message2 &&
              !errorMessage.message3
                ? false
                : true
            }
          >
            <Textarea
              placeholder="Enter emails here."
              minHeight={"150px"}
              value={emailsText}
              onChange={(e) => {
                setEmailsText(e.target.value);
                setErrorMessage({ message1: "", message2: "", message3: "" });
              }}
            />
            <FormErrorMessage>
              <VStack textAlign={"left"} alignItems={"start"}>
                {Object.values(errorMessage).map((message) => {
                  if (message) {
                    return (
                      <Box
                        key={message}
                        textAlign={"left"}
                        overflowX={"scroll"}
                      >
                        {message}
                        <br />
                      </Box>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </VStack>
            </FormErrorMessage>
            {!(
              errorMessage?.message1 ||
              errorMessage?.message2 ||
              errorMessage?.message3
            ) && (
              <FormHelperText>
                Paste a list of emails seperated by commas (as a string).
                <br />
                <br />
                Example:
                <br /> hello@lumiorc.com, contact@lumiproject.app,
                abcd@domain.in
              </FormHelperText>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter display={"flex"} justifyContent={"space-between"} mx={2}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={processEmailsText}
            isLoading={isLoading}
            colorScheme="blue"
          >
            {"Send Invites"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
