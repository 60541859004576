import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import { UserState } from "../Context/UserProvider";
import Main from "../Components/ProjectPageComponents/Main";

const Project = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projects, selectedOrganization } = UserState();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [viewingProject, setViewingProject] = useState(null);

  useEffect(() => {
    if (projects && Array.isArray(projects) && projects.length > 0) {
      const currentProject = projects.find((proj) => proj.id === projectId);
      if (currentProject) {
        setViewingProject(currentProject);
      } else {
        navigate("/dashboard");
        toast({
          title: "No such project exists.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      navigate("/dashboard");
      toast({
        title: "No such project exists.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [projects, projectId]);

  return (
    <Box
      style={{ all: "unset" }}
      position={"fixed"}
      top={0}
      left={0}
      width={"100vw"}
      id="project-page-container-0"
    >
      {projects && viewingProject && (
        <Box
          display="flex"
          maxWidth={"100vw"}
          minHeight={"calc(100vh - 60px)"}
          width={"100vw"}
          flexDirection={"column"}
        >
          <Box
            className="post-header"
            maxHeight={`calc(100vh - 60px)`}
            overflow={"none"}
            flex={1}
            margin={0}
            padding={0}
            display={"flex"}
            flexDirection={"row"}
          >
            <Main
              isOpen={isOpen}
              onClose={onClose}
              viewingProject={viewingProject}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Project;
