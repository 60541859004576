import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Button,
  useToast,
  Checkbox,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import { Search2Icon, AddIcon } from "@chakra-ui/icons";
import { Scrollbar } from "react-scrollbars-custom";
import { debounce } from "lodash";
import FilterPopover from "./FilterPopover";
import { taskStatusStyle, taskStatusText, taskPriorityStyles } from "./styles";
import AssigneeDropDown from "../../Miscellaneous/Dropdowns/AssigneeDropDown";
import ReporterDropDown from "../../Miscellaneous/Dropdowns/ReporterDropDown";
import PriorityDropDown from "../../Miscellaneous/Dropdowns/PriorityDropDown";
import StatusDropDown from "../../Miscellaneous/Dropdowns/StatusDropDown";
import { UserState } from "../../../Context/UserProvider";

const Inventory = ({ viewingProject }) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const toast = useToast();

  const { getProjectTasks, updateTask } = UserState();

  const columns = [
    "task_uid",
    "title",
    "description",
    "status",
    "assignee",
    "duration",
    "dueDate",
    "reviewer",
    "priority",
  ];

  const fetchTasks = async () => {
    if (!hasMore || loading) return;
    setLoading(true);
    try {
      const query = { page, limit };
      const newTasks = await getProjectTasks(viewingProject?.id, query);
      setTasks((prevTasks) => [...(prevTasks || []), ...(newTasks || [])]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(newTasks?.length === limit);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error fetching tasks",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTasks([]);
    setPage(1);
    setHasMore(true);
    fetchTasks();
  }, [viewingProject]);

  useEffect(() => {
    const filtered = tasks?.filter((task) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        if (key === "dueDate") {
          const taskDate = new Date(task[key]);
          return (
            taskDate >= new Date(value.start) && taskDate <= new Date(value.end)
          );
        }
        return String(task[key]).toLowerCase().includes(value.toLowerCase());
      });
    });
    setFilteredTasks(filtered);
  }, [tasks, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleTaskUpdate = useCallback(
    debounce(async (taskId, field, value) => {
      try {
        await updateTask(taskId, { [field]: value });
        toast({
          title: "Task updated",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
        toast({
          title: "Failed to update task",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }, 500),
    []
  );

  const renderCell = (task, key) => {
    switch (key) {
      case "task_uid":
        return <Text width={"90px"}>{task[key] || task.id.slice(-8)}</Text>;
      case "title":
        return <Text minW={"200px"}>{task[key]}</Text>;
      case "description":
        return <Text minW={"350px"}>{task[key]}</Text>;
      case "status":
        return (
          <StatusDropDown
            status={task.status}
            setStatus={(status) => handleTaskUpdate(task.id, "status", status)}
            isParent={task?.subTasks?.length > 0}
          >
            <Button
              size="sm"
              bgColor={taskStatusStyle[task.status]}
              color="gray.800"
              width={"85px"}
            >
              {taskStatusText[task.status]}
            </Button>
          </StatusDropDown>
        );
      case "assignee":
        return (
          <AssigneeDropDown
            assigneeId={task.assigneeId}
            setAssigneeId={(id) => handleTaskUpdate(task.id, "assigneeId", id)}
            projectId={viewingProject.id}
            reviewerId={task.reviewerId}
          />
        );
      case "duration":
        return (
          <Text width={"80px"} textAlign={"center"}>
            {task[key]}
          </Text>
        );
      case "dueDate":
        return (
          <Text textAlign={"center"} width={"150px"}>
            {task[key] ? new Date(task[key]).toLocaleDateString() : "-"}
          </Text>
        );
      case "reviewer":
        return (
          <ReporterDropDown
            reviewerId={task.reviewerId}
            setReviewerId={(id) => handleTaskUpdate(task.id, "reviewerId", id)}
            projectId={viewingProject.id}
            assigneeId={task.assigneeId}
          />
        );
      case "priority":
        return (
          <PriorityDropDown
            priority={task.priority}
            setPriority={(priority) =>
              handleTaskUpdate(task.id, "priority", priority)
            }
          >
            <Button style={{ all: "unset" }}>
              {taskPriorityStyles[task.priority]}
            </Button>
          </PriorityDropDown>
        );
      default:
        return <Text>{task[key]}</Text>;
    }
  };

  const lastTaskElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchTasks();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <Box>
      <Flex mb={4} gap={2} flexWrap="wrap" ml={1}>
        <Input
          placeholder="Search tasks"
          value={filters.title || ""}
          onChange={(e) => handleFilterChange("title", e.target.value)}
          width="300px"
          leftIcon={<Search2Icon />}
        />
        <Select
          placeholder="Status"
          value={filters.status || ""}
          onChange={(e) => handleFilterChange("status", e.target.value)}
          width="200px"
        >
          {Object.keys(taskStatusText).map((status) => (
            <option key={status} value={status}>
              {taskStatusText[status]}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Priority"
          value={filters.priority || ""}
          onChange={(e) => handleFilterChange("priority", e.target.value)}
          width="200px"
        >
          {Object.keys(taskPriorityStyles).map((priority) => (
            <option key={priority} value={priority}>
              {priority}
            </option>
          ))}
        </Select>
        <FilterPopover filters={filters} setFilters={setFilters} />

        <Flex ml={"auto"} mr={1}>
          <Button leftIcon={<AddIcon />} size={"md"} colorScheme="blue">
            Add
          </Button>
        </Flex>
      </Flex>

      <Scrollbar style={{ width: "100%", height: "calc(100vh - 200px)" }}>
        <Table variant="simple">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={selectedTasks?.length === filteredTasks?.length}
                  onChange={() =>
                    setSelectedTasks(
                      selectedTasks?.length === filteredTasks?.length
                        ? []
                        : filteredTasks
                    )
                  }
                  p={1}
                />
              </Th>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredTasks?.map((task, index) => (
              <Tr
                key={task?.id}
                ref={
                  index === filteredTasks.length - 1 ? lastTaskElementRef : null
                }
              >
                <Td>
                  <Checkbox
                    isChecked={selectedTasks?.includes(task)}
                    onChange={() =>
                      setSelectedTasks((prev) =>
                        prev.includes(task)
                          ? prev.filter((t) => t !== task)
                          : [...(prev || []), task]
                      )
                    }
                  />
                </Td>
                {columns.map((column) => (
                  <Td key={`${task.id}-${column}`} p={1}>
                    {renderCell(task, column)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {loading && (
          <Flex justify="center" my={4}>
            <Spinner />
          </Flex>
        )}
        {!hasMore && (
          <Text textAlign="center" my={4}>
            No more tasks to load
          </Text>
        )}
      </Scrollbar>
    </Box>
  );
};

export default Inventory;
