import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import SettingsPanel from "../Components/SettingComponents/SettingsPanel";
import SettingExpanded from "../Components/SettingComponents/SettingExpanded";
import { LayoutState } from "../Context/LayoutProvider";

const Settings = () => {
  const { screenWidth, isPhone, sidebarWidth, isSidebarOpen } = LayoutState();
  const [selectedSetting, setSelectedSetting] = useState("");
  const [settingsPanelWidth, setSettingsPanelWidth] = useState(
    screenWidth > 580 ? 200 : screenWidth
  );

  useEffect(() => {
    !isPhone && setSelectedSetting("profile");

    // update the width of the settings panel
    if (screenWidth > 580) {
      setSettingsPanelWidth(200);
    } else {
      setSettingsPanelWidth(screenWidth);
    }
  }, [isPhone]);

  return (
    <Box
      width={isSidebarOpen ? `calc(100vw - ${sidebarWidth}px)` : "100vw"}
      height="100vh"
      ml={sidebarWidth}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      overflow="hidden"
      id="settings-container-0"
    >
      <Grid
        templateColumns={isPhone ? "1fr" : `${settingsPanelWidth}px 1fr`}
        height="calc(100vh - 60px)"
        id="settings-container-1-grid"
      >
        <GridItem
          display={isPhone && selectedSetting ? "none" : "block"}
          height="calc(100vh - 60px)"
          overflowY="auto"
        >
          <SettingsPanel
            setSelectedSetting={setSelectedSetting}
            selectedSetting={selectedSetting}
          />
        </GridItem>
        <GridItem
          id="settings-expanded-parent-container"
          height="calc(100vh - 60px)"
          overflowY="auto"
        >
          <SettingExpanded
            setSelectedSetting={setSelectedSetting}
            selectedSetting={selectedSetting}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Settings;
