"use client";

import { Box } from "@chakra-ui/react";
import React from "react";
import { LayoutState } from "../../../Context/LayoutProvider";
import SideBarContent from "./SideBarContent";

const PhoneSideBar = () => {
  const { sidebarWidth, isSidebarOpen } = LayoutState();

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width={sidebarWidth}
      height={"100%"}
      overflow="hidden"
      display={"flex"}
      flexDirection="column"
      className="phone-side-bar"
      zIndex={98}
      boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
      transition="all 0.3s ease-in-out"
      id="phone-side-bar-container"
    >
      <Box
        bgColor="white"
        flex={1}
        display="flex"
        flexDirection="column"
        overflow="auto"
        p={isSidebarOpen && 2}
      >
        <SideBarContent />
      </Box>
    </Box>
  );
};

export default PhoneSideBar;
