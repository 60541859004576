import React from "react";
import { Box, Flex, Avatar } from "@chakra-ui/react";
import { LayoutState } from "../../Context/LayoutProvider";
import { AuthState } from "../../Context/AuthProvider";
import Organization from "../Miscellaneous/Organization/Organization";
import Notifications from "../NotificationComponents/Notifications";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { isPhone } = LayoutState();
  const { user } = AuthState();
  const navigate = useNavigate();

  return (
    <>
      <Box
        className="header-container"
        position="sticky"
        top={0}
        zIndex="10"
        bgColor={"gray.50"}
        boxShadow="sm"
      >
        <Flex justifyContent="space-between" alignItems="center" py={2} px={4}>
          <Flex
            alignItems="center"
            gap={2}
            onClick={() => navigate("/dashboard")}
            _hover={{ cursor: "pointer" }}
          ></Flex>
          <Flex alignItems="center" gap={isPhone ? 1 : 4}>
            <Organization />
            <Notifications />
            <Avatar
              src={user?.imageUrl}
              name={`${user?.firstName} ${user?.lastName}`}
              size="sm"
              onClick={() => {
                navigate(`/profile/${user?.firebaseUserId}`);
              }}
              _hover={{
                cursor: "pointer",
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
