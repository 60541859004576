"use client";

import { Box, Text, VStack, Flex } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { UserState } from "../../../../Context/UserProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { FolderIcon } from "lucide-react";

const AllProjects = ({
  setClicked,
  selectedProjectId,
  setSelectedProjectId,
}) => {
  const { projects } = UserState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleProjectSelect = (proj) => {
    setClicked(true);
    navigate(`/projects/${proj.id}`);
  };

  useEffect(() => {
    if (location.pathname.includes("/projects")) {
      const project_id = location.pathname.split("/")[2];
      setSelectedProjectId(project_id);
    } else {
      setSelectedProjectId(null);
    }
  }, [location, navigate]);

  return (
    <Box
      id="allprojects-container"
      maxHeight="240px"
      width={"100%"}
      overflowY="auto"
      borderRadius="md"
      boxShadow="sm"
      bg="white"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "gray.300",
          borderRadius: "24px",
        },
      }}
    >
      {!projects || !Array.isArray(projects) || projects.length === 0 ? (
        <Flex align="center" justify="center" height="100%" p={4}>
          <Text fontSize="sm" color="gray.500">
            No Available Projects
          </Text>
        </Flex>
      ) : (
        <VStack spacing={2} align="stretch" py={2} width={"100%"}>
          {projects?.map((proj) => (
            <Box
              key={proj.id}
              // bg={"gray.50"}
              bg={selectedProjectId === proj.id ? "blue.50" : "gray.50"}
              boxShadow={selectedProjectId === proj.id ? "md" : "sm"}
              borderRadius="md"
              _hover={{
                bg: "blue.50",
                boxShadow: "md",
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
              onClick={() => handleProjectSelect(proj)}
              cursor="pointer"
              width={"100%"}
            >
              <Flex align="center" p={3} width={"100%"}>
                <FolderIcon size={18} className="mr-2 text-blue-500" />
                <Text fontSize="sm" fontWeight="medium" paddingLeft={2}>
                  {proj.projectName}
                </Text>
              </Flex>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default AllProjects;
