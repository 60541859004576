export const taskStatusStyle = {
  done: "#C8E6C9",
  inProgress: "#F9E79F",
  blocked: "#FFC2C7",
  toDo: "#F5F5F5",
  review: "#B3E5FC",
};

export const taskStatusText = {
  done: "Done",
  inProgress: "In Progress",
  blocked: "Blocked",
  toDo: "To Do",
  review: "Review",
};

export const taskPriorityStyles = {
  low: "🔵 Low",
  medium: "🟡 Medium",
  high: "🔴 High",
};
