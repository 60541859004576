import { useEffect, useRef, useState } from "react";
import { AuthState } from "../Context/AuthProvider";
import { ORG_BASE_URL as baseUrl } from "../baseurls";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useSetSelectedOrganization = (
  organizations,
  getUserOrganizationsData
) => {
  const { user, accessToken } = AuthState();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isUpdatingLogoUrlRef = useRef(false);
  const navigate = useNavigate();

  const roles = [
    { id: 0, name: "member", value: "Member" },
    { id: 1, name: "admin", value: "Admin" },
  ];

  const uploadOrganizationLogo = async (formData) => {
    try {
      if (!selectedOrganization) {
        return;
      }

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${baseUrl}/org/uploadlogo/${selectedOrganization?.id}`,
        formData,
        config
      );

      if (response.status !== 200) {
        throw new Error("Logo update failed.");
      }
      const url = response?.data?.file_path;
      if (!url) {
        throw new Error("recieved empty url string from server");
      }
      setSelectedOrganization((val) => {
        return { ...val, logoUrl: url };
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const getSignedLogoUrl = async () => {
    if (
      !selectedOrganization ||
      !selectedOrganization?.logoPath ||
      !isUpdatingLogoUrlRef.current
    ) {
      console.log(`Not Updating logo url.`);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/org/viewlogo/${selectedOrganization?.id}`,
        config
      );

      const url = response?.data?.url;
      console.log(url);

      if (url) {
        setSelectedOrganization((org) => {
          console.log({ ...org, logoUrl: url });

          return { ...org, logoUrl: url };
        });
      }
    } catch (err) {
      // console.log(err);
      // setError("Failed to get signed url for organization logo");
      // throw new Error("Failed to add members");
      console.log("Failed to get signed url for organization logo", err);
    }
  };

  // might not work for new organizations, for new organizations, you should set selected_organization in localStorage
  const selectOrganization = async (
    organizationId,
    navigateToDashboard = false
  ) => {
    let organizationToSelect = organizations?.find(
      (organization) => organization?.id === organizationId
    );

    if (!organizationToSelect) {
      // fetch latest organizations
      const organizationsData = await getUserOrganizationsData();
      organizationToSelect = organizationsData?.find(
        (organization) => organization?.id === organizationId
      );
    }

    let selectedOrganizationInLocalStorage;

    try {
      selectedOrganizationInLocalStorage = JSON.parse(
        localStorage.getItem("selected_organization")
      );
    } catch (error) {
      selectedOrganizationInLocalStorage = {};
      console.log(error);
    }

    localStorage.setItem(
      "selected_organization",
      JSON.stringify({
        ...selectedOrganizationInLocalStorage,
        [user?.firebaseUserId]: organizationId,
      })
    );

    setSelectedOrganization(organizationToSelect);
    if (navigateToDashboard) {
      // navigate to dashboard
      console.log(
        "Navigating to dashboard after accepting organization invite in usesetselectedorganization"
      );
      navigate("/dashboard");
    }
  };

  useEffect(() => {}, [selectedOrganization]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (!organizations || !Array.isArray(organizations)) {
      return;
    }

    const selectedOrganizationInLocalStorage = JSON.parse(
      localStorage.getItem("selected_organization")
    );

    const storedSelectedOrganizationId =
      selectedOrganizationInLocalStorage?.[user?.firebaseUserId];

    if (storedSelectedOrganizationId) {
      const organizationToSelect = organizations?.find(
        (org) => org.id === storedSelectedOrganizationId
      );

      if (organizationToSelect) {
        selectOrganization(organizationToSelect?.id);
        setLoading(false);
        return;
      }
    }

    if (
      user?.orgs &&
      Object.keys(user?.orgs)?.length > 0 &&
      organizations?.length > 0
    ) {
      selectOrganization(organizations[0]?.id);
      return;
    }
  }, [organizations]);

  return {
    selectedOrganization,
    setSelectedOrganization,
    roles,
    loading,
    error,
    setError,
    selectOrganization,
    uploadOrganizationLogo,
  };
};

export default useSetSelectedOrganization;
