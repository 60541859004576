import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Settings from "./Pages/Settings";
import Auth from "./Pages/Auth";
import Project from "./Pages/Project";
import Organization from "./Pages/Organization";
import Landing from "./Pages/Landing";
import NotFound from "./Pages/NotFound";
import Metrics from "./Pages/Metrics";
import Verification from "./Pages/Verification";
import Assistant from "./Pages/Assistant";
import MyProfile from "./Pages/Profile";
import Layout from "./Components/Layout/sideBarHeaderLayout";
import CreateOrganization from "./Pages/CreateOrganization";
import Task from "./Pages/Task";
import FloatingChatbot from "./Components/AssistantPageComponents/FloatingChatbot";
import Invite from "./Pages/Invite";

function App() {
  const location = useLocation();
  return (
    <div className="App" style={{ margin: 0, padding: 0, position: "fixed" }}>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/auth" element={<Auth />}></Route>
        <Route path="/invite/:inviteToken" element={<Invite />}></Route>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/projects/:projectId" element={<Project />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route
            path="/organizations/create"
            element={<CreateOrganization />}
          ></Route>
          <Route
            path="/organizations/:orgId"
            element={<Organization />}
          ></Route>
          <Route path="/assistant/:orgId" element={<Assistant />}></Route>
          <Route path="/profile/:userId" element={<MyProfile />}></Route>
          <Route path="/task/:taskId" element={<Task />}></Route>
        </Route>

        <Route path="/metrics" element={<Metrics />}></Route>
        <Route path="/verify" element={<Verification />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      {!(
        location.pathname.includes("/assistant") ||
        location.pathname.includes("/auth") ||
        location.pathname === "/"
      ) && <FloatingChatbot />}
    </div>
  );
}

export default App;
