import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Check } from "lucide-react";
import { taskStatusStyleLight } from "../MyTasks/styles";

const StatusDropDown = ({
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  status,
  setStatus,
  isParent,
  children,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const [focusIndex, setFocusIndex] = useState(-1);
  const menuRef = useRef(null);
  const toast = useToast();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const statusObjs = [
    { name: "inProgress", value: "In Progress", key: 1 },
    { name: "blocked", value: "Blocked", key: 2 },
    { name: "review", value: "Review", key: 3 },
    { name: "toDo", value: "To Do", key: 4 },
    { name: "done", value: "Done", key: 5 },
  ];

  const filteredOptions = statusObjs.filter((obj) => obj.name !== status);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusIndex((prev) => (prev + 1) % filteredOptions.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusIndex(
          (prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length
        );
        break;
      case "Enter":
        e.preventDefault();
        if (focusIndex >= 0) {
          handleStatusClick(e, filteredOptions[focusIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        onClose();
        break;
      default:
        break;
    }
  };

  const handleStatusClick = (e, obj) => {
    e.stopPropagation();
    if (isParent && obj.name === "done") {
      toast({
        position: "bottom-left",
        title: "Cannot change status of parent task.",
        status: "info",
        isClosable: true,
      });
    }
    console.log("Setting status to:", obj.name);
    setStatus(obj.name);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      menuRef.current?.focus();
      setFocusIndex(-1);
    }
  }, [isOpen]);

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        width="auto"
        boxShadow="xl"
        borderRadius="md"
        bg="white"
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        ref={menuRef}
      >
        <PopoverBody p={1}>
          <VStack spacing={1} align="stretch">
            {filteredOptions.map((obj, index) => (
              <Box
                key={obj.key}
                px={3}
                py={2}
                borderRadius="md"
                width={"117px"}
                bg={taskStatusStyleLight[obj.name]}
                color="black"
                cursor="pointer"
                transition="all 0.2s"
                _hover={{ opacity: 0.8 }}
                onClick={(e) => handleStatusClick(e, obj)}
                onMouseEnter={() => setFocusIndex(index)}
                outline={focusIndex === index ? "2px solid #C9A8FF" : "none"}
                outlineOffset={focusIndex === index ? "2px" : "0"}
                role="menuitem"
                position="relative"
              >
                <Text width={"100%"}>{obj.value}</Text>
                {status === obj.name && (
                  <Box
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                  >
                    <Check size={16} />
                  </Box>
                )}
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default StatusDropDown;
