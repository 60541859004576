import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { findName } from "../Miscellaneous/MyTasks/utils";
import { PriorityIcon } from "../Miscellaneous/Dropdowns/PriorityDropDown";
import { useEffect } from "react";

const TaskCard = ({ task, organizationMembers, onOpen, setViewingTask }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBgColor = useColorModeValue("gray.50", "gray.600");

  useEffect(() => {
    if (task?.parentId) {
      console.log(task);
    }
    if (task?.uid === "LUP-045") {
      console.log(task);
    }
    if (task?.subTasks?.length > 0) {
      console.log(task);
    }
  }, []);

  return (
    <Box
      p={2}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={
        task?.parentId
          ? "yellow.100"
          : task?.subTasks && task?.subTasks?.length > 0
          ? "blue.100"
          : bgColor
      }
      mb={2}
      width="100%"
      position="relative"
      borderColor={borderColor}
      _hover={{
        transform: "translateY(-2px)",
        transition: "all 0.2s",
        boxShadow: "lg",
      }}
      cursor="pointer"
      overflowX={"scroll"}
    >
      <VStack align="stretch" spacing={1}>
        <Box
          onClick={() => {
            setViewingTask({ ...task });
            onOpen();
          }}
        >
          <Text
            fontSize="md"
            lineHeight={1.2}
            noOfLines={3}
            overflowX={"scroll"}
            mb={3}
            maxW={"180px"}
          >
            {task.title}
          </Text>

          <Tooltip label="Milestone" placement="top">
            <Text
              ml={1}
              fontSize="xs"
              color="gray.500"
              fontWeight="medium"
              maxW={"150px"}
              isTruncated
            >
              {task.milestone.title}
            </Text>
          </Tooltip>

          <Flex justify="space-between" align="center">
            <Box flex={1} display={"flex"} alignItems={"center"}>
              <Tooltip label="Task ID" placement="top">
                <Badge fontSize="xs" px={2} py={1} borderRadius="full">
                  {task?.task_uid ? task.task_uid : task.id.slice(-5)}
                </Badge>
              </Tooltip>
            </Box>

            <Flex gap={1} align="center">
              <Tooltip label="Priority" placement="top">
                <Badge style={{ all: "unset" }} p={2}>
                  <PriorityIcon priority={task.priority} />
                </Badge>
              </Tooltip>

              <Tooltip
                label={`Duration: ${task.duration} hour`}
                placement="top"
              >
                <Flex
                  ml={3}
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  color="gray.600"
                  borderRadius="full"
                  px={2}
                  py={1}
                  minWidth="32px"
                >
                  <Text fontSize="xs" fontWeight="medium">
                    {task.duration}
                  </Text>
                </Flex>
              </Tooltip>

              <Tooltip label="Assignee" placement="top">
                <Avatar
                  name={findName(task.assigneeId, organizationMembers)}
                  size={"xs"}
                  src={
                    organizationMembers?.find(
                      (member) => member.firebaseUserId === task.assigneeId
                    )?.imageUrl
                  }
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default TaskCard;
