import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useDisclosure,
  Heading,
  Tooltip,
  Badge,
  useColorModeValue,
  Flex,
  Avatar,
  Input,
  InputGroup,
  InputLeftElement,
  AvatarGroup,
  Icon,
  HStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Scrollbar } from "react-scrollbars-custom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AddIcon,
  CheckCircleIcon,
  Search2Icon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import CreateMyTaskModal from "../Miscellaneous/MyTasks/CreateMyTaskModal";
import { UserState } from "../../Context/UserProvider";
import { AuthState } from "../../Context/AuthProvider";
import { findName } from "../Miscellaneous/MyTasks/utils";
import ViewTaskModal from "../Miscellaneous/MyTasks/ViewTaskModal";
import { PriorityIcon } from "../Miscellaneous/Dropdowns/PriorityDropDown";
import { LayoutState } from "../../Context/LayoutProvider";
import { CheckIcon, Expand, Minimize } from "lucide-react";

const IconBox = ({ icon: Icon, onClick, label }) => (
  <Box
    as={Icon}
    size="20px"
    cursor="pointer"
    _hover={{ color: "gray.700", bg: "gray.200", transform: "scale(1.1)" }}
    transition="all 0.2s"
    borderRadius="full"
    onClick={onClick}
    aria-label={label}
  />
);

const statuses = [
  { value: "To Do", name: "toDo", color: "gray.100" },
  { value: "In Progress", name: "inProgress", color: "blue.100" },
  { value: "Review", name: "review", color: "yellow.100" },
  { value: "Done", name: "done", color: "green.100" },
  { value: "Blocked", name: "blocked", color: "red.100" },
];

const TaskCard = ({ task, organizationMembers, onOpen, setViewingTask }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBgColor = useColorModeValue("gray.50", "gray.600");

  return (
    <Box
      p={2}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      mb={2}
      width="100%"
      position="relative"
      borderColor={borderColor}
      _hover={{
        bg: hoverBgColor,
        transform: "translateY(-2px)",
        transition: "all 0.2s",
        boxShadow: "lg",
      }}
      cursor="pointer"
      overflowX={"scroll"}
    >
      <VStack align="stretch" spacing={1}>
        <Box
          onClick={() => {
            setViewingTask({ ...task });
            onOpen();
          }}
        >
          <Text
            fontSize="md"
            lineHeight={1.2}
            noOfLines={3}
            overflowX={"scroll"}
            mb={3}
            maxW={"180px"}
          >
            {task.title}
          </Text>

          {/*       labels section     */}
          {task.labels && Object.keys(task.labels).length > 0 && (
            <Box my={2} w="full">
              {/* <Text fontSize="sm" fontWeight="bold" mb={2}>
                Labels
                </Text> */}
              <Wrap spacing={2}>
                {Object.entries(task.labels).map(([key, value], index) => (
                  <WrapItem key={index}>
                    {key && (
                      <HStack
                        borderRadius="md"
                        bg={`hsl(${(index * 40) % 360}, 70%, 90%)`}
                        align="center"
                        boxShadow="sm"
                      >
                        <Text fontSize="xs" fontWeight="medium">
                          {key.slice(0, 3)}
                        </Text>
                        <Text fontSize="xs" color="gray.600">
                          {value.slice(0, 3)}
                        </Text>
                      </HStack>
                    )}
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          )}
          {/*  */}

          <Tooltip label="Milestone" placement="top">
            <Text
              ml={1}
              fontSize="xs"
              color="gray.500"
              fontWeight="medium"
              maxW={"150px"}
              isTruncated
            >
              {task.milestone.title}
            </Text>
          </Tooltip>

          <Flex justify="space-between" align="center">
            <Box flex={1} display={"flex"} alignItems={"center"}>
              <Tooltip label="Task ID" placement="top">
                <Badge fontSize="xs" px={2} py={1} borderRadius="full">
                  {task?.task_uid ? task.task_uid : task.id.slice(-5)}
                </Badge>
              </Tooltip>
            </Box>

            <Flex gap={1} align="center">
              <Tooltip label="Priority" placement="top">
                <Badge style={{ all: "unset" }} p={2}>
                  <PriorityIcon priority={task.priority} />
                </Badge>
              </Tooltip>

              <Tooltip
                label={`Duration: ${task.duration} hour`}
                placement="top"
              >
                <Flex
                  ml={3}
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  color="gray.600"
                  borderRadius="full"
                  px={2}
                  py={1}
                  minWidth="32px"
                >
                  <Text fontSize="xs" fontWeight="medium">
                    {task.duration}
                  </Text>
                </Flex>
              </Tooltip>

              <Tooltip label="Assignee" placement="top">
                <Avatar
                  name={findName(task.assigneeId, organizationMembers)}
                  size={"xs"}
                  src={
                    organizationMembers?.find(
                      (member) => member.firebaseUserId === task.assigneeId
                    )?.imageUrl
                  }
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default function Board({
  assignees,
  viewingProject,
  isFullscreen,
  setIsFullscreen,
}) {
  const [statusColumnHeight, setStatusColumnHeight] = useState(
    isFullscreen ? `calc(100vh - 136)` : `calc(100vh - 300)`
  );
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [viewingTask, setViewingTask] = useState(null);
  const [filters, setFilters] = useState({
    taskQuery: "",
    assigneeIds: [],
  });
  const [sprintInsights, setSprintInsights] = useState({
    taskPercentages: {
      done: 0,
      inProgress: 0,
      toDo: 0,
    },
    totalTasks: 0,
    totalHours: 0,
  });

  const [activeSprint, setActiveSprint] = useState(null);
  const [sprintData, setSprintData] = useState(null);

  const {
    isOpen: isCreateTaskModalOpen,
    onOpen: openCreateTaskModal,
    onClose: closeCreateTaskModal,
  } = useDisclosure();
  const {
    isOpen: isViewTaskModalOpen,
    onOpen: openViewTaskModal,
    onClose: closeViewTaskModal,
  } = useDisclosure();
  const {
    organizationMembers,
    getProjectActiveSprintTasks,
    updateTaskStatus,
    stopSprint,
    getSprints,
  } = UserState();
  const { user } = AuthState();
  const { screenWidth, isPhone } = LayoutState();

  const calculateSprintInsights = () => {
    // accommodate filters with assigneeIds
    const filteredTasks = tasks?.filter(
      (task) =>
        filters.assigneeIds.length === 0 ||
        filters.assigneeIds.includes(task.assigneeId)
    );
    const totalTasks = filteredTasks?.length;
    if (totalTasks === 0) return { done: 0, inProgress: 0, toDo: 0 };

    const totalHours = filteredTasks?.reduce(
      (acc, task) => acc + task.duration,
      0
    );

    const taskStatusCounts = statuses?.reduce(
      (acc, status) => ({
        ...acc,
        [status.name]: filteredTasks?.filter(
          (task) => task.status === status.name
        ).length,
      }),
      {}
    );

    return {
      taskPercentages: {
        done: Math.round((taskStatusCounts.done / totalTasks) * 100),
        inProgress: Math.round(
          (taskStatusCounts.inProgress / totalTasks) * 100
        ),
        toDo: Math.round(
          ((taskStatusCounts.toDo +
            taskStatusCounts.review +
            taskStatusCounts.blocked) /
            totalTasks) *
            100
        ),
      },
      totalTasks,
      totalHours,
    };
  };

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const columnBgColor = useColorModeValue("white", "gray.800");

  const isAssigneeSelected = (assignee) => {
    return filters.assigneeIds.includes(assignee.firebaseUserId);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { destination, draggableId: taskId } = result;

    const newTasks = Array.from(filteredTasks);
    const taskIndex = newTasks.findIndex((task) => task.id === taskId);

    if (taskIndex === -1) return;

    const [movedTask] = newTasks.splice(taskIndex, 1);
    newTasks.splice(destination.index, 0, movedTask);

    const newStatus = statuses[parseInt(destination.droppableId)].name;
    const prevStatus = movedTask.status;
    movedTask.status = newStatus;

    try {
      if (newStatus !== prevStatus) {
        await updateTaskStatus(movedTask, newStatus, prevStatus);
        setFilteredTasks(newTasks);
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === movedTask.id ? { ...task, status: newStatus } : task
          )
        );
      }
    } catch (err) {
      console.error("Error updating task status:", err);
    }
  };

  const fetchSprintData = async () => {
    if (viewingProject?.id && viewingProject?.sprints?.activeSprint) {
      try {
        const sprints = await getSprints(viewingProject.id, [
          viewingProject.sprints.activeSprint,
        ]);
        setSprintData(sprints);
        // console.log("Fetched Sprint Data:", sprints); // Uncomment or keep for debugging
      } catch (error) {
        console.error("Error fetching sprint data:", error);
      }
    }
  };

  useEffect(() => {
    fetchSprintData();
  }, [viewingProject]);

  useEffect(() => {
    const fetchProjectActiveSprintTasks = async () => {
      if (
        !viewingProject?.sprints?.activeSprint ||
        viewingProject.sprints.activeSprint !== "None"
      ) {
        const activeSprintTasks = await getProjectActiveSprintTasks(
          viewingProject.id,
          viewingProject.sprints.activeSprint
        );
        setTasks(activeSprintTasks);
        setFilteredTasks(activeSprintTasks);
        setActiveSprint(viewingProject.sprints.activeSprint); // Update activeSprint state
        setActiveSprint(null); // Update activeSprint state
      }
    };

    if (viewingProject?.sprints?.activeSprint) {
      fetchProjectActiveSprintTasks();
    }
  }, [viewingProject, sprintData]);

  useEffect(() => {
    const topElement = document.getElementById("board-section-status-box");
    const topHeight =
      topElement.clientHeight + topElement.getBoundingClientRect().top;
    setStatusColumnHeight(`calc(100vh - ${topHeight}px + 10px)`);
  }, [isFullscreen]);

  useEffect(() => {
    const filtered = tasks?.filter((task) => {
      const matchesQuery =
        task?.title?.toLowerCase().includes(filters.taskQuery.toLowerCase()) ||
        task?.task_uid
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase()) ||
        task?.description
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase()) ||
        Object.entries(task.labels || {}).some(([key, value]) =>
          `${key} ${value}`
            .toLowerCase()
            .includes(filters.taskQuery.toLowerCase())
        );
      const matchesAssignee =
        filters.assigneeIds.length === 0 ||
        filters.assigneeIds.includes(task.assigneeId);

      return matchesQuery && matchesAssignee;
    });

    const calculatedSprintInsights = calculateSprintInsights();
    setSprintInsights((prev) => ({
      ...(prev || {}),
      taskPercentages: calculatedSprintInsights?.taskPercentages,
      totalTasks: calculatedSprintInsights?.totalTasks,
      totalHours: calculatedSprintInsights?.totalHours,
    }));

    setFilteredTasks(filtered);
  }, [filters, tasks]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        setIsFullscreen(true);
      } else if (event.key === "Escape") {
        setIsFullscreen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Box
      height={"100%"}
      width="100%"
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={1}
      pt={0}
      id="board-container-0"
    >
      {!isFullscreen && (
        <Box p={1} cursor={"pointer"} width={"100%"} pt={0}>
          <Box
            overflowX="auto"
            width="100%"
            sx={{
              "&::-webkit-scrollbar": {
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <HStack
              spacing={3}
              justifyContent="flex-end"
              overflowX="scroll"
              minWidth="min-content"
              width="100%"
              px={2}
              id="sprint-insights-container"
            >
              {sprintData?.length > 0 &&
                sprintData[0].name &&
                sprintData[0].num && (
                  <Tooltip
                    aria-label="Active Sprint"
                    hasArrow
                    bg="gray.700"
                    color="white"
                    padding="2"
                    borderRadius="md"
                    fontSize="sm"
                  >
                    <Flex align="center">
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color="black"
                        whiteSpace="nowrap"
                      >
                        {sprintData[0].name} #{sprintData[0].num} •
                      </Text>
                    </Flex>
                  </Tooltip>
                )}

              {sprintData?.length > 0 && sprintData[0].goal && (
                <Tooltip
                  aria-label="Sprint Goal"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="black"
                      whiteSpace="nowrap"
                    >
                      {sprintData[0].goal} •
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {sprintData?.length > 0 && (
                <Tooltip
                  aria-label="Sprint Status"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      whiteSpace="nowrap"
                      color={(() => {
                        if (
                          !sprintData[0]?.startDate ||
                          sprintData[0].startDate === "None"
                        ) {
                          return "black";
                        }
                        const start = new Date(sprintData[0].startDate);
                        const today = new Date();
                        start.setHours(0, 0, 0, 0);
                        today.setHours(0, 0, 0, 0);

                        const daysFromStart = Math.ceil(
                          (today - start) / (1000 * 60 * 60 * 24)
                        );
                        const remainingDays =
                          sprintData[0].duration - daysFromStart;

                        if (remainingDays <= 0) return "red.500";
                        if (remainingDays <= 2) return "orange.500";
                        return "black";
                      })()}
                    >
                      {!sprintData[0]?.startDate ||
                      sprintData[0].startDate === "None"
                        ? "Start date not set"
                        : (() => {
                            const start = new Date(sprintData[0].startDate);
                            const today = new Date();
                            start.setHours(0, 0, 0, 0);
                            today.setHours(0, 0, 0, 0);

                            const daysFromStart = Math.ceil(
                              (today - start) / (1000 * 60 * 60 * 24)
                            );
                            const remainingDays =
                              sprintData[0].duration - daysFromStart;

                            if (remainingDays <= 0) return "Sprint ended •";
                            if (remainingDays === 0) return "Last day •";
                            if (remainingDays === 1) return "1 day left •";
                            return `${remainingDays} days left •`;
                          })()}
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {!sprintData?.length && (
                <Text fontSize="sm" fontWeight="bold" color="red.500">
                  No Active Sprint •
                </Text>
              )}

              {(sprintInsights?.totalTasks ||
                sprintInsights?.totalTasks === 0) && (
                <Tooltip
                  label="Total Tasks"
                  aria-label="Total Tasks"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="black"
                      whiteSpace="nowrap"
                    >
                      {sprintInsights.totalTasks} {isPhone ? "" : "tasks"} •
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {(sprintInsights?.totalHours ||
                sprintInsights?.totalHours === 0) && (
                <Tooltip
                  label="Total Hours"
                  aria-label="Total Hours"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="black"
                      whiteSpace="nowrap"
                    >
                      {sprintInsights.totalHours} {isPhone ? "h" : "hours"} •
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {(sprintInsights?.taskPercentages?.done ||
                sprintInsights?.taskPercentages?.done === 0) && (
                <Tooltip
                  label="Completed Tasks"
                  aria-label="Completed Tasks"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Icon as={CheckCircleIcon} color="green.500" mr={1} />
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="green.500"
                      whiteSpace="nowrap"
                    >
                      {sprintInsights.taskPercentages.done}%
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {(sprintInsights?.taskPercentages?.inProgress ||
                sprintInsights?.taskPercentages?.inProgress === 0) && (
                <Tooltip
                  label="Tasks In Progress"
                  aria-label="Tasks In Progress"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Icon as={TimeIcon} color="blue.500" mr={1} />
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="blue.500"
                      whiteSpace="nowrap"
                    >
                      {sprintInsights.taskPercentages.inProgress}%
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {(sprintInsights?.taskPercentages?.toDo ||
                sprintInsights?.taskPercentages?.toDo === 0) && (
                <Tooltip
                  label="Tasks Yet to Start"
                  aria-label="Tasks Yet to Start"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <Icon as={WarningIcon} color="red.500" mr={1} />
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="red.500"
                      whiteSpace="nowrap"
                    >
                      {sprintInsights.taskPercentages.toDo}%
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {!isFullscreen && (
                <Tooltip
                  label="Toggle Fullscreen"
                  aria-label="Toggle Fullscreen"
                  hasArrow
                  bg="gray.700"
                  color="white"
                  padding="2"
                  borderRadius="md"
                  fontSize="sm"
                >
                  <Flex align="center">
                    <IconBox
                      icon={Expand}
                      onClick={() => setIsFullscreen(true)}
                      label="Enter Fullscreen"
                    />
                  </Flex>
                </Tooltip>
              )}
            </HStack>
          </Box>
        </Box>
      )}

      <Flex
        align="center"
        justifyContent="end"
        mx={screenWidth < 700 && 4}
        mb={1}
        flexDir={"row"}
        width={"100%"}
      >
        {viewingProject?.sprints?.activeSprint && (
          <Button
            size="sm"
            ml={2}
            p={1}
            onClick={() => {
              const undoneTasks = tasks.filter((t) => t.status !== "done");
              const undoneTaskIds = undoneTasks.map((t) => t.id);
              stopSprint(
                viewingProject.id,
                viewingProject.sprints.activeSprint,
                undoneTaskIds
              );
            }}
            colorScheme="green"
          >
            Complete Sprint
          </Button>
        )}

        <Flex
          gap={4}
          flex={1}
          alignItems="flex-start"
          justifyContent={"end"}
          overflowX={"scroll"}
        >
          {isFullscreen && (
            <Tooltip
              label="Project Name and Active Sprint"
              placement="top"
              hasArrow
              bg="gray.700"
              color="white"
              padding="2"
              borderRadius="md"
              fontSize="sm"
            >
              <Text
                cursor={"pointer"}
                textAlign={"center"}
                fontSize={"lg"}
                fontWeight={700}
                boxShadow={"md"}
                p={1}
                bgColor={"gray.50"}
                borderRadius={"md"}
                fw={500}
                textOverflow="ellipsis"
              >
                {viewingProject?.projectName} {sprintData[0].name || null} #
                {sprintData[0].num || null}{" "}
              </Text>
            </Tooltip>
          )}
          <Box maxW="420px" overflowX="auto" _hover={{ cursor: "pointer" }}>
            <AvatarGroup size="sm" _hover={{ cursor: "pointer" }}>
              {assignees.map((assignee, index) => (
                <Tooltip
                  borderRadius={"full"}
                  key={index}
                  label={`${assignee?.firstName?.toUpperCase()} ${assignee?.lastName?.toUpperCase()}`}
                  placement="bottom"
                >
                  <Box position={"relative"} display={"inline-block"}>
                    <Avatar
                      key={index}
                      sx={{ height: "2.5rem", width: "2.5rem" }}
                      name={`${assignee?.firstName} ${assignee?.lastName}`}
                      src={assignee?.imageUrl || ""}
                      title={`${assignee?.firstName} ${assignee?.lastName}`}
                      onClick={() => {
                        setFilters((prev) => {
                          const newAssigneeIds = prev.assigneeIds.includes(
                            assignee?.firebaseUserId
                          )
                            ? prev.assigneeIds.filter(
                                (firebaseUserId) =>
                                  firebaseUserId !== assignee?.firebaseUserId
                              )
                            : [...prev.assigneeIds, assignee?.firebaseUserId];

                          return { ...prev, assigneeIds: newAssigneeIds };
                        });
                      }}
                    />
                    {isAssigneeSelected(assignee) && (
                      <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        bg="green.500"
                        borderRadius="full"
                        width="14px"
                        height="14px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CheckIcon color="white" boxSize="8px" />
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
          <InputGroup width={screenWidth < 850 ? "200px" : "350px"}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search tasks"
              value={filters.taskQuery}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, taskQuery: e.target.value }))
              }
            />
          </InputGroup>
        </Flex>
        <Button
          colorScheme="blue"
          leftIcon={<AddIcon />}
          onClick={openCreateTaskModal}
          ml={2}
          minW={"80px"}
        >
          Task
        </Button>
        {isFullscreen && (
          <Box mx={3}>
            <Tooltip
              label="Exit Fullscreen"
              aria-label="Exit Fullscreen"
              m={1}
              hasArrow
              bg="gray.700"
              color="white"
              padding="2"
              borderRadius="md"
              fontSize="sm"
            >
              <Box>
                <IconBox
                  icon={Minimize}
                  onClick={() => setIsFullscreen(false)}
                  label="Exit Fullscreen"
                  fontSize="lg"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box width="100%" mx={2} overflowX={"scroll"}>
          <Flex
            alignItems="flex-start"
            justifyContent={screenWidth > 1600 && "center"}
            align={"center"}
            minWidth="100%"
            pb={2}
          >
            {statuses.map((status, columnIndex) => (
              <Droppable key={status.name} droppableId={columnIndex.toString()}>
                {(provided, snapshot) => (
                  <VStack
                    minWidth="270px"
                    height={statusColumnHeight}
                    borderWidth="1px"
                    borderColor={borderColor}
                    bg={bgColor}
                    p={2}
                    pb={0}
                    borderRadius="md"
                    borderBottomRadius={"0"}
                    boxShadow="md"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    flex={1}
                    mx={"8px"}
                    transition="background-color 0.2s"
                    bgGradient={
                      snapshot.isDraggingOver
                        ? `linear(to-b, ${status.color}, ${columnBgColor})`
                        : undefined
                    }
                  >
                    <Heading
                      size="sm"
                      fontSize={"1em"}
                      mb={2}
                      px={2}
                      color="#4a2a5a"
                      textAlign={"left"}
                      width={"100%"}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box id="board-section-status-box">
                        {`${status.value.toUpperCase()} (${
                          filteredTasks?.filter(
                            (task) => task.status === status.name
                          ).length ?? 0
                        })`}
                      </Box>
                      <Box mr={2}>
                        {filteredTasks
                          ?.filter((task) => task.status === status.name)
                          ?.reduce((total, task) => total + task.duration, 0) >
                          0 &&
                          `${filteredTasks
                            ?.filter((task) => task.status === status.name)
                            ?.reduce(
                              (total, task) => total + task.duration,
                              0
                            )}h`}
                      </Box>
                    </Heading>

                    <Box
                      position="relative"
                      height="calc(100% - 40px)"
                      width="100%"
                    >
                      <Scrollbar
                        style={{
                          width: "100%",
                          height: "calc(100% - 14px)",
                          paddingRight: "0px",
                        }}
                        noScrollX={false}
                        noScrollY={false}
                        trackYProps={{ style: { width: "6px" } }}
                      >
                        <Box pr={2}>
                          {filteredTasks
                            ?.filter((task) => task.status === status.name)
                            ?.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    opacity={snapshot.isDragging ? 0.8 : 1}
                                    transform={
                                      snapshot.isDragging ? "rotate(3deg)" : ""
                                    }
                                    transition="all 0.2s"
                                  >
                                    <TaskCard
                                      task={task}
                                      organizationMembers={organizationMembers}
                                      setViewingTask={setViewingTask}
                                      onOpen={openViewTaskModal}
                                      user={user}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </Box>
                        <Box height={"200px"} />
                      </Scrollbar>
                    </Box>
                  </VStack>
                )}
              </Droppable>
            ))}
          </Flex>
        </Box>
      </DragDropContext>
      {isCreateTaskModalOpen && (
        <CreateMyTaskModal
          isOpen={isCreateTaskModalOpen}
          onClose={closeCreateTaskModal}
          setAllTasks={setTasks}
          allTasks={tasks}
          project={viewingProject}
          providedSprintId={viewingProject?.sprints?.activeSprint}
        />
      )}
      {isViewTaskModalOpen && (
        <ViewTaskModal
          task={viewingTask}
          setTask={setViewingTask}
          isOpen={isViewTaskModalOpen}
          onClose={() => {
            setViewingTask(null);
            closeViewTaskModal();
          }}
          allTasks={tasks}
          setAllTasks={setTasks}
        />
      )}
    </Box>
  );
}
