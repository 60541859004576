"use client";

import {
  Box,
  VStack,
  Divider,
  Image,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Projects from "./Projects/Projects";
import Settings from "./Settings";
import Logout from "./Logout";
import { LayoutState } from "../../../Context/LayoutProvider";
import Organization from "../Organization/Organization";
import logo from "../../../assets/lumiproject-logo.png";
import Dashboard from "./DashboardItem";
import Assistant from "./AssistantItem";
import { ChevronsUp } from "lucide-react";
import { useNavigate } from "react-router-dom";

const SideBarContent = () => {
  const { isPhone, isSidebarOpen, openSidebar, closeSidebar } = LayoutState();
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const textColor = useColorModeValue("purple.600", "purple.200");

  useEffect(() => {
    if (clicked) {
      setClicked(false);
      isPhone && isSidebarOpen && closeSidebar();
    }
  }, [clicked]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      id="sidebarcontent-container"
    >
      <Flex
        bgColor={"gray.50"}
        p={1}
        align="center"
        boxShadow="sm"
        gap={2}
        cursor={"pointer"}
        pr={0}
        justify={"space-between"}
      >
        <Flex
          gap={"2px"}
          align="center"
          cursor={"pointer"}
          onClick={() => navigate("/")}
        >
          <Image src={logo} alt="lumiproject" height="48px" />
          <Box pt={1} display={"flex"}>
            {/* <Text
              fontWeight="bold"
              fontSize="1.5rem"
              color={textColor}
              textAlign={"center"}
              height={"100%"}
            >
              lumiproject
            </Text> */}
            <Text
              fontWeight="bold"
              fontSize="1.5rem"
              color={"purple.400"}
              textAlign={"center"}
              height={"100%"}
            >
              lumi
            </Text>
            <Text
              fontWeight="bold"
              fontSize="1.5rem"
              color={"blue.300"}
              textAlign={"center"}
              height={"100%"}
            >
              project
            </Text>
          </Box>
        </Flex>
        {isSidebarOpen && isPhone && (
          <Flex
            bg="gray.200"
            borderRadius="full"
            p={1}
            boxShadow="md"
            cursor="pointer"
            onClick={isSidebarOpen ? closeSidebar : openSidebar}
          >
            <ChevronsUp
              style={{
                transform: `rotate(${!isSidebarOpen ? "90deg" : "270deg"})`,
              }}
              size={"2rem"}
            />
          </Flex>
        )}
      </Flex>
      <VStack
        spacing={3}
        align="stretch"
        flex={1}
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
        pt={4}
        pb={1}
        px={3}
      >
        {isPhone && isSidebarOpen && <Organization setClicked={setClicked} />}
        <Dashboard setClicked={setClicked} />
        <Assistant setClicked={setClicked} />
        <Projects setClicked={setClicked} />
        {/* <Status /> */}
      </VStack>
      <Box mt={2} bgColor={!isSidebarOpen && "gray.200"}>
        <Divider mb={4} />
        <VStack spacing={3} align={"stretch"}>
          <Settings />
          <Logout />
        </VStack>
      </Box>
    </Box>
  );
};

export default SideBarContent;
