import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";
import CreateNewOrganizationModal from "./CreateNewOrganizationModal";

export default function Organization() {
  const { isPhone, screenWidth, isSidebarOpen, closeSidebar } = LayoutState();
  const { organizations, user, selectedOrganization, selectOrganization } =
    UserState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dropDownOrgs, setDropDownOrgs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const menuItemHoverBg = useColorModeValue("blue.50", "blue.900");
  const createOrgBg = useColorModeValue("blue.500", "blue.200");
  const createOrgColor = useColorModeValue("white", "gray.800");
  const createOrgHoverBg = useColorModeValue("blue.600", "blue.300");

  const handleOrganizationClick = () => {
    if (selectedOrganization?.members[user.firebaseUserId] === "Admin") {
      navigate(`/organizations/${selectedOrganization?.id}`);
      isPhone && closeSidebar();
    } else {
      toast({
        title: "You are not authorized to view this page.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const orgs = organizations?.filter(
      (org) => org?.id !== selectedOrganization?.id
    );
    setDropDownOrgs([selectedOrganization, ...orgs]);
  }, [selectedOrganization, organizations]);

  return (
    organizations &&
    organizations?.length > 0 && (
      <>
        <CreateNewOrganizationModal isOpen={isOpen} onClose={onClose} />
        <Box
          bg={bgColor}
          borderRadius="md"
          boxShadow="sm"
          border="1px solid"
          borderColor={borderColor}
          transition="all 0.3s"
          _hover={{ boxShadow: "md" }}
          width={isPhone ? "100%" : "auto"}
        >
          <Flex alignItems="center">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="ghost"
                size="sm"
                fontWeight="normal"
                _hover={{ bg: hoverBgColor }}
                _active={{ bg: hoverBgColor }}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth={isPhone ? "80px" : "100px"}
              >
                {screenWidth < 780 && isSidebarOpen && "Orgs"}
              </MenuButton>
              <MenuList maxHeight="300px" overflowY="auto">
                {dropDownOrgs?.map((org) => (
                  <MenuItem
                    key={org.id}
                    onClick={() => {
                      if (selectedOrganization?.id === org.id) {
                        navigate(`/organizations/${org.id}`);
                      }
                      selectOrganization(org.id);
                      if (location.pathname.includes("organizations")) {
                        navigate(`/organizations/${org.id}`);
                      }
                      onClose();
                    }}
                    _hover={{ bg: menuItemHoverBg }}
                    p={3}
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    bgColor={
                      selectedOrganization?.id === org.id && menuItemHoverBg
                    }
                    _last={{ borderBottom: "none" }}
                  >
                    <Flex alignItems="center" gap={2}>
                      <Avatar size="xs" name={org.name} src={org?.logoUrl} />
                      <Text isTruncated>{org.name}</Text>
                    </Flex>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    navigate("/organizations/create");
                  }}
                  width={"100%"}
                  py={2}
                  px={3}
                >
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    size="sm"
                    width="100%"
                    bgColor={createOrgBg}
                    color={createOrgColor}
                    _hover={{ bgColor: createOrgHoverBg }}
                    transition="background-color 0.2s"
                  >
                    Create Organization
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
            <Box
              flex={1}
              py={2}
              px={3}
              onClick={handleOrganizationClick}
              _hover={{ bg: hoverBgColor, cursor: "pointer" }}
              transition="background-color 0.2s"
              width={"100%"}
              overflow={"scroll"}
            >
              <Flex alignItems="center" gap={2} overflowX={"hidden"}>
                <Avatar
                  size="xs"
                  name={selectedOrganization?.name}
                  src={selectedOrganization?.logoUrl}
                />
                <Text
                  fontSize={isPhone ? "sm" : "md"}
                  overflowX={"scroll"}
                  fontWeight="medium"
                  whiteSpace={"nowrap"}
                >
                  {selectedOrganization?.name}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </>
    )
  );
}
