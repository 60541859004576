import { Box, IconButton } from "@chakra-ui/react";
import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import NotificationsPopOver from "./NotificationsPopOver";
import { ServiceState } from "../../Context/ServiceProvider";

const Notifications = () => {
  const { unreadCount } = ServiceState();

  return (
    <NotificationsPopOver>
      <Box position="relative">
        <IconButton
          icon={<IoMdNotificationsOutline size={"1.3rem"} />}
          aria-label="Notifications"
          bgColor={"inherit"}
          size={"1rem"}
        />
        {unreadCount > 0 && (
          <Box
            position="absolute"
            top="0"
            right="0"
            bg="red.500"
            borderRadius="full"
            w="2"
            h="2"
            transform="translate(25%, -25%)"
          />
        )}
      </Box>
    </NotificationsPopOver>
  );
};

export default Notifications;
