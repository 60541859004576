import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  useColorModeValue,
  Flex,
  Badge,
  Avatar,
  AvatarGroup,
  Icon,
  SimpleGrid,
  Divider,
  Tooltip,
  Button,
  Switch,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {
  InfoIcon,
  CalendarIcon,
  CheckCircleIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { FaUsers } from "react-icons/fa";

import { UserState } from "../../../../Context/UserProvider";
import { EditIcon } from "lucide-react";
import useAssistant from "../../../../hooks/useAssistant";
import MarkdownRenderer from "../../../AssistantPageComponents/MarkdownRenderer";
import UpdateProjectModal from "./UpdateProjectModal";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";

const InfoCard = ({ title, value, icon }) => {
  const cardBg = useColorModeValue("white", "gray.700");
  const cardBorder = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="lg"
      p={4}
      shadow="md"
      transition="all 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
      flex={1}
    >
      <Flex align="center" mb={2}>
        <Icon as={icon} boxSize={6} mr={2} color="blue.500" />
        <Text fontWeight="bold" fontSize="lg">
          {title}
        </Text>
      </Flex>
      <Text fontSize="md">{value}</Text>
    </Box>
  );
};

const Overview = ({ project, projectMembers }) => {
  const { selectedOrganization, updateProjectManagement, getProjectReport } =
    UserState();

  const [loadingProjectReport, setLoadingProjectReport] = useState(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState(true);
  const [projectReport, setProjectReport] = useState("");
  const [isChangingManagement, setIsChangingManagement] = useState(false);
  const [newManagement, setNewManagement] = useState(
    project.management.toLowerCase()
  );
  const toast = useToast();
  const cancelRef = React.useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("gray.50", "gray.800");

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleManagementChange = (event) => {
    setNewManagement(event.target.checked ? "agile" : "kanban");
    setIsChangingManagement(true);
  };

  const confirmManagementChange = async () => {
    try {
      await updateProjectManagement(newManagement, project.id);
      toast({
        title: "Project management updated",
        description: `Management style changed to ${newManagement}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update project management",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsChangingManagement(false);
  };

  useEffect(() => {
    const fetchProjectReport = async () => {
      try {
        const currentReport = project?.report || "";
        if (currentReport) {
          setLoadingProjectReport(false);
          setProjectReport(currentReport);
        }

        if (isGeneratingReport) {
          await getProjectReport({
            projectId: project.id,
          });
          setIsGeneratingReport(false);
        }
      } catch (error) {
        console.error("Error fetching project report:", error);
        setLoadingProjectReport(false);
        setProjectReport("");
      }
    };

    fetchProjectReport();
  }, [project]);

  return (
    <Box
      borderRadius="lg"
      bg={bgColor}
      color={textColor}
      p={6}
      height="100%"
      width="100%"
    >
      <VStack align="stretch" spacing={6}>
        <Flex justify="space-between" align="center">
          <Box width={"100%"}>
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Heading size="lg" _hover={{ cursor: "pointer" }}>
                {project.projectName}
              </Heading>
              <Button
                leftIcon={<EditIcon size={"1em"} />}
                colorScheme="blue"
                size={"sm"}
                onClick={onOpen}
              >
                Edit Project
              </Button>
            </Flex>
            <Flex align="center" mt={2} gap={2}>
              <Tooltip label={`Project Acronym`} _hover={{ cursor: "pointer" }}>
                <Badge
                  colorScheme="purple"
                  fontSize="sm"
                  _hover={{ cursor: "pointer" }}
                >
                  {project.proAcronym}
                </Badge>
              </Tooltip>
              <Tooltip label={`Latest Task Sequence Number`}>
                <Badge
                  fontSize="sm"
                  color="gray.500"
                  _hover={{ cursor: "pointer" }}
                >
                  #{project.sequenceNumber}
                </Badge>
              </Tooltip>
              <Tooltip label={`Management Style`}>
                <Badge
                  fontSize="sm"
                  colorScheme="red"
                  _hover={{ cursor: "pointer" }}
                >
                  {project.management}
                </Badge>
              </Tooltip>
            </Flex>
          </Box>
        </Flex>

        <Flex align="center" mt={4}>
          <Text mr={2}>Kanban</Text>
          <Switch
            isChecked={newManagement === "agile"}
            onChange={handleManagementChange}
            colorScheme="blue"
          />
          <Text ml={2}>Agile</Text>
        </Flex>

        <Text>{project.description}</Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={3}>
          <InfoCard
            title="Created On"
            value={formatDate(project.createdAt)}
            icon={CalendarIcon}
          />
          {project?.management === "agile" && (
            <InfoCard
              title="Total Sprints"
              value={project.sprintNumber}
              icon={InfoIcon}
            />
          )}
        </SimpleGrid>

        <Box>
          <Heading size="md" mb={4}>
            Project Members {` (${project?.members?.length})`}
          </Heading>
          <AvatarGroup size="md" _hover={{ cursor: "pointer" }} max={8}>
            {projectMembers.map((member, index) => (
              <Tooltip
                key={index}
                label={`${member?.firstName} ${member?.lastName}`}
                placement="bottom"
              >
                <Avatar
                  key={index}
                  name={`${member?.firstName} ${member?.lastName}`}
                  src={member?.imageUrl || ""}
                  title={`${member?.firstName} ${member?.lastName}`}
                  onClick={() => {
                    navigate(`/profile/${member?.firebaseUserId}`);
                  }}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        </Box>

        <Divider />

        <Flex flexDir="column">
          {loadingProjectReport ? (
            <Flex
              justifyContent={"center"}
              width={"100%"}
              alignItems={"center"}
              gap={2}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text fontSize={"xl"}>Generating Project Report...</Text>
            </Flex>
          ) : (
            <MarkdownRenderer content={projectReport} />
          )}
        </Flex>

        <Divider />

        <Flex justify="space-between" align="center">
          <Text fontSize="sm" color="gray.500"></Text>
          <Tooltip label={`Organization`}>
            <Badge _hover={{ cursor: "pointer" }} bg={"transparent"}>
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Avatar
                  name={selectedOrganization?.name || ""}
                  src={selectedOrganization?.logoUrl || ""}
                  size="sm"
                />
                <Text colorScheme="blue" fontSize="3xl">
                  {selectedOrganization?.name || ""}
                </Text>
              </Box>
            </Badge>
          </Tooltip>
        </Flex>
      </VStack>

      {isOpen && (
        <UpdateProjectModal
          isOpen={isOpen}
          onClose={onClose}
          viewingProject={project}
        />
      )}

      <AlertDialog
        isOpen={isChangingManagement}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsChangingManagement(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={3}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Project Management Style
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to change the project management style to{" "}
              {newManagement}? This action may affect your project structure and
              workflow.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsChangingManagement(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={confirmManagementChange}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Overview;
