"use client";

import { Box, Button, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CreateProjectsModal from "./CreateProjectsModal";
import AllProjects from "./AllProjects";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { UserState } from "../../../../Context/UserProvider";
import { FoldersIcon } from "lucide-react";
import { LayoutState } from "../../../../Context/LayoutProvider";

const Projects = ({ setClicked }) => {
  const { selectedOrganization, user } = UserState();
  const { isSidebarOpen, openSidebar } = LayoutState();
  const [selectedProjectId, setSelectedProjectId] = useState();

  const {
    isOpen: isCreateProjectModalOpen,
    onOpen: openCreateProjectModal,
    onClose: closeCreateProjectModal,
  } = useDisclosure();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (selectedOrganization?.members[user?.firebaseUserId] === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [selectedOrganization, user]);

  return (
    <>
      {isSidebarOpen ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          maxHeight="350px"
          overflow="hidden"
          borderBottom={"1px solid"}
          borderColor={"gray.300"}
          borderRadius="md"
          bg="white"
        >
          <Accordion allowMultiple border="transparent">
            <AccordionItem border="none">
              <AccordionButton
                _hover={{ bg: "gray.50" }}
                _expanded={{ bg: "gray.50", fontWeight: "semibold" }}
                borderRadius="md"
                transition="all 0.2s"
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize="md"
                  alignItems={"center"}
                  width={"100%"}
                  gap={2}
                  display={"flex"}
                >
                  <FoldersIcon size={"1.3rem"} />
                  Projects
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={2} className="accordion-panel">
                <AllProjects
                  setClicked={setClicked}
                  selectedProjectId={selectedProjectId}
                  setSelectedProjectId={setSelectedProjectId}
                />
                {isAdmin && (
                  <Button
                    mt={2}
                    width={"100%"}
                    overflow={"hidden"}
                    padding={2}
                    marginRight={2}
                    size={"sm"}
                    colorScheme="blue"
                    leftIcon={<AddIcon />}
                    onClick={() => {
                      if (selectedOrganization?.isVerified) {
                        openCreateProjectModal();
                      }
                    }}
                  >
                    New Project
                  </Button>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <CreateProjectsModal
            isOpen={isCreateProjectModalOpen}
            onClose={closeCreateProjectModal}
          />
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"md"}
          bgColor={"gray.50"}
          boxShadow={"md"}
          p={1}
          cursor={"pointer"}
          onClick={() => {
            openSidebar();
          }}
        >
          <Tooltip label="Projects" aria-label="Projects">
            <FoldersIcon size={"1.5rem"} />
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default Projects;
