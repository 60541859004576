import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MyTasks from "../Miscellaneous/MyTasks/MyTasks";
import { LayoutState } from "../../Context/LayoutProvider";
import MyReports from "../Miscellaneous/MyReports/MyReports";

const Main = ({ myTasksRef, myReportsRef, metricsRef }) => {
  const { isPhone, isSidebarOpen, sidebarWidth, closeSidebar } = LayoutState();
  const [spacingFromTop, setSpacingFromTop] = useState(0);

  useEffect(() => {
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpacingFromTop(headerContainer.clientHeight);
  }, []);

  return (
    <Box
      overflowY={"auto"}
      flex={1}
      marginRight={0}
      marginLeft={sidebarWidth}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      display={"flex"}
      flexDir={"column"}
      gap={5}
      maxHeight={`calc(100vh - ${spacingFromTop + 10}px)`}
      id="dashboard-main-container"
    >
      <Box flex={1}>
        {isPhone && isSidebarOpen && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="1"
            bg="transparent"
            bgColor={"#0000002b"}
            onClick={closeSidebar}
          />
        )}
        {/* <Metrics metricsRef={metricsRef} /> */}
        <MyTasks taskRef={myTasksRef} isPhoneSideBarOpen={isSidebarOpen} />
        <MyReports
          reportRef={myReportsRef}
          isPhoneSideBarOpen={isSidebarOpen}
        />
      </Box>
    </Box>
  );
};

export default Main;
