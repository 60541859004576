import {
  Box,
  Heading,
  List,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => {
  // Pre-process the content to fix some formatting issues
  const processedContent = content
    .replace(/\\n/g, "\n") // Replace escaped newlines with actual newlines
    .replace(/\\\*/g, "*") // Replace escaped asterisks with actual asterisks
    .replace(/\\_/g, "_") // Replace escaped underscores with actual underscores
    .replace(/\\\n/g, "\n") // Replace escaped newlines with actual newlines
    .replace(/\\\*/g, "*") // Replace escaped asterisks with actual asterisks
    .replace(/\\_/g, "_") // Replace escaped underscores with actual underscores
    .replace(/\\\//g, "/") // Replace escaped slashes with actual slashes
    .replace(/\\'/g, "'") // Replace escaped single quotes with actual single quotes
    .replace(/\\\"/g, '"') // Replace escaped double quotes with actual double quotes
    .replace(/\\/g, ""); // Replace single backslash with bold formatting

  useEffect(() => {
    console.log("Updated Content: ", content);
    console.log("Processed Content: ", processedContent);
  }, [content]);

  return (
    <Box>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Heading
              as="h1"
              size="xl"
              mt={6}
              mb={4}
              {...(props ? props : {})}
            />
          ),
          h2: ({ node, ...props }) => (
            <Heading
              as="h2"
              size="lg"
              mt={5}
              mb={3}
              {...(props ? props : {})}
            />
          ),
          h3: ({ node, ...props }) => (
            <Heading
              as="h3"
              size="md"
              mt={4}
              mb={2}
              {...(props ? props : {})}
            />
          ),
          h4: ({ node, ...props }) => (
            <Heading
              as="h4"
              size="sm"
              mt={3}
              mb={2}
              {...(props ? props : {})}
            />
          ),
          p: ({ node, ...props }) => <Text mb={2} {...(props ? props : {})} />,
          ul: ({ node, children, ...props }) => (
            <List as={UnorderedList} mb={4} pl={4} spacing={2} {...props}>
              {children}
            </List>
          ),
          ol: ({ node, children, ...props }) => (
            <List as={OrderedList} mb={4} pl={4} spacing={2} {...props}>
              {children}
            </List>
          ),
          li: ({ node, ...props }) => <ListItem {...(props ? props : {})} />,
          strong: ({ node, ...props }) => (
            <Text as="strong" fontWeight="bold" {...(props ? props : {})} />
          ),
        }}
        skipHtml={false}
        remarkPlugins={[remarkGfm]}
        breaks={true}
      >
        {processedContent}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;
