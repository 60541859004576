import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Input,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";

const ViewSprintModal = ({
  projectId,
  isOpen,
  onClose,
  sprint = {},
  setSprint = () => {},
}) => {
  const { isPhone } = LayoutState();
  const { updateSprint } = UserState();
  const [fields, setFields] = useState({
    name: sprint?.name || "",
    goal: sprint?.goal || "",
    duration: sprint?.duration || 7,
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [id]: id === "duration" ? parseInt(value, 10) : value,
    }));
  };

  const handleUpdateSprint = async () => {
    if (
      fields.name === sprint.name &&
      fields.goal === sprint.goal &&
      fields.duration === sprint.duration
    ) {
      return;
    }

    try {
      setLoading(true);
      await updateSprint(
        projectId,
        sprint.id,
        fields.goal,
        fields.name,
        fields.duration
      );
      toast({
        title: "Sprint updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setSprint((currentSprint) => ({ ...currentSprint, ...fields }));
    } catch (error) {
      toast({
        title: "Error occurred",
        description: "Couldn't update sprint",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    handleUpdateSprint();
    onClose();
  };

  useEffect(() => {
    setFields({
      name: sprint.name,
      goal: sprint.goal,
      duration: sprint.duration,
    });
  }, [sprint]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxH="90vh"
        mx={3}
        boxShadow="xl"
        borderRadius="lg"
        overflow="hidden"
        bg="gray.100"
      >
        <ModalHeader p={4} pb={2}>
          <Input
            id="name"
            value={fields.name}
            onChange={handleInputChange}
            fontSize={isPhone ? "2xl" : "3xl"}
            fontWeight="500"
            border="none"
            bg="transparent"
            color="#4A2A5A"
            _focus={{ boxShadow: "none" }}
            placeholder="Sprint Name"
            _placeholder={{ color: "gray.400" }}
            autoFocus={false}
          />
        </ModalHeader>
        <ModalCloseButton cursor="pointer" zIndex={20} />
        <ModalBody pt={2} display="flex" flexDirection="column" px={4}>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel fontWeight="medium">Sprint Goal</FormLabel>
              <Input
                id="goal"
                value={fields.goal}
                onChange={handleInputChange}
                placeholder="Enter sprint goal"
                bg="white"
                borderColor="#a67db7"
                _hover={{ borderColor: "#4A2A5A" }}
                _focus={{
                  borderColor: "#4A2A5A",
                  boxShadow: "0 0 0 1px #4A2A5A",
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">
                Sprint Duration (in days)
              </FormLabel>
              <Input
                id="duration"
                value={fields.duration}
                onChange={handleInputChange}
                type="number"
                placeholder="Enter sprint duration"
                bg="white"
                borderColor="#a67db7"
                _hover={{ borderColor: "#4A2A5A" }}
                _focus={{
                  borderColor: "#4A2A5A",
                  boxShadow: "0 0 0 1px #4A2A5A",
                }}
              />
            </FormControl>
            <Text fontSize="sm" color="gray.600">
              Created: {new Date(sprint.createdAt).toLocaleDateString()}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#a67db7"
            color="white"
            _hover={{ bg: "#4A2A5A" }}
            onClick={handleClose}
            isLoading={loading}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewSprintModal;
