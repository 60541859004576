import React, { useState, useRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  List,
  ListItem,
} from "@chakra-ui/react";
import { IoIosSend, IoMdDocument } from "react-icons/io";
import { AuthState } from "../../../Context/AuthProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";
import { AttachmentIcon, SmallCloseIcon, SpinnerIcon } from "@chakra-ui/icons";

const ALLOWED_EXTENSIONS = ["png", "jpg", "jpeg", "gif", "pdf"];
const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB

const AddComment = ({ projectId, setTask }) => {
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [projectMembers, setProjectMembers] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const { screenWidth } = LayoutState();
  const { user } = AuthState();
  const { addTaskComment, uploadCommentAttachment, organizationMembers } =
    UserState();
  const attachmentRef = useRef(null);
  const inputRef = useRef(null);
  const toast = useToast();

  const onAttachmentIconClicked = () => {
    attachmentRef?.current?.click();
  };

  const removeAttachment = () => {
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
      toast({
        title: "Invalid file type.",
        description: `Allowed file types are: ${ALLOWED_EXTENSIONS.join(
          ", "
        )}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File is too large.",
        description: `Maximum file size is ${
          MAX_FILE_SIZE / (1024 * 1024)
        } MB.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSelectedFile(file);
  };

  const handleCommentSubmit = async () => {
    try {
      setLoading(true);
      let commentId;

      const uploadedComment = await addTaskComment(
        newComment,
        taggedUsers.map((user) => user.firebaseUserId)
      );
      commentId = uploadedComment.id;

      if (selectedFile) {
        await uploadCommentAttachment(selectedFile, commentId);
      }

      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask.commentsCount + 1,
      }));

      setNewComment("");
      setSelectedFile(null);
      setTaggedUsers([]);

      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask?.commentsCount + 1,
      }));

      setNewComment("");
      setSelectedFile(null);
      setTaggedUsers([]);
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleCommentSubmit();
    } else if (showSuggestions) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        const suggestionItems = document.querySelectorAll('[role="option"]');
        const currentIndex = Array.from(suggestionItems).findIndex(
          (item) => item === document.activeElement
        );
        let nextIndex;
        if (e.key === "ArrowDown") {
          nextIndex =
            currentIndex < suggestionItems.length - 1 ? currentIndex + 1 : 0;
        } else {
          nextIndex =
            currentIndex > 0 ? currentIndex - 1 : suggestionItems.length - 1;
        }
        suggestionItems[nextIndex].focus();
      } else if (
        e.key === "Enter" &&
        document.activeElement.getAttribute("role") === "option"
      ) {
        e.preventDefault();
        handleTagSelect(
          tagSuggestions[
            Array.from(document.querySelectorAll('[role="option"]')).indexOf(
              document.activeElement
            )
          ]
        );
      } else if (e.key === "Escape") {
        setShowSuggestions(false);
        inputRef.current.focus();
      }
    }
  };

  const handleTagInput = (e) => {
    const input = e.target.value;
    setNewComment(input);

    if (input.includes("@")) {
      const tagText = input.split("@").pop();
      if (tagText.length > 0) {
        const suggestions = projectMembers.filter((member) =>
          `${member.firstName} ${member.lastName}`
            .toLowerCase()
            .includes(tagText.toLowerCase())
        );
        setTagSuggestions(suggestions);
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleTagSelect = (member) => {
    const tagText = `@${member.firstName} ${member.lastName}`;
    setNewComment((prev) => prev.replace(/@\w*$/, tagText));
    setTaggedUsers((prev) => [...prev, member]);
    setShowSuggestions(false);
    inputRef.current.focus();
  };

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    // console.log(selectedFile)
  }, [selectedFile]);

  useEffect(() => {
    if (!organizationMembers) return;
    const members = organizationMembers?.filter((member) =>
      member.projectIds?.includes(projectId)
    );
    setProjectMembers(members);
  }, [organizationMembers]);

  return (
    <Box display="flex" alignItems="center">
      <Input
        ref={attachmentRef}
        type="file"
        accept={ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(",")}
        onChange={handleFileChange}
        display="none"
        id="attachment-upload"
      />
      <Avatar
        src={user.imageUrl}
        name={user.firstName + " " + user.lastName}
        size={screenWidth < 460 ? "sm" : "md"}
      />
      <Box
        width="100%"
        display="flex"
        flexDir="column"
        justifyContent="start"
        gap={0}
      >
        <Box
          display={selectedFile ? "block" : "none"}
          marginLeft="4px"
          bgColor="#eaeaea"
          padding={1}
          marginBottom={0}
          borderRadius="10px"
          borderBottomRadius={0}
          paddingLeft={2}
          width="90%"
        >
          {selectedFile &&
            (selectedFile.type.includes("image/") ? (
              <Box display="flex">
                <Image
                  src={URL.createObjectURL(selectedFile)}
                  maxHeight="50px"
                />
                <Box
                  height="100%"
                  flex={1}
                  display="flex"
                  justifyContent="end"
                  alignItems="start"
                >
                  <SmallCloseIcon
                    _hover={{ cursor: "pointer" }}
                    onClick={removeAttachment}
                  />
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <IoMdDocument />
                <Link href={URL.createObjectURL(selectedFile)}>
                  {selectedFile.name}
                </Link>
                <Box
                  height="100%"
                  flex={1}
                  display="flex"
                  justifyContent="end"
                  alignItems="start"
                >
                  <SmallCloseIcon
                    _hover={{ cursor: "pointer" }}
                    onClick={removeAttachment}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        <Box display="flex" flexDir="row" width="100%">
          <InputGroup padding={2} paddingTop={0} margin={0} marginTop={1}>
            <InputLeftElement
              display="flex"
              alignItems="center"
              height="100%"
              children={<AttachmentIcon />}
              justifyContent="center"
              marginLeft={2}
              _hover={{ cursor: "pointer" }}
              onClick={onAttachmentIconClicked}
            >
              <AttachmentIcon />
            </InputLeftElement>
            <Input
              ref={inputRef}
              bgColor="white"
              value={newComment}
              placeholder="Comment"
              border="2px solid grey"
              onChange={handleTagInput}
              onKeyDown={handleKeyDown}
            />
            {showSuggestions && (
              <Popover isOpen={showSuggestions} placement="bottom-start">
                <PopoverTrigger>
                  <Box />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody>
                    <List>
                      {tagSuggestions.map((member, index) => (
                        <ListItem
                          key={member.firebaseUserId}
                          onClick={() => handleTagSelect(member)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              handleTagSelect(member);
                            }
                          }}
                          tabIndex={0}
                          role="option"
                          aria-selected={index === 0}
                          _hover={{
                            cursor: "pointer",
                            backgroundColor: "#f0f0f0",
                          }}
                          _focus={{
                            outline: "2px solid blue",
                            backgroundColor: "#e0e0e0",
                          }}
                        >
                          {member.firstName} {member.lastName}
                        </ListItem>
                      ))}
                    </List>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </InputGroup>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            overflow="hidden"
            _hover={{ cursor: "pointer" }}
            onClick={handleCommentSubmit}
          >
            {!loading ? (
              <IoIosSend color="blue" fontSize="2rem" />
            ) : (
              <SpinnerIcon />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddComment;
