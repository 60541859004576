import React, { useEffect, useState, useRef } from "react";
import { Check } from "lucide-react";
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";

const MilestoneDropDown = ({
  projectId,
  milestone,
  setMilestone,
  task = null,
  forUpdation = false,
  isTriggerBgWhite = false,
  setTask = () => {},
}) => {
  const { getMilestonesByProject, updateTaskMilestone } = UserState();
  const [projectMilestones, setProjectMilestones] = useState([]);
  const [loadingMilestones, setLoadingMilestones] = useState(false);
  const [fetchMilestones, setFetchMilestones] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuRef = useRef(null);
  const itemsRef = useRef({});

  const changeMilestone = async (milestoneId) => {
    await updateTaskMilestone(task.id, milestoneId);
  };

  const handleMilestoneClick = async (e, milestoneObj) => {
    onClose();

    setMilestone({
      id: milestoneObj.id,
      title: milestoneObj.title,
    });

    setTask((prevTask) => ({
      ...prevTask,
      milestone: { id: milestoneObj.id, title: milestoneObj.title },
    }));

    if (forUpdation) {
      await changeMilestone(milestoneObj.id);
    }
  };

  const scrollIntoView = (index) => {
    const element = itemsRef.current[index];
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (!Array.isArray(projectMilestones) || projectMilestones.length === 0)
      return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        const nextIndex = (focusIndex + 1) % projectMilestones.length;
        setFocusIndex(nextIndex);
        scrollIntoView(nextIndex);
        break;
      case "ArrowUp":
        e.preventDefault();
        const prevIndex =
          (focusIndex - 1 + projectMilestones.length) % projectMilestones.length;
        setFocusIndex(prevIndex);
        scrollIntoView(prevIndex);
        break;
      case "Enter":
        e.preventDefault();
        if (focusIndex >= 0) {
          handleMilestoneClick(e, projectMilestones[focusIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        onClose();
        break;
    }
  };

  useEffect(() => {
    const getProjectMilestones = async () => {
      try {
        setLoadingMilestones(true);
        setProjectMilestones([]);
        const milestones = await getMilestonesByProject(projectId);
        if (milestone) {
          setProjectMilestones(milestones);
        }
      } catch (error) {
        console.error("error loading milestones", error);
      } finally {
        setLoadingMilestones(false);
      }
    };

    if (projectId && fetchMilestones) {
      getProjectMilestones();
      setFetchMilestones(false);
    } else {
      setProjectMilestones([]);
    }
  }, [projectId, fetchMilestones]);

  useEffect(() => {
    if (isOpen) {
      menuRef.current?.focus();
      setFocusIndex(-1);
    }
  }, [isOpen]);

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          w="full"
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          color={!milestone?.title ? "gray.500" : "black"}
          minWidth={"60px !important"}
          _hover={{ opacity: 0.8 }}
          fontWeight={"400"}
          overflow={"scroll"}
          px={3}
          isDisabled={!projectId}
          border={!projectId ? "1px solid gray" : "1px solid #e2e8f0"}
          onClick={(prev) => setFetchMilestones(true)}
        >
          {!projectId
            ? "Select a project first."
            : milestone?.title
            ? milestone.title
            : "Select Milestone"}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width={"100%"}
        borderRadius={"md"}
        boxShadow={"lg"}
        overflow={"hidden"}
        mx={2}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        ref={menuRef}
      >
        <PopoverBody
          p={0}
          pr={2}
          width={"100%"}
          maxH={"250px"}
          overflowY={"scroll"}
          overflowX={"hidden"}
        >
          {projectId ? (
            !loadingMilestones ? (
              Array.isArray(projectMilestones) &&
              projectMilestones.length > 0 ? (
                projectMilestones.map((obj, index) => (
                  <Box
                    key={obj.id}
                    ref={(el) => (itemsRef.current[index] = el)}
                    overflowX={"scroll"}
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    overflow={"scroll"}
                    bgColor={"gray.200"}
                    _hover={{
                      opacity: 0.8,
                      cursor: "pointer",
                    }}
                    textAlign={"left"}
                    m={"5px"}
                    mb={"2px"}
                    p={"5px"}
                    borderRadius={"md"}
                    onClick={(e) => handleMilestoneClick(e, obj)}
                    onMouseEnter={() => setFocusIndex(index)}
                    outline={focusIndex === index ? "2px solid #C9A8FF" : "none"}
                    outlineOffset={focusIndex === index ? "2px" : "0"}
                    role="menuitem"
                    position="relative"
                  >
                    <Text fontSize={"sm"} px={3} py={1} textAlign={"left"}>
                      {obj.title}
                    </Text>
                    {milestone?.id === obj.id && (
                      <Box
                        position="absolute"
                        right={2}
                        top="50%"
                        transform="translateY(-50%)"
                      >
                        <Check size={16} />
                      </Box>
                    )}
                  </Box>
                ))
              ) : (
                <Box>No Milestone Found</Box>
              )
            ) : (
              <Spinner size={"md"} />
            )
          ) : (
            <Box bgColor={"gray.100"} p={4}>
              No Project Selected
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default MilestoneDropDown;