import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Textarea,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Box,
  useToast,
  FormHelperText,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import StatusDropDown from "../Dropdowns/StatusDropDown";
import PriorityDropDown, { PriorityIcon } from "../Dropdowns/PriorityDropDown";
import { findIndexOfTask } from "../../../utils/functions";
import AddComment from "../Comments/AddComment";
import AllTaskComments from "../Comments/AllTaskComments";
import MilestoneDropDown from "../Dropdowns/MilestoneDropDown";
import ReporterDropDown from "../Dropdowns/ReporterDropDown";
import AssigneeDropDown from "../Dropdowns/AssigneeDropDown";
import { priorityStyleLight, taskStatusStyleLight } from "./styles";
import { Expand, Trash2, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Plus } from "lucide-react";
import isEqual from "lodash/isEqual";
import { MinusIcon } from "@chakra-ui/icons";

export default function ViewTaskModal({
  isOpen,
  onClose,
  task,
  setTask,
  allTasks,
  setAllTasks,
}) {
  const {
    myTasks,
    reportTasks,
    setMyTasks,
    setReportTasks,
    setTaskIdRef,
    resetTaskIdRef,
    reloadTaskComments,
    updateTaskPriority,
    updateTaskStatus,
    updateTask,
    taskComments,
    setTaskComments,
    deleteTasks,
    fetchTaskDetail,
  } = UserState();
  const { user } = AuthState();
  const navigate = useNavigate();
  const [title, setTitle] = useState(task.title || "");
  const [description, setDescription] = useState(task.description || "");
  const [status, setStatus] = useState(task.status || "toDo");
  const [prevStatus, setPrevStatus] = useState(task.status || "toDo");
  const [priority, setPriority] = useState(task.priority || "");
  const [prevPriority, setPrevPriority] = useState(task.priority || "");
  const [projectId, setProjectId] = useState(task.projectId || "");
  const [reviewerId, setReviewerId] = useState(task.reviewerId || "");
  const [assigneeId, setAssigneeId] = useState(task.assigneeId || "");
  const [duration, setDuration] = useState(Number(task.duration) || 0);
  const [dueDate, setDueDate] = useState(task.dueDate || "");
  const [milestone, setMilestone] = useState(
    task.milestone || { id: "", title: "" }
  );
  const [labels, setLabels] = useState(
    task.labels
      ? Object.entries(task.labels).map(([key, value]) => ({ key, value }))
      : []
  );
  const [parentTask, setParentTask] = useState(null);
  const [childTasks, setChildTasks] = useState([]);

  const toast = useToast();
  const indexOfMyTaskRef = useRef(-1);
  const indexOfReportTaskRef = useRef(-1);
  const indexOfTaskRef = useRef(-1);
  const latestKeyInputRef = useRef(null);

  const panelColor = "#ebf8ffe0";

  const priorityText = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  const taskStatusText = {
    done: "Done",
    inProgress: "In Progress",
    blocked: "Blocked",
    toDo: "To Do",
    review: "Review",
  };

  const handleSaveEditing = async (attrToUpdate) => {
    try {
      await updateTask(
        task,
        { ...attrToUpdate },
        indexOfMyTaskRef.current,
        indexOfReportTaskRef.current,
        indexOfTaskRef.current,
        allTasks,
        setAllTasks
      );
      toast({
        title: "Task updated successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Failed to update task.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleUpdateTaskStatus = async () => {
    const val = status;
    const res = await updateTaskStatus(
      task,
      status,
      prevStatus,
      indexOfMyTaskRef.current,
      indexOfReportTaskRef.current,
      indexOfTaskRef.current,
      allTasks,
      setPrevStatus,
      setAllTasks
    );

    if (!res?.success) {
      setStatus(prevStatus);
    } else {
      setPrevStatus(val);
    }
  };

  const modifiedOnClose = () => {
    setTaskComments([]);
    const labelsObject = Object.fromEntries(
      labels
        .filter(({ key }) => key !== "")
        .map(({ key, value }) => [key, value])
    );

    if (indexOfMyTaskRef.current !== -1) {
      setMyTasks((tasks) => {
        const newTasks = tasks
          ?.filter((obj) => obj?.assigneeId === user?.firebaseUserId)
          ?.map((object) =>
            object.id !== task.id
              ? object
              : {
                  ...object,
                  reviewerId,
                  assigneeId,
                  milestone,
                  title,
                  description,
                  duration,
                  priority,
                  status,
                  dueDate,
                  labels: labelsObject,
                }
          );
        return newTasks;
      });
    }

    if (indexOfReportTaskRef.current !== -1) {
      setReportTasks((tasks) => {
        const newTasks = tasks
          ?.filter((obj) => obj?.reviewerId === user?.firebaseUserId)
          ?.map((object) =>
            object.id !== task.id
              ? object
              : {
                  ...object,
                  reviewerId,
                  assigneeId,
                  milestone,
                  title,
                  description,
                  duration,
                  priority,
                  status,
                  dueDate,
                  labels: labelsObject,
                }
          );
        return newTasks;
      });
    }

    if (indexOfTaskRef.current !== -1) {
      setAllTasks((tasks) => {
        const newTasks = tasks?.map((object) =>
          object.id !== task.id
            ? { ...object }
            : {
                ...object,
                reviewerId,
                assigneeId,
                milestone,
                title,
                description,
                duration,
                priority,
                status,
                dueDate,
                labels: labelsObject,
              }
        );
        return newTasks;
      });
    }

    indexOfTaskRef.current = -1;
    indexOfMyTaskRef.current = -1;
    indexOfReportTaskRef.current = -1;

    resetTaskIdRef();

    if (
      description !== task.description ||
      title !== task.title ||
      duration !== task.duration ||
      (dueDate && dueDate !== task.dueDate) ||
      !isEqual(labelsObject, task.labels)
    ) {
      const attrToUpdate = {};

      if (description !== task.description) {
        attrToUpdate.description = description;
      }

      if (title !== task.title) {
        attrToUpdate.title = title;
      }

      if (duration !== task.duration) {
        attrToUpdate.duration = duration;
      }

      if (dueDate && dueDate !== task.dueDate) {
        attrToUpdate.dueDate = dueDate;
      }

      if (!isEqual(labelsObject, task.labels)) {
        attrToUpdate.labels = labelsObject;
      }

      handleSaveEditing(attrToUpdate);
    }

    onClose();
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+(?=\d+(\.\d+)?)/, "");
    value = value.replace(/\.+/g, ".");
    value = value.replace(/[^\d.]/g, "");
    if (value === "" || value === ".") {
      value = "0";
    }
    setDuration(parseFloat(value));
  };

  const handleDueDateChange = (e) => {
    const selectedDate = e.target.value;
    setDueDate(selectedDate);
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTasks([task.id]);

      setMyTasks(
        (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
      );
      setReportTasks(
        (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
      );

      if (typeof setAllTasks === "function") {
        setAllTasks(
          (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
        );
      }

      toast({
        title: "Task deleted successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onClose();
    } catch (err) {
      toast({
        title: "Error deleting task.",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleNavigation = () => {
    navigate(`/task/${task.id}`);
  };

  const handleAddCustomField = () => {
    const emptyKeyLabel = labels.find((label) => !label.key);
    if (emptyKeyLabel) {
      return;
    }
    setLabels([...labels, { key: "", value: "" }]);
  };

  const handleCustomFieldChange = (index, newKey, newValue) => {
    const updatedLabels = labels.map((label, i) =>
      i === index ? { key: newKey, value: newValue } : label
    );
    setLabels(updatedLabels);
  };

  const handleRemoveCustomField = (index) => {
    const updatedLabels = labels.filter((_, i) => i !== index);
    setLabels(updatedLabels);
  };

  const fetchParentChildTasks = async () => {
    try {
      if (task.parentId) {
        const response = await fetchTaskDetail(task.parentId);
        const taskData = Array.isArray(response?.data) && response.data[0];
        setParentTask(taskData);
      }
      if (task.subTasks && task.subTasks.length > 0) {
        const childTasksData = await fetchTaskDetail(task.subTasks);
        const tasks =
          Array.isArray(childTasksData?.data) && childTasksData.data;
        setChildTasks(tasks);
      }
    } catch (error) {
      console.error("Error fetching parent/child tasks:", error);
    }
  };

  useEffect(() => {
    if (status && prevStatus && status !== prevStatus) {
      try {
        handleUpdateTaskStatus();
      } catch (err) {
        console.error(err);
      }
    }
  }, [status]);

  useEffect(() => {
    if (priority && prevPriority && priority !== prevPriority) {
      try {
        const val = priority;
        updateTaskPriority(
          task,
          priority,
          indexOfMyTaskRef.current,
          indexOfReportTaskRef.current,
          indexOfTaskRef.current,
          allTasks,
          setAllTasks
        );
        setPrevPriority(val);
      } catch (error) {
        console.error(error);
      }
    }
  }, [priority]);

  useEffect(() => {
    setTaskIdRef(task.id);
    reloadTaskComments();

    indexOfMyTaskRef.current = findIndexOfTask(task, myTasks);
    indexOfReportTaskRef.current = findIndexOfTask(task, reportTasks);
    indexOfTaskRef.current = findIndexOfTask(task, allTasks);

    setAssigneeId(task.assigneeId);
    setReviewerId(task.reviewerId);
    setPrevPriority(task.priority);
    setPrevStatus(task.status);
    setDueDate(task.dueDate || "");

    fetchParentChildTasks();
  }, []);

  useEffect(() => {
    if (latestKeyInputRef.current) {
      latestKeyInputRef.current.focus();
    }
  }, [labels.length]);

  const IconBox = ({ icon: Icon, onClick, label }) => (
    <Box
      as={Icon}
      size="20px"
      cursor="pointer"
      _hover={{ color: "gray.700", bg: "gray.200", transform: "scale(1.1)" }}
      transition="all 0.2s"
      borderRadius="full"
      onClick={onClick}
      aria-label={label}
    />
  );

  const TaskBox = ({ task }) => (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      mb={2}
      cursor="pointer"
      onClick={() => navigate(`/task/${task.id}`)}
      _hover={{ bg: "gray.50" }}
      bgColor={task?.status === "done" ? "green.50" : "white"}
    >
      <Text fontWeight="bold">{task.title}</Text>
      {task.dueDate && (
        <Text fontSize="sm" color="gray.500">
          Due: {new Date(task.dueDate).toLocaleDateString()}
        </Text>
      )}
      {task.description && (
        <Text fontSize="sm" noOfLines={2}>
          {task.description}
        </Text>
      )}
      {task.milestone && task.milestone.title && (
        <Text fontSize="sm" color="blue.500">
          Milestone: {task.milestone.title}
        </Text>
      )}
    </Box>
  );

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent
        height={"90vh"}
        maxHeight={"90vh"}
        m={0}
        mx={2}
        overflowY="auto"
        borderRadius="lg"
        boxShadow="xl"
        id="view-task-modal-content"
        className="view-task-modal-content"
      >
        <Box display="flex" justifyContent="flex-end" p={3} bg="white" gap={3}>
          <IconBox
            icon={Trash2}
            onClick={handleDeleteTask}
            label="Delete task"
          />
          <IconBox
            icon={Expand}
            onClick={handleNavigation}
            label={"Expand modal"}
          />
          <IconBox icon={X} onClick={modifiedOnClose} label="Close modal" />
        </Box>
        <ModalHeader p={1} pt={0} pb={1} mb={2} mt={-3}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fontSize="3xl"
            fontWeight="500"
            border="none"
            _focus={{ boxShadow: "none" }}
            placeholder="Task Title"
            autoFocus={false}
          />
        </ModalHeader>

        <Box px={6} mb={0} borderBottom="1px" borderColor="gray.200" pb={1}>
          <Grid templateColumns="1fr 1fr" gap={2}>
            {labels.map((label, index) => (
              <React.Fragment key={index}>
                <GridItem>
                  <HStack spacing={2} role="group" alignItems="center">
                    <Box
                      as="button"
                      aria-label="Delete label"
                      opacity={0}
                      _groupHover={{ opacity: 1 }}
                      onClick={() => handleRemoveCustomField(index)}
                      color="gray.500"
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      <MinusIcon />
                    </Box>
                    <Input
                      ref={
                        index === labels.length - 1 ? latestKeyInputRef : null
                      }
                      placeholder="Key"
                      value={label.key}
                      onChange={(e) =>
                        handleCustomFieldChange(
                          index,
                          e.target.value,
                          label.value
                        )
                      }
                      size="sm"
                      variant="unstyled"
                      fontSize="sm"
                      color="gray.600"
                    />
                  </HStack>
                </GridItem>
                <GridItem>
                  <Input
                    placeholder="Value"
                    value={label.value}
                    onChange={(e) =>
                      handleCustomFieldChange(index, label.key, e.target.value)
                    }
                    size="sm"
                    variant="unstyled"
                    fontSize="sm"
                  />
                </GridItem>
              </React.Fragment>
            ))}
            <GridItem colSpan={2}>
              <Button
                leftIcon={<Plus size={12} />}
                variant="ghost"
                size="sm"
                color="gray.500"
                fontWeight="normal"
                onClick={handleAddCustomField}
                _hover={{ bg: "gray.50" }}
                mt={2}
              >
                Add a property
              </Button>
            </GridItem>
          </Grid>
        </Box>

        <ModalBody pt={0} display="flex" flexDirection="column" px={0} pb={0}>
          <Tabs
            isFitted
            variant="enclosed"
            flex={1}
            display="flex"
            flexDirection="column"
            colorScheme="blue"
          >
            <TabList
              bg="gray.50"
              borderBottomWidth="2px"
              id={"view-task-modal-tabs-list"}
            >
              <Tab
                _selected={{
                  bg: panelColor,
                  borderBottomWidth: "2px",
                  borderBottomColor: "blue.500",
                }}
                _focus={{ boxShadow: "none" }}
              >
                Details
              </Tab>
              <Tab
                _selected={{
                  bg: panelColor,
                  borderBottomWidth: "2px",
                  borderBottomColor: "blue.500",
                }}
                _focus={{ boxShadow: "none" }}
              >
                <Text pr={2}>Discussions</Text>
                <Text fontWeight={700}>
                  {taskComments?.length > 0 && ` •${taskComments.length}`}
                </Text>
              </Tab>
              {(task.parentId ||
                (task.subTasks && task.subTasks.length > 0)) && (
                <Tab
                  _selected={{
                    bg: panelColor,
                    borderBottomWidth: "2px",
                    borderBottomColor: "blue.500",
                  }}
                  _focus={{ boxShadow: "none" }}
                >
                  {task.parentId ? "Parent Task" : "Child Tasks"}
                </Tab>
              )}
            </TabList>
            <TabPanels
              flex={1}
              overflowY="hidden"
              overflowX="hidden"
              bgColor={"white"}
            >
              <TabPanel>
                <VStack spacing={6} align="stretch">
                  <HStack>
                    <FormControl>
                      <AssigneeDropDown
                        assigneeId={assigneeId}
                        setAssigneeId={setAssigneeId}
                        projectId={projectId}
                        reviewerId={reviewerId}
                        forUpdation={true}
                        isTriggerBgWhite={true}
                      />
                      <FormHelperText display={"flex"} gap={1}>
                        Assignee{" "}
                        {assigneeId && (
                          <Box
                            color="blue.500 !important"
                            onClick={() => navigate(`/profile/${assigneeId}`)}
                            cursor={"pointer"}
                          >
                            (
                            <Button style={{ all: "unset" }}>
                              Visit Profile
                            </Button>
                            )
                          </Box>
                        )}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <ReporterDropDown
                        reviewerId={reviewerId}
                        setReviewerId={setReviewerId}
                        assigneeId={assigneeId}
                        projectId={projectId}
                        forUpdation={true}
                        isTriggerBgWhite={true}
                      />
                      <FormHelperText display={"flex"} gap={1}>
                        Reviewer
                        {reviewerId && (
                          <Box
                            color="blue.500 !important"
                            onClick={() => navigate(`/profile/${reviewerId}`)}
                            cursor={"pointer"}
                          >
                            (
                            <Button style={{ all: "unset" }}>
                              Visit Profile
                            </Button>
                            )
                          </Box>
                        )}
                      </FormHelperText>
                    </FormControl>
                  </HStack>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      minH="70px"
                      bgColor={"white"}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={5}
                    />
                  </FormControl>
                  <HStack spacing={4} align="flex-start" wrap="wrap">
                    <FormControl flex={1}>
                      <Input
                        value={duration}
                        bgColor={"white"}
                        placeholder="Duration"
                        onChange={handleDurationChange}
                        type="text"
                        inputMode="decimal"
                        step="0.1"
                      />
                      <FormHelperText>Duration (hours)</FormHelperText>
                    </FormControl>

                    <FormControl flex={1} minW="150px">
                      <Input
                        value={dueDate}
                        bgColor="white"
                        placeholder="Select Due Date"
                        onChange={handleDueDateChange}
                        type="date"
                        min={getTodayDate()}
                      />
                      <FormHelperText>Due Date</FormHelperText>
                    </FormControl>
                  </HStack>

                  <HStack spacing={4} align="flex-start" wrap="wrap">
                    <FormControl flex={1}>
                      <StatusDropDown
                        status={status}
                        setStatus={setStatus}
                        isParent={task?.subTasks?.length > 0}
                      >
                        <Button
                          w="full"
                          bg={taskStatusStyleLight[status]}
                          color="black"
                          _hover={{ opacity: 0.8 }}
                        >
                          {taskStatusText[status]}
                        </Button>
                      </StatusDropDown>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                    <FormControl flex={1}>
                      <PriorityDropDown
                        priority={priority}
                        setPriority={setPriority}
                      >
                        <Button
                          w="full"
                          bg={priorityStyleLight[priority]}
                          color={priority ? "black" : "gray.800"}
                          _hover={{ opacity: 0.8 }}
                        >
                          <Box>
                            {priority ? (
                              <Box display="flex" alignItems="center" gap={1}>
                                <PriorityIcon priority={priority} />
                                <Text>{priorityText[priority]}</Text>
                              </Box>
                            ) : (
                              <Text color="gray.500">Select Priority</Text>
                            )}
                          </Box>
                        </Button>
                      </PriorityDropDown>
                      <FormHelperText>Priority</FormHelperText>
                    </FormControl>
                  </HStack>

                  <HStack>
                    <FormControl>
                      <FormLabel>Milestone</FormLabel>
                      <MilestoneDropDown
                        projectId={projectId}
                        milestone={milestone}
                        setMilestone={setMilestone}
                        task={task}
                        forUpdation={true}
                        setTask={setTask}
                        isTriggerBgWhite={true}
                      />
                    </FormControl>
                  </HStack>
                </VStack>
              </TabPanel>
              <TabPanel display="flex" flexDirection="column" height={"100%"}>
                <Box flex={1}>
                  <AllTaskComments task={task} setTask={setTask} />
                </Box>
                <Box
                  mt="auto"
                  pt={4}
                  borderTop="1px solid"
                  borderColor="gray.200"
                >
                  <AddComment projectId={task?.projectId} setTask={setTask} />
                </Box>
              </TabPanel>
              {(task.parentId ||
                (task.subTasks && task.subTasks.length > 0)) && (
                <TabPanel>
                  {task.parentId && parentTask && (
                    <Box>
                      <Text fontWeight="bold" mb={2}>
                        Parent Task:
                      </Text>
                      <TaskBox task={parentTask} />
                    </Box>
                  )}
                  {task.subTasks && task.subTasks.length > 0 && (
                    <Box mt={4}>
                      <Text fontWeight="bold" mb={2}>
                        Child Tasks:
                      </Text>
                      {childTasks.map((childTask) => (
                        <TaskBox key={childTask.id} task={childTask} />
                      ))}
                    </Box>
                  )}
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
